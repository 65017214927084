<template>
  <div class="select-wrapper">
    <div
      class="wrap-selected is-flex is-align-items-center is-clickable"
      :class="{ disabled: disableDropdown }"
      @click="showDropdownFunc"
      ref="selectBranch"
      v-closable2="{
        exclude: ['selectBranch', 'searchBranch'],
        handler: 'hideDropdown'
      }"
    >
      <div v-if="Object.keys(payloadAddPicBranch.branchSelected).length > 0 && payloadAddPicBranch.branchSelected.name !== ''">
        <p class="averta-bold">{{ branchSelected.name }}</p>
      </div>
      <p v-else class="text-grey">Pilih Cabang</p>
      <img :src="require('@/assets/img/arrow-down-1.png')" class="arrow-autocomplete-1" :class="{'open': showDropdown}" />
    </div>
    <div :style="{ display: showDropdown ? 'block' : 'none' }">
      <div class="form-input">
        <div ref="searchBranch">
          <b-input
            class="input-search"
            placeholder="Cari"
            type="search"
            icon="magnify"
            @input="searchBranchFunc"
            v-model="searchBranch"
          />
        </div>
        <div class="list-branch">
          <div
            class="branch-item"
            :class="{ active: branchSelected === branch }"
            v-for="(branch, index) in listBranchFilter"
            :key="index"
            @click="selectBranchFunc(branch)"
          >
            <p class="label-14">Cabang {{ branch.name }}</p>
            <p class="label-14">{{ branch.company_name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'branchAutocomplete',
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      payloadAddPicBranch: 'client/getPayloadAddPicBranch',
      clearDataPayloadPicBranch: 'client/getClearDataPayloadPicBranch'
    })
  },
  data () {
    return {
      showDropdown: false,
      branchSelected: '',
      searchBranch: '',
      disableDropdown: true,
      listBranch: [],
      listBranchFilter: []
    }
  },
  methods: {
    getListBranch () {
      if (
        this.$route.params.vendorId ||
        this.payloadAddPicBranch.clientSelected.id
      ) {
        const payload = {
          url: this.url,
          vendor_id:
            this.$route.params.vendorId ||
            this.payloadAddPicBranch.clientSelected.id
        }

        this.$store
          .dispatch('client/getListBranch', payload)
          .then(response => {
            this.listBranch = response.data.data.list
            this.listBranchFilter = response.data.data.list
          })
          .catch(error => {
            console.log(error)
            this.$buefy.toast.open({
              message: 'Gagal memuat list cabang, silahkan coba lagi',
              type: 'is-danger'
            })
          })
      }
    },
    showDropdownFunc () {
      if (this.disableDropdown === false) {
        this.showDropdown = !this.showDropdown
      }
    },
    hideDropdown () {
      this.showDropdown = false
    },
    selectBranchFunc (branch) {
      const storeToVuex = {
        type: 'branchSelected',
        branchSelected: branch
      }
      this.$store.commit('client/setPayloadAddPicBranch', storeToVuex)
      this.branchSelected = branch
      this.showDropdown = false
    },
    searchBranchFunc () {
      const searchBranch = this.listBranch.filter(branch => {
        return branch.name
          .toLowerCase()
          .includes(this.searchBranch.toLowerCase())
      })

      this.listBranchFilter = searchBranch
    },
    clearBranch () {
      this.branchSelected = ''
      this.payloadAddPicBranch.branchSelected = {}
    }
  },
  mounted () {
    if (this.$route.params.vendorId) {
      this.getListBranch()
    }

    const path = this.$route.path.split('/')
    const lastElement = path[path.length - 1]

    if (lastElement === 'branch') {
      if (Object.keys(this.payloadAddPicBranch.clientSelected).length > 0) {
        this.disableDropdown = false
      }
    }
  },
  watch: {
    searchBranch (newValue) {
      if (newValue === '') {
        this.listBranchFilter = this.listBranch
      }
    },
    payloadAddPicBranch: {
      handler (newValue) {
        if (Object.keys(newValue.clientSelected).length > 0) {
          this.disableDropdown = false
        } else {
          this.disableDropdown = true
        }

        if (Object.keys(newValue.clientSelected).length > 0 && (newValue.dataPic.length < 1 || newValue.dataPic[0].name === '')) {
          this.getListBranch()
        }

        if (newValue.changeClient === true) {
          this.clearBranch()
          newValue.changeClient = false
        }
      },
      deep: true
    },
    clearDataPayloadPicBranch (newValue) {
      if (newValue === true) {
        this.branchSelected = ''
        this.searchBranch = ''
      }
    }
  }
}
</script>

<style scoped>
.select-wrapper {
  width: 100%;
  position: relative;
}

.wrap-selected {
  border: 1px solid #e1e1e1;
  background: white;
  border-radius: 8px;
  padding: 8px 12px;
  width: 100%;
  height: 40px;
}

.wrap-selected.disabled {
  background: #f2f2f2;
}

.form-input {
  background: white;
  border-radius: 8px;
  padding: 8px 12px;
}

.list-branch {
  max-height: 387px;
  overflow-y: auto;
}

.branch-item {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 12px;
}

.branch-item:first-child {
  margin-top: 10px;
}

.branch-item:hover {
  background: #fcecec;
}

.branch-item.active {
  background: #fcecec;
}
</style>
