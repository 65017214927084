<template>
    <div class="wrap-lampiran">
        <p class="averta-bold label-20 mb-3">Berkas Lampiran</p>
        <div class="wrap-spk-sik is-flex is-justify-content-space-between">
            <!-- SIK -->
            <div class="card-lampiran is-flex is-justify-content-space-between is-align-items-center">
                <div class="is-flex is-align-items-center">
                    <PaperIcon class="mr-2" />
                    <div>
                        <p class="label-14 averta-bold">{{ dataSIK ? dataSIK.name : '-' }}</p>
                        <p class="label-12 text-grey">upload pada : {{  dataSIK ? dataSIK.created_at : '-' }}</p>
                    </div>
                </div>
                <div class="wrap-action is-flex is-align-items-center is-justify-content-space-between">
                    <div class="wrap-icon is-flex is-align-items-center" @click="download(dataSIK.filename, dataSIK.name)">
                        <DownloadIcon class="mr-2" />
                        <p class="text-merah averta-bold label-14">Unduh</p>
                    </div>
                    <div class="wrap-icon is-flex is-align-items-center" @click="previewLampiranSIK">
                        <EyeIcon class="mr-2" />
                        <p class="text-merah averta-bold label-14">Lihat</p>
                    </div>
                </div>
            </div>
            <!-- SIK -->

            <!-- SPK -->
            <div class="card-lampiran is-flex is-justify-content-space-between is-align-items-center">
                <div class="is-flex is-align-items-center">
                    <PaperIcon class="mr-2" />
                    <div>
                        <p class="label-14 averta-bold">{{ dataSPK ? dataSPK.name : '-' }}</p>
                        <p class="label-12 text-grey">upload pada : {{ dataSPK ? dataSPK.created_at : '-' }}</p>
                    </div>
                </div>
                <div class="wrap-action is-flex is-align-items-center is-justify-content-space-between">
                    <div class="wrap-icon is-flex is-align-items-center" @click="download(dataSPK.filename, dataSPK.name)">
                        <DownloadIcon class="mr-2" />
                        <p class="text-merah averta-bold label-14">Unduh</p>
                    </div>
                    <div class="wrap-icon is-flex is-align-items-center" @click="previewLampiranSPK">
                        <EyeIcon class="mr-2" />
                        <p class="text-merah averta-bold label-14">Lihat</p>
                    </div>
                </div>
            </div>
            <!-- SPK -->
        </div>

        <!-- Modal Preview Image -->
        <ModalPreviewImage :isActive="modalPreviewImageActive" :dataImage="imagePreview" :title="titlePreviewImage"
            @closeModal="closeModalPreviewImage()" />
        <!-- Modal Preview Image -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { PaperIcon, DownloadIcon, EyeIcon } from '@/components/icons'
import ModalPreviewImage from '@/components/modal/ModalPreviewImage'

export default {
  name: 'TabBerkasLampiran',
  props: {
    dataBerkasLampiran: {
      type: Array
    }
  },
  components: {
    PaperIcon,
    DownloadIcon,
    EyeIcon,
    ModalPreviewImage
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  data () {
    return {
      dataSIK: null,
      dataSPK: null,
      modalPreviewImageActive: false,
      imagePreview: null,
      titlePreviewImage: null
    }
  },
  methods: {
    previewLampiranSIK () {
      this.imagePreview = [this.dataSIK.filename]
      this.titlePreviewImage = 'Lampiran SIK'
      this.modalPreviewImageActive = true
    },
    previewLampiranSPK () {
      this.imagePreview = [this.dataSPK.filename]
      this.titlePreviewImage = 'Lampiran SPK'
      this.modalPreviewImageActive = true
    },
    closeModalPreviewImage () {
      this.modalPreviewImageActive = false
    },
    download (url, name) {
      const sliceExtension = url.slice(url.lastIndexOf('/') + 1)
      const extension = sliceExtension.split('.')[1]
      const finalExtension = extension.split('?')[0]

      fetch(url)
        .then(resp => resp.blob())
        .then(blob => {
          const linkURL = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = linkURL

          a.download = `${name}.${finalExtension}`
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .catch(() => alert('Maaf ada masalah, silahkan coba lagi'))
    },
    getBerkasLampiran () {
      this.isLoading = true

      const payload = {
        url: this.url,
        proyek_id: this.$route.params.proyekId,
        category: 'detail',
        subcategory: 'attachments'
      }

      this.$store
        .dispatch('projectV2/getDetailProject', payload)
        .then(response => {
          this.isLoading = false
          this.dataSIK = response.data.data.attachments[0]
          this.dataSPK = response.data.data.attachments[1]
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
          this.$buefy.toast.open({
            message:
              'Gagal memuat data detail berkas lampiran, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    }
  },
  created () {
    this.getBerkasLampiran()
  }
}
</script>

<style scoped>
.wrap-lampiran {
    width: 100%;
    height: 100%;
    border: 1px solid #D8D8D8;
    border-radius: 12px;
    padding: 18px;
}

.card-lampiran {
    width: 49%;
    background: #FAFAFA;
    border: none;
    padding: 12px;
    border-radius: 20px;
}

.wrap-action {
    width: 34%;
}

.wrap-icon {
    border: 2px solid #E10009;
    border-radius: 100px;
    padding: 6px 14px;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .wrap-spk-sik {
        flex-direction: column;
    }

    .card-lampiran {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .card-lampiran:first-child {
        margin-bottom: 14px;
    }

    .wrap-action {
        width: auto;
        margin-top: 12px;
    }

    .wrap-icon:first-child {
        margin-right: 12px;
    }
}
</style>
