<template>
  <div class="container pt-5" :class="{'c-w-95 c-w-sm-100': currentStep === 1 && currentPath === 'update-price'}">
    <div class="is-flex is-align-items-center is-justify-content-space-between mb-5 mt-3">

      <div class="is-flex is-align-items-center is-clickable" @click="backToDetail()">
        <ArrowLeftIcon class="mr-3" />
        <p class="averta-bold is-size-5">Kembali ke Penawaran</p>
      </div>

      <div class="is-flex is-align-items-center">
        <span v-if="currentStep > 1" @click="previousStep(currentStep)" class="is-clickable text-red averta-bold mr-4">
          Sebelumnya
        </span>

        <button v-if="currentStep === 1 && currentPath === 'complete-offering'" @click="nextStep(currentStep)"
          class="btn-kirim is-flex is-align-items-center is-clickable">
          <CheckIcon class="mr-2" />
          <p>Kirim Penawaran ke Client</p>
        </button>

        <button v-if="currentStep === 2 && currentPath === 'complete-offering'" class="btn-kirim is-flex is-align-items-center is-clickable" @click="postPenawaran()">
          <p class="mr-2">Kirim</p>
          <CheckIcon />
        </button>

        <div class="is-flex is-align-items-center" v-if="currentStep === 1 && currentPath === 'update-price'">
          <button @click="showHideSidebar()" class="is-flex is-align-items-center is-clickable mr-3 btn-update py-3 px-5">
            <p class="averta-bold">Update Item Pekerjaan</p>
          </button>

          <button @click="nextStep(currentStep)" class="btn-kirim is-flex is-align-items-center is-clickable">
            <CheckIcon class="mr-2" />
            <p>Kirim Penawaran ke Client</p>
          </button>
        </div>

        <button v-if="currentStep === 2 && currentPath === 'update-price'" class="btn-kirim is-flex is-align-items-center is-clickable" @click="updateRejectPenawaran()">
          <p class="mr-2">Kirim</p>
          <CheckIcon />
        </button>

      </div>
    </div>

    <div class="notification is-light is-danger" v-if="message !== ''">
      <p>{{ message }}</p>
    </div>

    <div class="notification is-light is-danger" v-if="message_alfa !== ''">
      <button class="delete" @click="closeNotification"></button>
      <div class="content">
        <p class="label-16 averta-bold">{{ message_alfa.data.message }}</p>
        <ul>
          <li>
            <span class="averta-bold">{{ message_alfa.data.data.item_name }}</span>
            <ul>
              <li v-if="message_alfa.data.data.area_code !== null">
                Area Code: {{ message_alfa.data.data.area_code }}
              </li>
              <li v-if="message_alfa.data.data.kanggo_price !== null">
                Kanggo: {{ formatThousand(message_alfa.data.data.kanggo_price) }}
              </li>
              <li v-if="message_alfa.data.data.alfamart_price !== null">
                Alfamart: {{ formatThousand(message_alfa.data.data.alfamart_price) }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <CompleteOffering @postLengkapiPenawaran="lengkapiPenawaran($event)" v-if="currentStep === 1 && !isLoading" />
    <PreviewCompleteOffering v-if="currentStep === 2" />

    <b-sidebar type="is-light" :fullheight="true" :can-cancel="false" :fullwidth="true" :overlay="true" :right="true" v-model="showSidebar">
      <div v-if="!isLoading">
        <NewOffering @showHideSidebar="$event => showHideSidebar($event)" v-if="currentPath === 'update-price'" />
      </div>
    </b-sidebar>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatCurrency } from '@/helpers'
import { ArrowLeftIcon, CheckIcon, SpinnerIcon } from '@/components/icons'
import CompleteOffering from './CompleteOffering.vue'
import PreviewCompleteOffering from './PreviewCompleteOffering.vue'
import confirmOfferingImage from '@/assets/img/delete-area-component.png'
import successCreateImage from '../../../../../assets/img/survey-selesai.png'
import discardImage from '../../../../../assets/img/discard-data.png'
import NewOffering from '../../../project/NewOffering.vue'

export default {
  name: 'LengkapiPenawaran',
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      dataPenawaran: 'project/getDataDetailPenawaran'
    }),
    currentPath () {
      const path = this.$route.path.split('/')
      const lastElement = path[path.length - 1]

      return lastElement
    }
  },
  components: {
    ArrowLeftIcon,
    CheckIcon,
    CompleteOffering,
    PreviewCompleteOffering,
    SpinnerIcon,
    NewOffering
  },
  data () {
    return {
      currentStep: 1,
      dataLengkapiPenawaran: null,
      message: '',
      message_alfa: '',
      isLoading: false,
      showSidebar: false
    }
  },
  methods: {
    formatThousand (x) {
      return formatCurrency(x)
    },
    backToDetail () {
      if (this.currentPath === 'update-price') {
        this.$router.push(`/am/project/survey/${this.dataPenawaran.survey_id}/detail`)
      } else {
        this.$router.push(`/am/project/survey/${this.$route.params.surveyId}/detail`)
      }
    },
    previousStep (currentStep) {
      this.currentStep = currentStep - 1
    },
    nextStep (currentStep) {
      if (this.currentPath === 'complete-offering') {
        if (currentStep === 1) {
          if (this.dataLengkapiPenawaran === null) {
            this.message = 'Pastikan harga tukang dan material sudah terisi dengan benar'
          } else {
            this.postLengkapiPenawaran()
          }
        }
      } else {
        this.updateHargaRejectPenawaran()
      }
    },
    lengkapiPenawaran (data) {
      this.dataLengkapiPenawaran = data
    },
    async postLengkapiPenawaran () {
      let validate
      this.dataLengkapiPenawaran.forEach(area => {
        area.components.forEach(components => {
          components.detail_components.forEach(detailComponent => {
            detailComponent.item.forEach(item => {
              item.workers.forEach(worker => {
                if (worker.unit_price < 0 || worker.unit_price === '' || worker.unit_price === null) {
                  validate = 1
                }
              })
              item.materials.forEach(material => {
                if (material.unit_price < 0 || material.unit_price === '' || material.unit_price === null) {
                  validate = 1
                }
              })
            })
          })
        })
      })

      if (validate === 1) {
        this.message = 'Pastikan harga tukang dan material sudah terisi dengan benar'
        return validate
      } else {
        this.message = ''
        const payload = {
          url: this.url,
          survey_id: this.$route.params.surveyId,
          data: this.dataLengkapiPenawaran
        }

        await this.dataLengkapiPenawaran.forEach(area => {
          area.components.forEach(components => {
            components.detail_components.forEach(detailComponent => {
              detailComponent.item.forEach(item => {
                item.workers.forEach(worker => {
                  worker.unit_price = +worker.unit_price.replace(/,/g, '')
                })
                item.materials.forEach(material => {
                  material.unit_price = +material.unit_price.replace(/,/g, '')
                })
              })
            })
          })
        })

        this.isLoading = true
        this.$store
          .dispatch('project/lengkapiPenawaran', payload)
          .then(response => {
            this.currentStep = 2
            this.isLoading = false
          })
          .catch(error => {
            this.isLoading = false
            console.log(error)
            this.$buefy.toast.open({
              message: '<p class="averta-regular">Uups ada kesalahan, silahkan coba lagi</p>',
              type: 'is-danger'
            })
          })
      }
    },
    postPenawaran () {
      this.$swal({
        imageUrl: confirmOfferingImage,
        imageAlt: 'confirm offering',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Lanjut Proses Penawaran Ini?</p>',
        html: '<p>Pastikan kamu sudah cek kembali penawaran ini sebelum di proses</p>',
        showCancelButton: true,
        confirmButtonText: 'Proses',
        cancelButtonText: 'Cek Kembali',
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            url: this.url,
            survey_id: this.$route.params.surveyId
          }

          this.isLoading = true
          this.$store
            .dispatch('project/postPenawaran', payload)
            .then(response => {
              this.isLoading = false
              this.backToDetail()
              this.$swal({
                width: '600px',
                padding: '10px 10px 30px 10px',
                html: `
                  <img src="${successCreateImage}" class="img-create-penawaran mb-4" />
                  <p class="averta-bold mb-4 label-22">Penawaran berhasil dibuat</p>
                  <p>Penawaran telah berhasil dibuat dan terkirim ke client. Mohon tunggu respon dari client untuk melanjutkan pemesanan</p>
                `,
                showCancelButton: true,
                confirmButtonText: 'Lihat Detail',
                cancelButtonText: 'Lihat Daftar Proyek',
                customClass: {
                  confirmButton: 'btn-hapus-detail-component',
                  cancelButton: 'btn-batal-detail-component'
                }
              })
            }).then((result) => {
              this.isLoading = false
              if (result.isConfirmed) {
                this.backToDetail()
              } else {
                this.$router.push('/am/project')
              }
            })
            .catch(error => {
              this.isLoading = false
              const dataError = error.response
              if (dataError) {
                this.message_alfa = dataError
              }
            })
        }
      })
    },
    async updateHargaRejectPenawaran () {
      let validate
      this.dataLengkapiPenawaran.forEach(area => {
        area.components.forEach(components => {
          components.detail_components.forEach(detailComponent => {
            detailComponent.item.forEach(item => {
              item.workers.forEach(worker => {
                if (worker.unit_price < 0 || worker.unit_price === '' || worker.unit_price === null) {
                  validate = 1
                }
              })
              item.materials.forEach(material => {
                if (material.unit_price < 0 || material.unit_price === '' || material.unit_price === null) {
                  validate = 1
                }
              })
            })
          })
        })
      })

      if (validate === 1) {
        this.message = 'Pastikan harga tukang dan material sudah terisi dengan benar'
        return validate
      } else {
        await this.dataLengkapiPenawaran.forEach(area => {
          area.components.forEach(components => {
            components.detail_components.forEach(detailComponent => {
              detailComponent.item.forEach(item => {
                item.workers.forEach(worker => {
                  worker.unit_price = +worker.unit_price.replace(/,/g, '')
                })
                item.materials.forEach(material => {
                  material.unit_price = +material.unit_price.replace(/,/g, '')
                })
              })
            })
          })
        })

        this.$swal({
          imageUrl: confirmOfferingImage,
          imageAlt: 'confirm offering',
          width: '600px',
          padding: '10px 10px 30px 10px',
          title: '<p class="averta-bold label-22">Lanjut Proses Penawaran Ini?</p>',
          html: '<p>Pastikan kamu sudah cek kembali penawaran ini sebelum di proses</p>',
          showCancelButton: true,
          confirmButtonText: 'Proses',
          cancelButtonText: 'Cek Kembali',
          customClass: {
            confirmButton: 'btn-hapus-detail-component',
            cancelButton: 'btn-batal-detail-component'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            const payload = {
              url: this.url,
              offering_id: this.$route.params.offeringId,
              data: this.dataLengkapiPenawaran
            }

            this.isLoading = true
            this.$store
              .dispatch('project/updateHargaSatuanPenawaranV2', payload)
              .then(response => {
                this.isLoading = false
                this.currentStep = 2
              })
              .catch(error => {
                this.isLoading = false
                console.log(error)
                if (error.response.data.code === 0) {
                  this.message = error.response.data.message
                }
              })
          } else {
            this.dataLengkapiPenawaran.forEach(area => {
              area.components.forEach(components => {
                components.detail_components.forEach(detailComponent => {
                  detailComponent.item.forEach(item => {
                    item.workers.forEach(worker => {
                      worker.unit_price = this.formatPrice(worker.unit_price)
                    })
                    item.materials.forEach(material => {
                      material.unit_price = this.formatPrice(material.unit_price)
                    })
                  })
                })
              })
            })
          }
        })
      }
    },
    updateRejectPenawaran () {
      this.$swal({
        imageUrl: confirmOfferingImage,
        imageAlt: 'confirm offering',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Lanjut Proses Penawaran Ini?</p>',
        html: '<p>Pastikan kamu sudah cek kembali penawaran ini sebelum di proses</p>',
        showCancelButton: true,
        confirmButtonText: 'Proses',
        cancelButtonText: 'Cek Kembali',
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            url: this.url,
            offering_id: this.$route.params.offeringId
          }

          this.isLoading = true
          this.$store
            .dispatch('projectV2/updateRejectPenawaran', payload)
            .then(response => {
              this.isLoading = false
              this.backToDetail()
              this.$swal({
                width: '600px',
                padding: '10px 10px 30px 10px',
                html: `
                  <img src="${successCreateImage}" class="img-create-penawaran mb-4" />
                  <p class="averta-bold mb-4 label-22">Penawaran berhasil dibuat</p>
                  <p>Penawaran telah berhasil dibuat dan terkirim ke client. Mohon tunggu respon dari client untuk melanjutkan pemesanan</p>
                `,
                showCancelButton: true,
                confirmButtonText: 'Lihat Detail',
                cancelButtonText: 'Lihat Daftar Proyek',
                customClass: {
                  confirmButton: 'btn-hapus-detail-component',
                  cancelButton: 'btn-batal-detail-component'
                }
              })
                .then((result) => {
                  this.isLoading = false
                  if (result.isConfirmed) {
                    this.backToDetail()
                  } else {
                    this.$router.push('/am/project')
                  }
                })
            })
            .catch(error => {
              this.isLoading = false
              const dataError = error.response
              if (dataError) {
                this.message_alfa = dataError
              }
            })
        }
      })
    },
    closeNotification () {
      this.message_alfa = ''
    },
    formatPrice (price) {
      if (price !== null) {
        price = price.toString().replace(/\D/g, '')
        const formattedPrice = Number(price).toLocaleString('en-US')
        return formattedPrice
      }
    },
    showHideSidebar (event) {
      if (event === 'success-update-item' || event === 'cancel-item') {
        this.getDetailPenawaran()
      }
      this.showSidebar = !this.showSidebar
    },
    getDetailPenawaran () {
      if (this.$route.params.offeringId) {
        this.isLoading = true
        const payload = {
          url: this.url,
          offering_id: this.$route.params.offeringId
        }
        this.$store
          .dispatch('project/getDetailPenawaran', payload)
          .then(response => {
            this.$store.commit('project/setDataUpdateItemPekerjaanBS', {
              surveyId: response.data.data.survey_id
            })
            this.isLoading = false
          })
          .catch(error => {
            this.isLoading = false
            console.log(error)
            this.$buefy.toast.open({
              message: '<p class="averta-regular">Gagal memuat data detail penawaran, silahkan coba lagi</p>',
              type: 'is-danger'
            })
          })
      }
    }
  },
  created () {
    this.getDetailPenawaran()
    if (this.currentPath === 'update-price') {
      this.$store.commit('project/setDataUpdateItemPekerjaanBS', {
        isUpdate: true
      })
    }
    //  else {
    //   this.$store.commit('project/setDataUpdateItemPekerjaanBS', {
    //     clearData: true
    //   })
    // }
  },
  beforeRouteLeave (to, from, next) {
    if (this.showSidebar === true) {
      next(false)
      this.$swal({
        width: '600px',
        padding: '10px 10px 30px 10px',
        html: `
          <img src="${discardImage}" class="img-create-penawaran mb-4" />
          <p class="averta-bold mb-4">Keluar Dari Halaman Ini?</p>
          <p>Informasi yang telah diedit akan hilang</p>
        `,
        showCancelButton: true,
        confirmButtonText: 'Keluar',
        cancelButtonText: 'Tetap Dihalaman Ini',
        customClass: {
          confirmButton: 'btn-batal-detail-component',
          cancelButton: 'btn-hapus-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            url: this.url,
            survey_id: this.$route.params.surveyId // ini harus ada agar id survey yang di ambil dari vuex
          }
          this.$store
            .dispatch('projectV2/cancelOffering', payload)
            .then(() => {
              this.showHideSidebar('cancel-item')
              next()
            })
            .catch((error) => {
              console.log(error)
            })
        }
      })
    } else {
      next()
    }
  }
}
</script>

<style scoped>
.btn-kirim {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.25%);
  border-radius: 100px;
  font-family: 'Averta-Bold';
  color: white;
  padding: 8px 24px;
  border: none;
}

.text-red {
  color: #EB4600;
}

.btn-update {
  background: white;
  color: #D9272D;
  border: 2px solid #D9272D;
  border-radius: 24px;
}
</style>
