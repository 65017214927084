<template>
    <div id="mainContainer" class="wrap-follow-up p-6" @scroll="handleScroll">
        <!-- Header -->
        <TitleHeader
          from="follow-up"
          title="List Follow Up Client"
          desc="Laporan progress proyek Client"
        />
        <!-- Header -->

        <!-- Filter -->
        <div class="filter-wrapper py-3" :style="{ top: navbarPosition }">
            <div>
                <p class="mb-1">Cari Berdasarkan</p>
                <b-input v-model="search" class="b-input-wrapper" placeholder="ID Survey/Nama Outlet" type="search" icon="magnify" style="width: 300px" @keyup.native.enter="handleSearch" />
            </div>
            <div class="is-flex is-justify-content-space-between is-align-items-center">
                <div class="mr-4">
                    <p class="mb-1">Client</p>
                    <div class="client-wrapper">
                      <InputAutocomplete
                        header="Pilih Client"
                        :isVendor="true"
                        :isNew="true"
                        :haveImg="true"
                        imgKey="logo"
                        :onSelectItem="handleSelectVendor"
                        :onClearSearch="handleSelectVendor"
                      />
                    </div>
                </div>
                <div class="mr-4">
                    <p class="mb-1">Filter Status Proyek</p>
                    <FilterStatusFollowUp @onSubmit="handleFilterStatus($event)" />
                </div>
                <div>
                    <p class="mb-1">Tanggal Penawaran Dikirim</p>
                    <div class="is-flex is-align-items-center">
                        <!-- Start Date -->
                        <div class="is-flex is-relative">
                          <div class="result-date-wrapper" @click="openDateOffering('start')" ref="refStartDate">
                            <ScheduleIcon width="15" height="15" />
                            <p class="ml-2">{{startDate ? formatDate(startDate) : 'Start Date'}}</p>
                          </div>
                          <div
                            v-if="showStartDate"
                            class="date-wrapper"
                            v-closable="{
                              exclude: ['refStartDate'],
                              handler: 'onCloseStartDate'
                            }"
                          >
                            <b-datepicker v-model="startDate" inline>
                            </b-datepicker>
                            <div class="divider"></div>
                            <div class="wrap-button py-3 is-flex is-justify-content-space-between">
                                <ButtonApp :isDisabled="isLoading" :isSecondary="true" :isBold="true" @click="resetDateFilter('start')" class="flex-1 ml-4 mr-2">
                                  <p class="py-2">Reset Filter</p>
                                </ButtonApp>
                                <ButtonApp :isDisabled="isLoading" :isBold="true" class="flex-1 mr-4" @click="selectDateOffering">
                                  <p class="py-2">Terapkan</p>
                                </ButtonApp>
                            </div>
                          </div>
                        </div>

                        <p class="label-18 mx-2">&#8209;</p>

                        <!-- End Date -->
                        <div class="is-flex is-relative mr-4">
                          <div class="result-date-wrapper" @click="openDateOffering('end')" ref="refEndDate">
                            <ScheduleIcon width="15" height="15" />
                            <p class="ml-2">{{endDate ? formatDate(endDate) : 'End Date'}}</p>
                          </div>
                          <div
                            v-if="showEndDate"
                            class="date-wrapper"
                            v-closable="{
                              exclude: ['refEndDate'],
                              handler: 'onCloseEndDate'
                            }"
                          >
                            <b-datepicker v-model="endDate" inline>
                            </b-datepicker>
                            <div class="divider"></div>
                            <div class="wrap-button py-3 is-flex is-justify-content-space-between">
                                <ButtonApp :isDisabled="isLoading" :isSecondary="true" :isBold="true" @click="resetDateFilter('end')" class="flex-1 ml-4 mr-2">
                                  <p class="py-2">Reset Filter</p>
                                </ButtonApp>
                                <ButtonApp :isDisabled="isLoading" :isBold="true" class="flex-1 mr-4" @click="selectDateOffering">
                                  <p class="py-2">Terapkan</p>
                                </ButtonApp>
                            </div>
                          </div>
                        </div>

                        <ButtonApp :isDisabled="dataReport.length < 1" :isBold="true" @click="handleExport">
                          <div class="is-flex is-align-items-center">
                            <p class="py-2 mr-2">Export</p>
                            <DownloadWhiteIcon />
                          </div>
                        </ButtonApp>
                    </div>
                </div>
            </div>
        </div>
        <!-- Filter -->

        <div v-if="isLoading" class="loading-wrapper">
          <SpinnerIcon />
        </div>

        <!-- Table Follow Up -->
        <div v-else-if="!isLoading && dataReport.length > 0">
            <table class="table is-bordered is-narrow is-hoverable is-fullwidth table-layout-fixed">
              <tr style="height: 1px;" class="has-background-grey has-text-white has-text-centered tr-wrapper">
                  <td style="width: 5%" class="py-4">
                      <div class="c-h-100 is-flex is-align-items-center is-justify-content-center">
                          <p class="averta-bold label-12">NO</p>
                      </div>
                  </td>
                  <td style="width: 8%" class="py-4">
                      <div class="c-h-100 is-flex is-align-items-center is-justify-content-center">
                          <p class="averta-bold label-12">ID SURVEY</p>
                      </div>
                  </td>
                  <td style="width: 15%" class="py-4">
                      <div class="c-h-100 is-flex is-align-items-center is-justify-content-center">
                          <p class="averta-bold label-12">CLIENT</p>
                      </div>
                  </td>
                  <td style="width: 20%" class="py-4">
                      <div class="c-h-100 is-flex is-align-items-center is-justify-content-center">
                          <p class="averta-bold label-12">TOKO/OUTLET <span class="ml-3 is-clickable" @click="handleOrderBy('store_name')">&#x21c5;</span></p>
                      </div>
                  </td>
                  <td style="width: 15%" class="py-4">
                      <div class="c-h-100 is-flex is-align-items-center is-justify-content-center">
                          <p class="averta-bold label-12">STATUS</p>
                      </div>
                  </td>
                  <td style="width: 12%" class="py-4">
                      <div class="c-h-100 is-flex is-align-items-center is-justify-content-center">
                          <p class="averta-bold label-12">TANGGAL <br>KIRIM PENAWARAN</p>
                      </div>
                  </td>
                  <td style="width: 13%" class="py-4">
                      <div class="c-h-100 is-flex is-align-items-center is-justify-content-center">
                          <p class="averta-bold label-12">NILAI KONTRAK <br>(Inc. Ppn) <span class="ml-3 is-clickable" @click="handleOrderBy('contract_value')">&#x21c5;</span></p>
                      </div>
                  </td>
                  <td style="width: 15%" class="py-4">
                      <div class="c-h-100 is-flex is-align-items-center is-justify-content-center">
                          <p class="averta-bold label-12">TANGGAL AKAN <br>FOLLOW UP</p>
                      </div>
                  </td>
              </tr>
              <tr v-for="(itemList, indexList) in dataReport" :key="`list-${indexList}`" class="tr-wrapper" :class="{'bg-lightgray': (indexList + 1) % 2 === 0}">
                  <td>
                      <div class="is-flex is-align-items-center is-justify-content-center">
                          <p>{{indexList + 1}}</p>
                      </div>
                  </td>
                  <td>
                      <div class=" is-flex is-align-items-center averta-bold">
                          <p>{{itemList.survey_id}}</p>
                          <div class="copy-icon" @click="handleCopy(itemList.survey_id)">
                              <img :src="require('@/assets/img/copy-icon-2.png')" class="is-clickable">
                          </div>
                      </div>
                  </td>
                  <td>
                      <div class="is-flex is-justify-content-center is-align-items-center is-flex-direction-column averta-bold ellipsis-content">
                          <div class="img-vendor">
                            <img v-if="itemList.vendor_logo" :src="itemList.vendor_logo" alt="">
                            <img v-else :src="require('@/assets/img/empty-img-2.png')" alt="">
                          </div>
                          <p>{{itemList.vendor_name}}</p>
                      </div>
                  </td>
                  <td>
                      <div class="is-flex is-align-items-center">
                        <div class="averta-bold text-merah is-clickable ellipsis-content">
                          <p @click="handleClickDetail({projectId: itemList.project_id, offeringId: itemList.offering_id, surveyId: itemList.survey_id})">{{itemList.store_name}}</p>
                        </div>
                      </div>
                  </td>
                  <td>
                      <div class="is-flex is-align-items-center is-justify-content-center is-flex-direction-column">
                          <div class="status-survey tag-status-wrapper" :class="`${getClassName(itemList.status)}`">
                            <p class="label-12">{{wordingHelper(itemList.status)}}</p>
                          </div>
                      </div>
                  </td>
                  <td>
                      <div class="is-flex is-align-items-center averta-bold is-justify-content-center">
                          <p>{{itemList.offering_date}}</p>
                      </div>
                  </td>
                  <td>
                      <div class="is-flex is-align-items-center is-justify-content-space-between averta-bold">
                          <p>Rp</p>
                          <p>{{formatPrice(itemList.contract_value)}}</p>
                      </div>
                  </td>
                  <td>
                      <div class="is-flex is-align-items-center is-justify-content-center">
                          <DateFollowUp
                              :surveyId="itemList.survey_id"
                              :statusFollowUpProps="itemList.follow_up_status"
                              :dateFollowUpProps="itemList.follow_up_date"
                              :isGroupLast="dataReport.length > 5 && indexList > dataReport.length - 2"
                          />
                      </div>
                  </td>
              </tr>
            </table>
        </div>
        <!-- Table Follow Up -->

        <div v-else-if="!isLoading && dataReport.length < 1" class="empty-wrapper">
            <ProyekEmptyIcon />
            <p class="averta-bold label-16">Tidak Ada Data</p>
            <p class="color-gray">Periksa kembali kata kunci pencarian yang dimasukan</p>
        </div>

        <!-- Button Scroll Top -->
        <div v-if="!isLoading && dataReport.length > 0">
          <ButtonScrollTop :offsetWindowScroll="200" elementPoint="mainContainer" />
        </div>
        <!-- Button Scroll Top -->

    </div>
</template>

<script>
// Helpers
import moment from 'moment'
import {
  alertErrorMessage,
  formatCurrency,
  getStatusClassName,
  wordingMenuHelper,
  handleCopyText
} from '@/helpers'
import { mapGetters } from 'vuex'

// Assets
import { SpinnerIcon, ProyekEmptyIcon, ScheduleIcon, DownloadWhiteIcon } from '@/components/icons'

// Components
import InputAutocomplete from '@/components/input/InputAutocomplete.vue'
import DateFollowUp from './components/DateFollowUp.vue'
import TitleHeader from './components/TitleHeader'
import ButtonApp from '@/components/button/ButtonApp.vue'
import FilterStatusFollowUp from './components/FilterStatusFollowUp.vue'
import ButtonScrollTop from '@/components/button/ButtonScrollTop.vue'

export default {
  name: 'ReportFollowUp',
  components: {
    // Icons
    DownloadWhiteIcon,
    SpinnerIcon,
    ProyekEmptyIcon,
    ScheduleIcon,

    // Components
    DateFollowUp,
    InputAutocomplete,
    ButtonApp,
    FilterStatusFollowUp,
    ButtonScrollTop,
    TitleHeader
  },
  data () {
    return {
      isLoading: false,
      dataReport: [],
      showStartDate: false,
      showEndDate: false,
      navbarPosition: null,

      search: '',
      vendor: null,
      status: null,
      startDate: null,
      endDate: null,
      orderBy: null,
      order: 'asc',

      currentPage: 1,
      totalPage: 0,
      totalData: 0,
      limitPage: 20
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user',
      heightNavbar: 'dashboard/getNavbarHeight'
    })
  },
  methods: {
    goBack () {
      this.$router.push({ path: '/am/report' })
    },
    formatDate (date, surveyId) {
      const resultDate = moment(date)

      if (date && resultDate.isValid()) {
        return moment(resultDate).format('DD MMMM YYYY')
      }
      return '-'
    },
    formatPrice (value) {
      if (value || value >= 0) {
        return formatCurrency(value, true)
      }
      return '-'
    },
    getReportFollowUp (props = {}) {
      const { loadMore, hideLoading } = props

      if (!loadMore && !hideLoading) {
        this.isLoading = true
      }

      const payload = {
        url: this.url,
        search: this.search,
        vendor: this.vendor,
        status: this.status,
        startDate: this.startDate ? moment(this.startDate).format('YYYY-MM-DD') : null,
        endDate: this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : null,
        orderBy: this.orderBy,
        order: this.order,
        page: this.currentPage,
        limit: this.limitPage
      }

      this.$store
        .dispatch('report/getListReportFollowUp', payload)
        .then((response) => {
          const res = response.data
          this.currentPage = res.current_page

          if (!loadMore) {
            this.dataReport = res.data

            this.totalPage = res.total_page
            this.totalData = res.total_data
            this.isLoading = false

            // set default state
            this.showStartDate = false
            this.showEndDate = false
          } else {
            this.dataReport = [...this.dataReport, ...res.data]
          }
        }).catch((err) => {
          this.isLoading = false
          alertErrorMessage(err)
        })
    },
    getClassName (status) {
      return getStatusClassName(status)
    },
    wordingHelper (value) {
      return wordingMenuHelper(value)
    },
    handleCopy (value) {
      handleCopyText(value)
    },
    handleSearch () {
      if (!this.search) {
        return alertErrorMessage('Masukan Kata Kunci')
      }
      this.currentPage = 1
      this.getReportFollowUp()
    },
    handleSelectVendor (props = {}) {
      const { dataVendor } = props
      if (dataVendor) {
        this.vendor = dataVendor.name
      } else {
        this.vendor = null
      }
      this.currentPage = 1
      this.getReportFollowUp()
    },
    handleFilterStatus (status) {
      this.status = status
      this.currentPage = 1
      this.getReportFollowUp()
    },
    onCloseStartDate () {
      this.$refs.refStartDate.blur()
      this.showStartDate = false
    },
    onCloseEndDate () {
      this.$refs.refEndDate.blur()
      this.showEndDate = false
    },
    resetDateFilter (type) {
      if (type === 'start') {
        this.startDate = null
      } else if (type === 'end') {
        this.endDate = null
      }

      this.currentPage = 1
      this.getReportFollowUp()
    },
    selectDateOffering () {
      this.currentPage = 1
      this.getReportFollowUp()
    },
    openDateOffering (type) {
      if (type === 'start') {
        this.showStartDate = !this.showStartDate
        this.showEndDate = false
      } else if (type === 'end') {
        this.showEndDate = !this.showEndDate
        this.showStartDate = false
      }
    },
    closeDateOffering (type) {
      if (type === 'start') {
        this.showStartDate = false
      } else if (type === 'end') {
        this.showEndDate = false
      }
    },
    handleOrderBy (type) {
      // check if first time orderBy
      if (!this.orderBy || type !== this.orderBy) {
        this.order = 'asc'
      } else {
        if (this.order === 'asc') {
          this.order = 'desc'
        } else {
          this.order = 'asc'
        }
      }

      this.orderBy = type
      this.currentPage = 1
      this.getReportFollowUp({ hideLoading: true })
    },
    handleClickDetail (props = {}) {
      const { projectId, offeringId, surveyId } = props

      let routeData
      if (projectId) {
        routeData = this.$router.resolve({
          path: `/am/project/${projectId}/detail`
        })
      } else if (offeringId) {
        routeData = this.$router.resolve({
          path: `/am/project/survey/${surveyId}/detail`
        })
      }

      window.open(routeData.href, '_blank')
    },
    handleExport () {
      if (this.dataReport.length < 1) {
        return
      }

      const routeData = this.$router.resolve({
        path: '/am/print-follow-up',
        query: {
          search: this.search,
          vendor: this.vendor,
          status: this.status,
          startDate: this.startDate ? moment(this.startDate).format('YYYY-MM-DD') : null,
          endDate: this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : null,
          orderBy: this.orderBy,
          order: this.order
        }
      })
      window.open(routeData.href, '_blank')
    },
    handleScroll () {
      const windowHeight = window.innerHeight
      const scrollY = window.scrollY || window.pageYOffset
      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight
      )

      const offset = 1

      if (documentHeight - (scrollY + windowHeight) < offset) {
        if (this.currentPage < this.totalPage) {
          this.currentPage++
          this.getReportFollowUp({ loadMore: true })
        }
      }
    },
    adjustStickyPosition () {
      setTimeout(() => {
        if (this.heightNavbar) {
          this.navbarPosition = this.heightNavbar + 'px'
        }
      }, 600)
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('scroll', this.showButtonScrollUp)
    this.adjustStickyPosition()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('scroll', this.showButtonScrollUp)
  },
  created () {
    this.getReportFollowUp()
  },
  watch: {
    search (val) {
      if (val === '') {
        this.currentPage = 1
        this.getReportFollowUp()
      }
    },
    heightNavbar (val) {
      if (val) {
        this.adjustStickyPosition()
      }
    }
  }
}
</script>

<style scoped>
.wrap-follow-up {
    width: 100%;
    min-height: 100vh;
}

.tr-wrapper {
  height: 1px;
}

.tr-wrapper td {
  border: 1px solid #C0C0C0 !important;
  padding: 10px;
}

.tr-wrapper td {
  height: inherit;
}

.tr-wrapper td > div:first-child {
  height: 100%;
}

.copy-icon {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
}

.copy-icon {
    object-fit: contain;
}

.loading-wrapper {
    width: 100%;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.empty-wrapper {
  margin-top: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.tag-status-wrapper {
  width: 100%;
  max-width: 20em;
  padding: 18px 10px;
  text-align: center;
}

.client-wrapper {
  width: 13em;
}

.date-wrapper {
  position: absolute;
  right: 0;
  top: 45px;
  z-index: 2;
  background: white;
  border-radius: 12px;
  box-shadow: 6px 7px 50px 0px #00000014;
}

.result-date-wrapper {
  height: 40px;
  width: 180px;
  cursor: pointer;
  border: 1px solid #E1E1E1;
  background: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  overflow: hidden !important;
}

.divider {
    width: 90%;
    border: 1px solid #E1E1E1;
    margin: 0 auto;
}

.filter-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 5;
    position: sticky;
    background: white;
    border-bottom: 2px solid #e1e1e1;
}
</style>

<style>
div.date-wrapper .datepicker .dropdown-content {
    box-shadow: none !important;
    border-radius: 12px;
    border-bottom: none;
}

.b-input-wrapper input {
  border: 1px solid #e1e1e1;
  font-size: 14px;
  padding: 21px 33px;
  outline: none;
}

.b-input-wrapper input:hover, .b-input-wrapper input:focus {
  border: 1px solid #e1e1e1;
}
</style>
