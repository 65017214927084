<template>
  <div class="select-wrapper">
    <div
      class="wrap-selected is-flex is-align-items-center is-clickable"
      :class="{ 'disabled': disableDropdown }"
      @click="showDropdownFunc"
      ref="selectBranch"
      v-closable2="{
        exclude: ['selectBranch', 'searchStore'],
        handler: 'hideDropdown'
      }"
    >
      <div v-if="storeSelected !== ''">
        <p class="averta-bold">{{ storeSelected.name }}</p>
      </div>
      <p v-else class="text-grey">Pilih Toko</p>
      <img :src="require('@/assets/img/arrow-down-1.png')" class="arrow-autocomplete-1" :class="{'open': showDropdown}" />
    </div>
    <div :style="{ display: showDropdown ? 'block' : 'none' }">
      <div class="form-input">
        <div ref="searchStore">
          <b-input
            class="input-search"
            placeholder="Cari"
            type="search"
            icon="magnify"
            @input="searchStoreFunc"
            v-model="searchStore"
          />
        </div>
        <div class="list-branch">
          <div
            class="branch-item"
            v-for="(store, index) in listStoreFilter"
            :key="index"
            @click="selectStoreFunc(store)"
          >
            <p class="label-14">{{ store.name }}</p>
            <p class="label-14">{{ store.company_name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { validationClientAndBranch, alertErrorMessage } from '@/helpers'

export default {
  name: 'StoreAutocomplete',
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      payloadStore: 'client/getPayloadStore',
      payloadAddPicBranch: 'client/getPayloadAddPicBranch',
      clearDataPayloadPicBranch: 'client/getClearDataPayloadPicBranch'
    })
  },
  data () {
    return {
      showDropdown: false,
      storeSelected: '',
      searchStore: '',
      disableDropdown: true,
      listStore: [],
      listStoreFilter: []
    }
  },
  methods: {
    getListStore () {
      if (
        this.$route.params.vendorId ||
        this.payloadAddPicBranch.branchSelected.id
      ) {
        const payload = {
          url: this.url,
          branch_id:
            this.$route.params.vendorId ||
            this.payloadAddPicBranch.branchSelected.id
        }

        this.$store
          .dispatch('client/getListStore', payload)
          .then(response => {
            const res = response.data.data
            this.listStore = res.list
            this.listStoreFilter = res.list
          })
          .catch(error => {
            alertErrorMessage(error)
          })
      }
    },
    showDropdownFunc () {
      if (this.disableDropdown === false) {
        this.showDropdown = !this.showDropdown
      }
    },
    hideDropdown () {
      this.showDropdown = false
    },
    selectStoreFunc (data) {
      this.storeSelected = data
      this.showDropdown = false
      this.$store.commit('client/setPayloadStore', { selectedStore: data })
    },
    searchStoreFunc () {
      const searchStore = this.listStore.filter(store => {
        return store.name
          .toLowerCase()
          .includes(this.searchStore.toLowerCase())
      })

      this.listStoreFilter = searchStore
    },
    handleValidationInput () {
      this.disableDropdown = validationClientAndBranch(this.payloadAddPicBranch)
      if (!this.disableDropdown) {
        this.getListStore()
      } else {
        this.searchStore = ''
        this.storeSelected = ''
        this.$store.commit('client/setPayloadStore', { selectedStore: {} })
      }
    }
  },
  mounted () {
    this.handleValidationInput()
  },
  watch: {
    searchStore (newValue) {
      if (newValue === '') {
        this.listStoreFilter = this.listStore
      }
    },
    payloadAddPicBranch: {
      handler () {
        this.handleValidationInput()
      },
      deep: true
    },
    clearDataPayloadPicBranch (newValue) {
      if (newValue === true) {
        this.storeSelected = ''
      }
    }
  }
}
</script>

<style scoped>
.select-wrapper {
  width: 100%;
  position: relative;
}

.wrap-selected {
  border: 1px solid #e1e1e1;
  background: white;
  border-radius: 8px;
  padding: 8px 12px;
  width: 100%;
  height: 40px;
}

.wrap-selected.disabled {
  background: #f2f2f2;
}

.form-input {
  background: white;
  border-radius: 8px;
  padding: 8px 12px;
}

.list-branch {
  max-height: 387px;
  overflow-y: auto;
}

.branch-item {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 12px;
}

.branch-item:first-child {
  margin-top: 10px;
}

.branch-item:hover {
  background: #fcecec;
}
</style>
