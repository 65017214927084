<template>
  <SidebarClientTemplate
    :show="showSidebar"
    :disabledSubmit="disabledSubmit"
    @closeSidebar="handleCloseSidebar"
    @submit="handleSubmit"
  >
    <template v-slot:bodySidebar>
      <b-loading :is-full-page="true" v-model="isLoadingSubmit" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>
      <div class="pb-6">
        <p class="averta-black label-20 mb-4">Edit PIC Cabang</p>

        <ClientAndBranchInfo :detailClient="getDetailClient" :detailBranch="getDetailBranch"/>

        <BranchInfo currentTab="pic" typeSection="edit" />
      </div>

      <!-- Modal Submit -->
      <ModalConfirm
        :showModal="showModalConfirm"
        imageProps="sunset.png"
        titleProps="Semua Data Yang Dimasukkan Sudah Benar?"
        descProps="Pastikan kamu sudah melakukan pengecekkan sebelum diubah"
      >
        <template v-slot:button>
          <div class="is-flex is-align-items-center">
            <ButtonApp
              :isSecondary="true"
              :is-bold="true"
              width="185px"
              height="52px"
              class="mr-3"
              @click="cancelClose"
            >
              Batalkan
            </ButtonApp>
            <ButtonApp :is-bold="true" width="185px" height="52px" @click="confirmModal">
              Simpan Perubahan
            </ButtonApp>
          </div>
        </template>
      </ModalConfirm>
    </template>
  </SidebarClientTemplate>
</template>

<script>
import { mapGetters } from 'vuex'
import { validationPayloadHelper, alertErrorMessage } from '@/helpers'
import { SpinnerIcon } from '@/components/icons'
import BranchInfo from './BranchInfo.vue'
import ButtonApp from '@/components/button/ButtonApp.vue'
import ModalConfirm from '../ModalConfirm.vue'
import ClientAndBranchInfo from '../store-components/ClientAndBranchInfo.vue'
import SidebarClientTemplate from '@/components/sidebar/SidebarClientTemplate.vue'

export default {
  name: 'SidebarEditPICBranch',
  components: {
    SidebarClientTemplate,
    SpinnerIcon,
    BranchInfo,
    ButtonApp,
    ModalConfirm,
    ClientAndBranchInfo
  },
  props: {
    show: {
      type: Boolean
    },
    close: {
      type: Function
    },
    selectedBranch: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      payloadAddPicBranch: 'client/getPayloadAddPicBranch',
      detailClient: 'client/getPayloadClientSelectedBranch',
      getDetailClient: 'client/getDetailClient',
      getDetailBranch: 'client/getDetailBranch'
    })
  },
  data () {
    return {
      showSidebar: false,
      showModalConfirm: false,
      isLoadingSubmit: false,
      disabledSubmit: true,
      typeSection: '',
      name: '',
      companyName: '',
      logo: ''
    }
  },
  methods: {
    cancelClose () {
      this.showModalConfirm = false
    },
    confirmModal () {
      this.isLoadingSubmit = true
      this.showModalConfirm = false

      const payload = {
        url: this.url,
        user_id: this.payloadAddPicBranch.dataPic[0].id,
        data: {
          name: this.payloadAddPicBranch.dataPic[0].name,
          email: this.payloadAddPicBranch.dataPic[0].email,
          phone: this.payloadAddPicBranch.dataPic[0].phone
        }
      }

      this.$store
        .dispatch('client/updatePICBranch', payload)
        .then(() => {
          this.isLoadingSubmit = false
          this.$buefy.toast.open({
            message:
              '<p class="averta-regular">PIC Cabang Berhasil Di Ubah</p>',
            type: 'is-success'
          })
          this.handleCloseSidebar()
          this.$emit('updateTable')
        })
        .catch(error => {
          alertErrorMessage(error)
          this.isLoadingSubmit = false
        })
    },
    handleSubmit () {
      this.showModalConfirm = true
    },
    handleCloseSidebar () {
      this.$store.commit('client/setPayloadAddPicBranch', {
        type: 'dataPic',
        dataPic: [
          {
            name: '',
            email: '',
            phone: ''
          }
        ]
      })
      this.$store.commit('client/setDetailPICBranch', null)
      this.showSidebar = false
      this.close()
    }
  },
  watch: {
    show (value) {
      this.showSidebar = value
      if (value) {
        this.typeSection = 'information'
        if (this.detailClient) {
          this.name = this.detailClient.name
          this.companyName = this.detailClient.company_name
          this.logo = this.detailClient.logo
        }
      }
    },
    payloadAddPicBranch: {
      handler (value) {
        value.clientSelected = this.getDetailClient
        this.disabledSubmit = validationPayloadHelper(value, 'pic')
      },
      deep: true
    }
  }
}
</script>
