import { render, staticRenderFns } from "./TabHistorySurvey.vue?vue&type=template&id=165c183b&scoped=true&"
import script from "./TabHistorySurvey.vue?vue&type=script&lang=js&"
export * from "./TabHistorySurvey.vue?vue&type=script&lang=js&"
import style0 from "./TabHistorySurvey.vue?vue&type=style&index=0&id=165c183b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "165c183b",
  null
  
)

export default component.exports