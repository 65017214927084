<template>
  <b-modal
    v-model="isModalActive"
    :width="425"
    :can-cancel="false"
    class="modal-preview"
  >
    <div class="content-modal">
      <div class="modal-body has-text-centered">
        <div class="image-confirm flex-center" :class="`${imageClass}`">
          <img
            v-if="imageProps"
            :src="require(`@/assets/img/${imageProps}`)"
          />
        </div>
        <div class="mb-4" :class="`${descClass}`">
          <p class="averta-bold label-18 mb-1">{{ titleProps }}</p>
          <div class="mb-3">
            <p class="label-14">{{ descProps }}</p>
            <slot name="additionalDesc"></slot>
          </div>
        </div>
        <slot name="additional"></slot>
        <div class="mb-4 c-w-95 flex-center">
          <slot name="button"></slot>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalConfirm',
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    imageProps: {
      type: String,
      default: ''
    },
    imageClass: {
      type: String,
      default: ''
    },
    descClass: {
      type: String,
      default: ''
    },
    titleProps: {
      type: String,
      default: ''
    },
    descProps: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isModalActive: false
    }
  },
  watch: {
    showModal (newValue) {
      this.isModalActive = newValue
    }
  }
}
</script>

<style scoped>
.modal-preview {
  background-color: rgba(0, 0, 0, 0.2);
}

.content-modal {
  width: 100%;
}

.modal-body {
  background: white;
  border-radius: 12px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-confirm {
  height: 190px;
  margin: 30px 0px 50px 0px;
}

.image-confirm img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 768px) {
  .content-modal {
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .modal-body {
    padding-bottom: 30px;
  }
}
</style>
