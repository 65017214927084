<template>
  <div
    class="wrap-banner is-flex is-justify-content-space-between is-align-items-center"
  >
    <div>
      <p class="averta-black label-14 mb-1">
        Tambah PIC Cabang lainnya di cabang ini?
      </p>
      <p class="label-14 mb-3">
        Kamu dapat menambahkan lebih dari 1 PIC cabang dalam satu cabang
      </p>
      <ButtonApp :is-bold="true" width="142px" height="33px" @click="addPic">
        + PIC Cabang
      </ButtonApp>
    </div>
    <img
      src="@/assets/img/add-pic-banner.png"
      alt="arrow-right"
      class="arrow-right"
    />
  </div>
</template>

<script>
import ButtonApp from '@/components/button/ButtonApp.vue'

export default {
  name: 'AddPicBranchBanner',
  components: {
    ButtonApp
  },
  methods: {
    addPic () {
      this.$emit('addPic')
    }
  }
}
</script>

<style scoped>
.wrap-banner {
  background: linear-gradient(to right, #ffdedf, #fcdeff);
  padding: 20px;
  border-radius: 20px;
}
</style>
