<template>
    <ClientSkeleton v-if="isLoading" class="px-6 pb-6 pt-5" />
    <div class="container is-fluid pt-5 pb-6 px-6 min-h-90vh" v-else>
      <div class="is-flex is-justify-content-space-between mb-5">
        <div class="is-flex is-align-items-center is-clickable" @click="goBack()">
          <ArrowLeftIcon class="mr-3" />
          <p class="averta-bold is-size-5">Cabang {{ listStore.branch.name }}</p>
        </div>
        <div class="flex-center-vertical">
          <b-field class="mb-0" v-if="tabActive === 'list'">
            <b-input
              placeholder="Cari nama toko"
              type="search"
              icon="magnify"
              @icon-click="search = ''"
              @keyup.native.enter="getListStore"
              v-model="search"
            >
            </b-input>
          </b-field>
          <b-field class="mb-0" v-if="tabActive === 'pic'">
            <b-input
              placeholder="Cari nama pic"
              type="search"
              icon="magnify"
              @icon-click="searchPic = ''"
              @keyup.native.enter="filterPic"
              v-model="searchPic"
            >
            </b-input>
          </b-field>
          <ButtonApp @click="handleAddStore" class="ml-5" v-if="tabActive === 'list'">
            <p class="averta-bold color-white py-2 px-5">+ Tambah Toko</p>
          </ButtonApp>
          <ButtonApp @click="handleAddPicBranch" class="ml-5" v-if="tabActive === 'pic'">
            <p class="averta-bold color-white py-2 px-5">+ Tambah PIC</p>
          </ButtonApp>
        </div>
      </div>

      <div class="flex-center-vertical mb-5">
        <div class="btn-tabs-2 mr-3" @click="handleClickTab('list')" :class="{'is-active': tabActive === 'list'}">
          <p>Daftar Toko</p>
        </div>
        <div class="btn-tabs-2" @click="handleClickTab('pic')" :class="{'is-active': tabActive === 'pic'}">
          <p>PIC Cabang</p>
        </div>
      </div>

      <TabListStore
        v-if="tabActive === 'list'"
        :listStore="listStore"
        :refreshData="getListStore"
        @edit="editStore($event)"
      />
      <TabPICBranch
        v-if="tabActive === 'pic'"
        :dataPic="dataPicFilter"
        :refreshData="getListStore"
      />

      <SidebarStore :show="showSidebar" :close="handleCloseSidebar" :showTabButton="showTabButton" @refreshData="getListStore" />
      <SidebarAddPICBranch :show="sidebarAddPIC" :close="handleCloseSidebar" @reloadData="getListStore($event)" />
    </div>
</template>

<script>
// Helpers
import { mapGetters } from 'vuex'
import { alertErrorMessage } from '@/helpers'

// Icons
import { ArrowLeftIcon } from '@/components/icons'

// Components
import ClientSkeleton from '@/components/skeleton/ClientSkeleton.vue'
import ButtonApp from '@/components/button/ButtonApp.vue'
import TabPICBranch from './components/store-components/TabPICBranch.vue'
import TabListStore from './components/store-components/TabListStore.vue'
import SidebarStore from './components/SidebarStore.vue'
import SidebarAddPICBranch from './components/branch-components/SidebarAddPICBranch.vue'

export default {
  name: 'ListStore',
  components: {
    // Icons
    ArrowLeftIcon,

    // Components
    ClientSkeleton,
    // eslint-disable-next-line vue/no-unused-components
    ButtonApp,
    TabListStore,
    TabPICBranch,
    SidebarStore,
    SidebarAddPICBranch
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  data () {
    return {
      isLoading: false,
      listStore: null,
      initListStore: null,
      search: null,
      tabActive: 'list', // list or pic
      showSidebar: false,
      showTabButton: true,
      sidebarAddPIC: false,
      searchPic: '',
      dataPicFilter: null
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    editStore (data) {
      this.$store.commit('client/setTypeSidebarStore', 'edit-store')
      this.$store.commit('client/setPayloadStore', { formStore: data })
      this.showSidebar = true
      this.showTabButton = false
      // console.log(data)
    },
    getListStore (props) {
      this.isLoading = true
      const payload = {
        url: this.url,
        branch_id: this.$route.params.branchId,
        search: this.search
      }

      this.$store
        .dispatch('client/getListStore', payload)
        .then(response => {
          this.listStore = response.data.data
          if (!this.initListStore || (props && props.refresh)) {
            this.initListStore = JSON.parse(JSON.stringify(this.listStore))
          }
          if (!this.dataPicFilter || (props && props.refresh)) {
            this.dataPicFilter = {
              branch: response.data.data.branch,
              pics: response.data.data.pics
            }
          }

          this.$store.commit('client/setDetailClient', { data: response.data.data.vendor })
          this.$store.commit('client/setDetailBranch', { data: response.data.data.branch })
          this.$store.commit('client/setPayloadAddPicBranch', {
            type: 'branchSelected',
            branchSelected: response.data.data.branch
          })

          this.$store.commit('client/setPayloadAddPicBranch', {
            type: 'clientSelected',
            clientSelected: response.data.data.vendor
          })

          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    filterPic () {
      const filter = this.initListStore.pics.filter(pic => {
        return pic.name
          .toLowerCase()
          .includes(this.searchPic.toLowerCase())
      })

      this.dataPicFilter.pics = filter
    },
    handleClickTab (tab) {
      this.tabActive = tab
    },
    handleAddStore () {
      this.$store.commit('client/setTypeSidebarStore', 'add-store')
      this.showSidebar = true
      this.showTabButton = true
    },
    handleAddPicBranch () {
      this.sidebarAddPIC = true
    },
    handleCloseSidebar () {
      this.showSidebar = false
      this.sidebarAddPIC = false
      this.$store.commit('client/setClearPayloadStore')
    }
  },
  created () {
    this.getListStore()
  },
  watch: {
    search (newValue) {
      if (newValue === '') {
        this.getListStore()
      }
    },
    searchPic (newValue) {
      if (newValue === '') {
        this.dataPicFilter = {
          branch: this.initListStore.branch,
          pics: this.initListStore.pics
        }
      }
    }
  }
}

</script>

<style scoped>
</style>
