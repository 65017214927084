<template>
    <ModalTemplate :showModal="isModalActive" :width="widthModal" @closeModal="closeModal">
        <template v-slot:headerModal>
            <div class="is-flex is-justify-content-space-between is-align-items-center">
                <p class="averta-bold label-18">Riwayat Catatan</p>
                <div class="is-clickable" @click="closeModal">
                  <b-icon icon="close"></b-icon>
                </div>
            </div>
        </template>
        <template v-slot:bodyModal>
            <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false">
                <SpinnerIcon />
            </b-loading>
            <div class="wrap-body-modal p-5">
                <div v-if="isLoading">
                    <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false">
                      <SpinnerIcon />
                    </b-loading>
                  </div>
                <div v-else-if="!isLoading && dataModal && dataModal.data.length > 0">
                  <div class="wrap-table mb-5">
                      <table class="table is-bordered is-narrow is-hoverable is-fullwidth">
                          <tr class="has-background-grey has-text-white has-text-centered">
                              <td class="py-4">NO</td>
                              <td class="py-4">DIUBAH OLEH</td>
                              <td class="py-4" style="width: 50vw;">CATATAN PROYEK</td>
                              <td class="py-4" style="width: 15vw;">STATUS</td>
                          </tr>
                          <tr class="tr-wrapper" v-for="item, index in dataModal.data" :key="`data-history-${index}`">
                              <td class="py-3 has-text-centered">{{numberingListHistory(index)}}</td>
                              <td>
                                  <div class="py-3">
                                      <p class="mb-1 white-space-nowrap"><span class="averta-bold">{{item.user_name}} |</span> <span class="averta-reguler"><i>{{userRole(item.role_name)}}</i></span></p>
                                      <p class="color-gray">{{formatDateHistory(item.created_at)}}</p>
                                  </div>
                              </td>
                              <td>
                                  <div class="label-14 note-desc-wrapper">
                                      <p class="white-space-pre-line">{{item.note}}</p>
                                  </div>
                              </td>
                              <td>
                                  <div class="status-survey px-3 mt-sm-1 white-space-nowrap"
                                      :class="getClassName(item.status)">
                                      <span>{{item.status}}</span>
                                  </div>
                              </td>
                          </tr>
                      </table>
                  </div>
                  <PaginationApp
                    :total-pages="totalPages"
                    :current-page="currentPage"
                    :isNew="true"
                    :load-page="getNoteProjectHistory"
                    :limit-data="limitPage"
                    :total-data="totalData"
                  />
                </div>
                <div v-if="!isLoading && dataModal && dataModal.data.length < 1" class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                    <ProyekEmptyIcon class="mb-3" />
                    <p class="averta-bold">Tidak Ada Riwayat Catatan Proyek</p>
                </div>
            </div>
        </template>
    </ModalTemplate>
</template>

<script>
import { mapGetters } from 'vuex'
import { getStatusClassName, alertErrorMessage, numberingList, copywritingRole } from '@/helpers'
import ModalTemplate from './ModalTemplate.vue'
import { ProyekEmptyIcon, SpinnerIcon } from '@/components/icons'
import moment from 'moment'
import PaginationApp from '@/components/PaginationApp.vue'

export default {
  name: 'ModalHistoryCatatanProyek',
  components: {
    ModalTemplate,
    SpinnerIcon,
    ProyekEmptyIcon,
    PaginationApp
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '75vw'
    },
    surveyId: {
      type: Number
    },
    refreshData: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isModalActive: false,
      widthModal: this.width,
      isLoading: false,
      dataModal: null,

      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      limitPage: 5
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
    getClassName (status) {
      return getStatusClassName(status)
    },
    getNoteProjectHistory (props = {}) {
      const { pages = 1 } = props
      this.isLoading = true

      const payload = {
        url: this.url,
        survey_id: this.surveyId,
        limit: this.limitPage,
        page: pages
      }

      this.$store
        .dispatch('project/getNoteProjectHistory', payload)
        .then((res) => {
          this.dataModal = res.data
          this.isLoading = false

          this.totalPages = res.data.total_page
          this.totalData = res.data.total_data
          this.currentPage = res.data.current_page

          if (this.dataModal.data.length > 0 && this.currentPage === 1) {
            this.$emit('getFirstNote', this.dataModal.data[0])
          }
          this.$emit('handleRefreshData', false)
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    formatDateHistory (value) {
      return moment(value).format('DD/MM/YYYY - HH:mm')
    },
    numberingListHistory (index) {
      return numberingList(this.limitPage, this.currentPage, index)
    },
    userRole (roleName) {
      return copywritingRole(roleName)
    }
  },
  mounted () {
    if (this.showModal) {
      this.getNoteProjectHistory()
    }
  },
  watch: {
    showModal (value) {
      this.isModalActive = value
      if (value === true) {
        this.getNoteProjectHistory()
      }
    },
    width (value) {
      this.widthModal = value
    },
    surveyId () {
      this.getNoteProjectHistory()
    },
    refreshData (val) {
      this.getNoteProjectHistory()
    }
  }
}
</script>

<style scoped>
.wrap-body-modal {
    height: 70vh;
    overflow-y: auto;
    position: relative;
}

.wrap-table {
  overflow-x: auto;
}

.table td {
    vertical-align: middle;
}

.note-desc-wrapper {
    max-height: 5em;
    overflow: auto;
}

</style>
