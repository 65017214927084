<template>
    <div class="wrap-snackbar is-flex is-justify-content-space-between is-align-items-center">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
            <img :src="require('@/assets/img/folder.gif')" alt="folder-gif" class="mr-3 img-snackbar" width="40">
            <div>
                <p class="averta-bold label-20 has-text-white">Terdapat Request Reschedule Baru</p>
                <p class="label-16 has-text-white">Kamu punya {{ total }} request reschedule</p>
            </div>
        </div>
        <div>
            <button class="btn-detail is-clickable averta-bold" @click="buttonCLick">Lihat Detail</button>
        </div>
    </div>
</template>

<script>

export default {
  name: 'SnackbarRescheduleSurvey',
  props: ['total'],
  methods: {
    buttonCLick () {
      this.$emit('buttonClick')
    }
  }
}
</script>

<style scoped>
.wrap-snackbar {
    background: #10BD41;
    padding: 7px 50px 12px 24px;
    position: fixed;
    width: 96vw;
    z-index: 2;
}

.btn-detail {
    background: none;
    border: 1px solid white;
    padding: 6px 32px;
    color: white;
}

.img-snackbar {
    border-radius: 10px;
}
</style>
