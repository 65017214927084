<template>
    <div class="not-fill">
    <AlertIcon />
    <div class="is-flex is-align-items-center">
      <p class="averta-bold color-red fs-12 ml-1">
          {{title}}
      </p>
      <i v-if="subtitle" class="ml-1 color-red fs-12">({{subtitle}})</i>
    </div>
    </div>
</template>

<script>
import { AlertIcon } from '@/components/icons'
export default {
  name: 'ErrorComponent',
  props: {
    title: {
      type: String,
      default: 'Belum Diisi'
    },
    subtitle: {
      type: String,
      default: ''
    }
  },
  components: {
    AlertIcon
  }
}
</script>

<style scoped>
.color-red {
  color: #d9272d !important;
}

.not-fill {
  background: rgba(255, 0, 0, 0.1);
  border-radius: 8px;
  padding: 4px 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
}

.fs-12 {
  font-size: 12px;
}

.w-92 {
  width: 92% !important;
}
</style>
