<template>
    <div class="wrap-banner is-flex is-justify-content-space-between is-align-items-center">
        <div>
            <p class="averta-bold label-20">{{ client.name }}</p>
            <p class="text-grey label-14">{{ client.company_name }}</p>
        </div>
        <img :src="client.logo" alt="logo" class="wrap-image">
    </div>
</template>

<script>
export default {
  name: 'BannerClient',
  props: {
    client: Object
  }
}
</script>

<style scoped>

.wrap-banner {
    background: #FAFAFA;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
}

.wrap-image {
    height: 32px;
}

</style>
