<template>
  <SidebarClientTemplate
    :show="showSidebar"
    :disabledSubmit="disabledSubmit"
    @closeSidebar="showModalCancelFunc"
    @submit="handleSubmit"
  >
    <template v-slot:bodySidebar>
      <b-loading :is-full-page="true" v-model="isLoadingSubmit" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>
      <div>
        <p class="averta-black label-20 mb-4">Edit Cabang</p>
        <ClientInfo
          currentTab="branch"
          class="mb-5"
          :typeSection="typeSection"
          :name="name"
          :companyName="companyName"
          :logo="logo"
        />

        <div class="wrap-client">
          <div class="mb-5">
            <p class="averta-black label-20">Informasi Cabang</p>
          </div>
          <div class="is-flex is-justify-content-space-between is-align-items-center mb-5">
            <p class="label-14 mr-3 white-space-nowrap">
              <span class="averta-bold">Nama Cabang </span>
              <span class="text-merah">*</span>
            </p>
            <input
              type="text"
              class="input"
              placeholder="Masukkan nama cabang"
              v-model="branchName"
            />
          </div>
        </div>
      </div>

      <!-- Modal Cancel -->
      <ModalConfirm
        :showModal="showModalCancel"
        imageProps="sunset.png"
        titleProps="Batalkan Perubahan Cabang?"
        descProps="Jika data sudah diisi maka akan dihapus oleh sistem"
      >
        <template v-slot:button>
          <div class="is-flex is-align-items-center mt-4">
            <ButtonApp
              :isSecondary="true"
              :is-bold="true"
              width="185px"
              height="52px"
              class="mr-3"
              @click="cancelClose"
            >
              Kembali
            </ButtonApp>
            <ButtonApp :is-bold="true" width="185px" height="52px" @click="handleCloseSidebar">
              Batalkan Perubahan
            </ButtonApp>
          </div>
        </template>
      </ModalConfirm>
      <!-- Modal Cancel -->

      <!-- Modal Submit -->
      <ModalConfirm
        :showModal="showModalConfirm"
        imageProps="sunset.png"
        titleProps="Semua Data Yang Dimasukkan Sudah Benar?"
        descProps="Pastikan kamu sudah melakukan pengecekkan sebelum diubah"
      >
        <template v-slot:button>
          <div class="is-flex is-align-items-center mt-4">
            <ButtonApp
              :isSecondary="true"
              :is-bold="true"
              width="185px"
              height="52px"
              class="mr-3"
              @click="cancelClose"
            >
              Batalkan
            </ButtonApp>
            <ButtonApp :is-bold="true" width="185px" height="52px" @click="confirmModal">
              Simpan Perubahan
            </ButtonApp>
          </div>
        </template>
      </ModalConfirm>
      <!-- Modal Submit -->

    </template>
  </SidebarClientTemplate>
</template>

<script>
import { mapGetters } from 'vuex'
import { SpinnerIcon } from '@/components/icons'
import { alertErrorMessage } from '@/helpers'
import SidebarClientTemplate from '@/components/sidebar/SidebarClientTemplate.vue'
import ButtonApp from '@/components/button/ButtonApp.vue'
import ModalConfirm from '../ModalConfirm.vue'
import ClientInfo from './ClientInfo.vue'

export default {
  name: 'SidebarEditBranch',
  components: {
    SidebarClientTemplate,
    SpinnerIcon,
    ModalConfirm,
    ClientInfo,
    ButtonApp
  },
  props: {
    show: {
      type: Boolean
    },
    close: {
      type: Function
    },
    selectedBranch: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      detailClient: 'client/getPayloadClientSelectedBranch'
    })
  },
  data () {
    return {
      showSidebar: false,
      showModalConfirm: false,
      showModalCancel: false,
      isLoadingSubmit: false,
      disabledSubmit: false,
      typeSection: '',
      name: '',
      companyName: '',
      logo: '',
      branchName: ''
    }
  },
  methods: {
    cancelClose () {
      this.showModalConfirm = false
      this.showModalCancel = false
    },
    showModalCancelFunc () {
      if (this.branchName !== this.selectedBranch.name && this.branchName !== '') {
        this.showModalCancel = true
      } else {
        this.handleCloseSidebar()
      }
    },
    confirmModal () {
      this.isLoadingSubmit = true
      this.showModalConfirm = false

      const payload = {
        url: this.url,
        branch_id: this.selectedBranch.id,
        name: this.branchName
      }

      this.$store
        .dispatch('client/updateBranch', payload)
        .then(response => {
          this.isLoadingSubmit = false
          this.showSidebar = false
          this.close()
          this.$emit('updateBranch')
          this.$buefy.toast.open({
            message: '<p class="averta-regular">Cabang Berhasil Di Ubah</p>',
            type: 'is-success'
          })
        })
        .catch((error) => {
          alertErrorMessage(error)
          this.isLoadingSubmit = false
        })
    },
    handleSubmit () {
      this.showModalConfirm = true
    },
    handleCloseSidebar () {
      this.showModalCancel = false
      this.showSidebar = false
      this.close()
    },
    disableButton (value) {
      if (value === '') {
        this.disabledSubmit = true
      } else if (value !== this.selectedBranch.name) {
        this.disabledSubmit = false
      } else {
        this.disabledSubmit = true
      }
    }
  },
  watch: {
    show (value) {
      this.showSidebar = value
      if (value) {
        this.typeSection = 'information'
        if (this.detailClient) {
          this.name = this.detailClient.name
          this.companyName = this.detailClient.company_name
          this.logo = this.detailClient.logo
        }

        if (this.selectedBranch) {
          this.branchName = this.selectedBranch.name
        }
      }
    },
    branchName (newValue) {
      this.disableButton(newValue)
    }
  }
}
</script>
