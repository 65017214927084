<template>
    <ModalTemplate :showModal="isModalActive" :width="isMobile ? '95vw' : widthModal" @closeModal="closeModal">
        <template v-slot:headerModal>
            <div class="is-flex is-justify-content-space-between is-align-items-center">
                <div class="is-flex is-justify-content-space-between is-align-items-center">
                    <CheckIcon class="mr-2" />
                    <p class="averta-bold label-18">Ubah Tujuan</p>
                </div>
                <div class="is-clickable" @click="closeModal">
                  <b-icon icon="close"></b-icon>
                </div>
            </div>
        </template>
        <template v-slot:bodyModal>
            <div class="wrap-body-modal p-5">
                <p class="mb-2 form-control">Tujuan Pengiriman <span class="text-merah">*</span></p>
                <b-input type="textarea" rows="2" resize="none" placeholder="Masukkan tujuan" v-model="tujuan" />
                <div class="form-button is-flex is-justify-content-end is-align-items-center" :class="{ 'active': tujuan.length > 0 }">
                    <b-button @click="saveTujuan">Simpan Perubahan</b-button>
                </div>
            </div>
        </template>
    </ModalTemplate>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalTemplate from './ModalTemplate.vue'
import { CheckIcon } from '@/components/icons'
import saveImage from '@/assets/img/delete-area-component.png'

export default {
  name: 'ModalUbahTujuan',
  components: { ModalTemplate, CheckIcon },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '75vw'
    },
    data: {
      type: String,
      default: ''
    },
    materialOrderId: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    isMobile () {
      if (window.innerWidth <= 768) {
        return true
      }
      return false
    }
  },
  data () {
    return {
      isModalActive: false,
      widthModal: this.width,
      isLoading: false,
      modeEdit: false,
      tujuan: '',
      idMaterialOrder: null
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
      this.tujuan = ''
    },
    saveTujuan () {
      if (this.tujuan.length > 0) {
        this.$swal({
          imageUrl: saveImage,
          imageAlt: 'save data',
          width: '540px',
          padding: '10px 10px 30px 10px',
          title: '<p class="averta-bold label-22">Simpan Perubahan Tujuan</p>',
          html: '<p>Pastikan seluruh informasi material yang diinginkan telah sesuai.</p>',
          showCancelButton: true,
          confirmButtonText: 'Simpan',
          cancelButtonText: 'Batalkan',
          customClass: {
            confirmButton: 'btn-hapus-detail-component',
            cancelButton: 'btn-batal-detail-component'
          }
        }).then((result) => {
          this.$emit('isLoading', true)
          if (result.isConfirmed) {
            const payload = {
              url: this.url,
              material_order_id: this.idMaterialOrder,
              destination: this.tujuan
            }

            this.$store
              .dispatch('project/updateDestination', payload)
              .then((response) => {
                this.$emit('reloadData')
                this.tujuan = ''
                this.idMaterialOrder = null
                this.$buefy.toast.open({
                  message: '<p class="averta-regular">Berhasil update data</p>',
                  type: 'is-success'
                })
                this.$emit('isLoading', false)
              })
              .catch((error) => {
                console.log(error)
                this.$buefy.toast.open({
                  message: '<p class="averta-regular">Gagal update data, silahkan coba lagi</p>',
                  type: 'is-danger'
                })
                this.$emit('isLoading', false)
              })
          }
        })
      }
    }
  },
  watch: {
    showModal (newValue) {
      this.isModalActive = newValue
    },
    width (newValue) {
      this.widthModal = newValue
    },
    data (newValue) {
      this.tujuan = newValue
    },
    materialOrderId (newValue) {
      this.idMaterialOrder = newValue
    }
  }
}
</script>

<style scoped>
.wrap-body-modal {
    position: relative;
    height: 35vh;
}

.form-button {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 12px;
    width: 100%;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.05);
}

.form-button button {
    background: #9A9A9A;
    border-radius: 100px;
    color: white;
    font-family: 'Averta-Bold';
    padding: 24px;
}

.form-button.active button {
    background: var(--Gradient-Red-Red-Button-Solid, linear-gradient(116deg, #D9272D 16.15%, #EB4600 83.25%));
}
</style>
