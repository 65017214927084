<template>
    <div class="py-6 wrap-search">
        <!-- Header -->
        <div class="is-flex is-align-items-center mb-5 is-clickable">
            <div @click="handleBack">
              <ArrowLeftIcon class="mr-4" />
            </div>
            <p class="averta-bold label-20" @click="handleBack">Hasil Pencarian</p>
        </div>
        <!-- Header -->

        <!-- Filter -->
        <div class="wrap-filter is-flex is-align-items-center is-justify-content-space-between mb-6">
            <div class="wrap-filter-left">
                <p class="mb-2">Cari Kata Kunci</p>
                <b-input placeholder="Masukan Kata Kunci" type="search" icon="magnify" class="mb-2" v-model="keyword" @keyup.native.enter="handleSearch" />
                <p class="text-grey"><i>Cari ID/Nama Toko/No. Invoice</i></p>
            </div>
            <div class="wrap-filter-right is-flex is-align-items-center is-justify-content-space-between">
                <div class="mr-4 filter-vendor">
                    <p class="mb-2">Filter Berdasarkan</p>
                    <InputAutocomplete header="Pilih Client" :isVendor="true" :isNew="true" :haveImg="true" imgKey="logo" :onSelectItem="handleSelectVendor" :onClearSearch="handleSelectVendor" />
                </div>
                <div>
                    <p class="mb-2">Urutkan Berdasarkan</p>
                    <b-dropdown aria-role="list" v-model="sort" class="dropdown-list">
                        <template #trigger="{ active }">
                            <b-button :icon-right="active ? 'menu-up' : 'menu-down'" class="btn-dropdown">
                                <div class="is-flex is-align-items-center is-justify-content-start">
                                    <AscDscIcon class="mr-3" />
                                    <p class="averta-regular">{{ sort ? sort === 'asc' ? 'Terlama' : 'Terbaru' : 'Pilih Urutan' }}</p>
                                </div>
                            </b-button>
                        </template>
                        <b-dropdown-item aria-role="listitem" value="desc">Terbaru</b-dropdown-item>
                        <b-dropdown-item aria-role="listitem" value="asc">Terlama</b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
        </div>
        <!-- Filter -->

        <!-- List Project -->
        <div class="list-project" v-if="!isLoading && dataSearch">
            <div v-for="item, index in dataSearch" :key="`search-${index}`">
                <NewCardProject :data="item" @showModalProyekDarurat="showProyekDarurat($event)" @lihatCatatanProyek="showCatatanProyek($event)" @showModalInvoiceTagihan="showInvoiceTagihan($event)" @showModalTanggalPenagihan="showTanggalPenagihan($event)" @showModalBeforeAfter="showModalBeforeAfter($event)" @detailProject="detailProject($event)" @reloadList="reloadList" />
            </div>
        </div>
        <!-- List Project -->

        <DataNotFound v-if="!isLoading && dataSearch.length < 1" />

        <!-- Modal Proyek Darurat -->
        <ModalProyekDarurat :dataModalUrgent="projectUrgent" :surveyId="surveyIdDarurat" :showModal="modalProyekDarurat" :width="isMobile ? '95vw' : '45vw'" @closeModal="showProyekDarurat" :loadData="reloadList" />
        <!-- Modal Proyek Darurat -->

        <!-- Modal Lihat Catatan Proyek -->
        <ModalHistoryCatatanProyek :showModal="modalHistoryCatatanProyek" width="95vw" @closeModal="showCatatanProyek" :surveyId="surveyId" />
        <!-- Modal Lihat Catatan Proyek -->

        <!-- Modal Invoice Tagihan -->
        <ModalInvoiceTagihan :data="dataInvoice" :showModal="modalInvoiceTagihan" :width="isMobile ? '95vw' : '40vw'" @closeModal="showInvoiceTagihan" @reloadList="reloadList" />
        <!-- Modal Invoice Tagihan -->

        <!-- Modal Tanggal Penagihan -->
        <ModalTanggalPenagihan :data="dataPenagihan" :showModal="modalTanggalPenagihan" :width="isMobile ? '95vw' : '40vw'" @closeModal="showTanggalPenagihan" @reloadList="reloadList" />
        <!-- Modal Tanggal Penagihan -->

        <!-- Sidebar Before After -->
        <SidebarBeforeAfter
          :showSidebar="modalBeforeAfter"
          :data="dataModalBeforeAfter"
          type="list"
          @closeSidebar="closeSidebarBeforeAfter"
        />
        <!-- Sidebar Before After -->

        <div class="loading-wrapper">
          <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false">
              <SpinnerIcon />
          </b-loading>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ArrowLeftIcon, AscDscIcon, SpinnerIcon } from '@/components/icons'
import InputAutocomplete from '@/components/input/InputAutocomplete.vue'
import DataNotFound from '@/components/DataNotFound.vue'
import NewCardProject from '@/components/project/NewCardProject.vue'
import ModalProyekDarurat from '@/components/modal/ModalProyekDarurat.vue'
import ModalHistoryCatatanProyek from '@/components/modal/ModalHistoryCatatanProyek.vue'
import ModalInvoiceTagihan from '@/components/modal/ModalInvoiceTagihan.vue'
import ModalTanggalPenagihan from '@/components/modal/ModalTanggalPenagihan.vue'
import { alertErrorMessage } from '@/helpers'
import SidebarBeforeAfter from '@/components/SidebarBeforeAfter.vue'

export default {
  name: 'SearchGlobal',
  components: {
    ArrowLeftIcon,
    AscDscIcon,
    InputAutocomplete,
    NewCardProject,
    ModalProyekDarurat,
    ModalHistoryCatatanProyek,
    ModalInvoiceTagihan,
    ModalTanggalPenagihan,
    DataNotFound,
    SpinnerIcon,
    SidebarBeforeAfter
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user'
    }),
    isMobile () {
      if (window.innerWidth <= 768) {
        return true
      }
      return false
    }
  },
  data () {
    return {
      isLoading: false,
      sort: 'desc',
      vendor: null,
      keyword: '',
      dataSearch: [],
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      limitPage: 10,
      surveyId: null,
      modalProyekDarurat: false,
      modalHistoryCatatanProyek: false,
      modalInvoiceTagihan: false,
      modalTanggalPenagihan: false,
      projectUrgent: null,
      surveyIdDarurat: null,
      dataInvoice: null,
      dataPenagihan: null,
      modalBeforeAfter: false,
      dataModalBeforeAfter: null
    }
  },
  methods: {
    showProyekDarurat (data) {
      if (data) {
        this.projectUrgent = data.projectUrgent
        this.surveyIdDarurat = data.surveyId
      }
      this.modalProyekDarurat = !this.modalProyekDarurat
    },
    showCatatanProyek (event) {
      this.surveyId = event
      this.modalHistoryCatatanProyek = !this.modalHistoryCatatanProyek
    },
    showInvoiceTagihan (data) {
      if (data) {
        this.dataInvoice = data
      }
      this.modalInvoiceTagihan = !this.modalInvoiceTagihan
    },
    showTanggalPenagihan (data) {
      if (data) {
        this.dataPenagihan = data
      }
      this.modalTanggalPenagihan = !this.modalTanggalPenagihan
    },
    handleSelectVendor (props = {}) {
      const { dataVendor } = props
      if (dataVendor) {
        this.vendor = dataVendor.name
      } else {
        this.vendor = null
      }
      this.handleQuery()

      // check if already have keyword
      if (this.keyword) {
        this.getSearchResult()
      }
    },
    handleBack () {
      const currentRole = this.user.roles[0].name

      let routerPath = ''
      switch (currentRole) {
        case 'Site Manager':
          routerPath = '/sm/project'
          break
        case 'Account Manager':
        case 'Business Support':
          routerPath = '/am/project'
          break
        case 'Purchasing':
          routerPath = '/purchasing/project'
          break
        default:
          routerPath = '/project'
          break
      }
      this.$router.push({ path: routerPath })
    },
    handleSearch () {
      if (!this.keyword) {
        return alertErrorMessage('Masukan Kata Kunci')
      }

      const currentRole = this.user.roles[0].name

      let routerPath = ''
      switch (currentRole) {
        case 'Site Manager':
          routerPath = '/sm/search'
          break
        case 'Account Manager':
        case 'Business Support':
          routerPath = '/am/search'
          break
        case 'Purchasing':
          routerPath = '/purchasing/search'
          break
        default:
          routerPath = '/search'
          break
      }

      this.$router.push({ path: routerPath, query: { keyword: this.keyword, sort: this.sort, vendor: this.vendor } })
      this.getSearchResult()
    },
    handleQuery () {
      this.$router.replace({ query: { keyword: this.keyword, sort: this.sort, vendor: this.vendor } })
    },
    getSearchResult (props = {}) {
      const { pages = 1 } = props
      this.isLoading = true
      const payload = {
        url: this.url,
        sort: this.sort,
        limit: this.limitPage,
        page: pages || 1,
        keyword: this.keyword,
        vendor: this.vendor
      }

      this.$store
        .dispatch('projectV2/searchListGlobal', payload)
        .then(response => {
          // it should contain pagination (pagination not ready)
          this.dataSearch = response.data.data
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    showModalBeforeAfter (data) {
      this.modalBeforeAfter = true
      this.dataModalBeforeAfter = data
    },
    closeSidebarBeforeAfter () {
      this.modalBeforeAfter = false
      this.dataModalBeforeAfter = null
    },
    reloadList () {
      this.modalProyekDarurat = false
      this.modalInvoiceTagihan = false
      this.modalTanggalPenagihan = false
      setTimeout(() => {
        this.handleSearch()
      }, 200)
    },
    detailProject (data) {
      const currentRole = this.user.roles[0].name

      if (data.project_id) {
        let routePath = ''

        switch (currentRole) {
          case 'Konsultan Survey':
            routePath = `/project/${data.project_id}/detail`
            break
          case 'Site Manager':
            routePath = `/sm/project/${data.project_id}/detail`
            break
          case 'Account Manager':
            routePath = `/am/project/${data.project_id}/detail`
            break
          case 'Purchasing':
            routePath = `/purchasing/project/${data.project_id}/detail`
            break
          default:
            break
        }

        this.$router.push({ path: routePath, query: { fromSearch: true } })
      } else if (data.offering_id) {
        let routePath = ''

        switch (currentRole) {
          case 'Konsultan Survey':
            routePath = `/project/survey/${data.survey_id}/detail`
            break
          case 'Site Manager':
            routePath = `/sm/project/survey/${data.survey_id}/detail`
            break
          case 'Account Manager':
            routePath = `/am/project/survey/${data.survey_id}/detail`
            break
          case 'Purchasing':
            routePath = `/purchasing/project/offering/${data.offering_id}/detail`
            break
          default:
            break
        }
        this.$router.push({ path: routePath, query: { fromSearch: true } })
      } else if (data.survey_id) {
        let routePath = ''

        switch (currentRole) {
          case 'Konsultan Survey':
            routePath = `/project/survey/${data.survey_id}/detail`
            break
          case 'Site Manager':
            routePath = `/sm/project/survey/${data.survey_id}/detail`
            break
          case 'Account Manager':
            routePath = `/am/project/survey/${data.survey_id}/detail`
            break
          default:
            break
        }
        this.$router.push({ path: routePath, query: { fromSearch: true } })
      }
    }
  },
  created () {
    const queryParams = this.$route.query
    this.keyword = queryParams.keyword
    this.sort = queryParams.sort || 'desc'
    this.vendor = queryParams.vendor || ''

    this.getSearchResult()
  },
  watch: {
    sort (newVal) {
      this.handleQuery()

      // check if already have keyword
      if (this.keyword && newVal) {
        this.getSearchResult()
      }
    }
  }
}
</script>

<style scoped>
.wrap-search {
  width: calc(100% - 75px);
  padding: 35px;
}

.modal-wrapper {
  overflow-x: hidden;
}

.header-modal {
  background: #911a1e;
  padding: 24px;
  color: white;
  border-radius: 12px 12px 0px 0px;
}

.body-modal {
  background: white;
  border-radius: 0px 0px 12px 12px;
  height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.before-after {
  width: 380px;
  height: 380px;
  border-radius: 24px;
}

.loading-wrapper {
  height: 50vh;
  width: 100%;
  position: relative;
}

@media only screen and (max-width: 600px) {
    .wrap-search {
        padding: 14px !important;
        overflow: auto;
    }

    .wrap-filter {
        display: block !important;
    }

    .wrap-filter-left {
        margin-bottom: 14px;
    }

    .wrap-filter-right {
        display: block !important;
    }

    .filter-vendor {
        margin-bottom: 14px;
        width: 100%;
    }

    .dropdown-list {
        width: 100% !important;
    }

    .btn-dropdown-search {
        width: 93vw !important;
    }
}

@media screen and (min-width: 1408px) {
  .container {
    max-width: 1280px !important;
  }
}
</style>

<style>
@media only screen and (max-width: 600px) {
    .btn-dropdown-search span:first-child {
        width: 100% !important;
    }

}
</style>
