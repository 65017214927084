<template>
  <div class="wrap-survey-complaint py-5 px-6">
    <div v-if="!dataHeader">
      <DetailSkeleton />
    </div>
    <div v-else class="is-relative">
      <!-- Back Button and Submit Button -->
      <div
        class="is-flex is-justify-content-space-between is-align-items-center mb-5"
        :class="{ 'wrap-header': isSticky }"
        :style="`top: ${heightNavbar}px`"
      >
        <div class="is-flex is-align-items-center is-clickable" @click="goBack">
          <ArrowLeftIcon class="mr-3" />
          <p class="label-18">Kembali ke Detail</p>
        </div>
        <div>
          <ButtonApp
            :is-bold="true"
            height="3em"
            :isDisabled="buttonDisabled"
            @click="createSurveyComplaint"
          >
            Simpan & Buat Survey
          </ButtonApp>
        </div>
      </div>
      <!-- Back Button and Submit Button -->

      <!-- Body Content -->
      <div>
        <p class="averta-black label-20 mb-5">Buat Survey Komplain</p>
        <!-- ID Terkait dan Client -->
        <div class="columns wrap-id-client mb-5">
          <div class="column id-terkait py-0">
            <p class="label-14 text-grey">ID Terkait</p>
            <p class="text-merah averta-black label-16">
              ID{{ dataHeader.survey_id }}
              <ArrowRightIcon color="#E10009" height="12" width="12" />
            </p>
          </div>
          <div class="column py-0 ml-2">
            <p class="label-14 text-grey">Client</p>
            <p class="averta-black label-16">
              {{ dataHeader.vendor.name }}
            </p>
          </div>
        </div>
        <!-- ID Terkait dan Client -->

        <!-- Alamat Proyek -->
        <div class="alamat-proyek mb-5">
          <div class="alamat-header">
            <p class="averta-bold label-18 title-card">
              1. Alamat Proyek
            </p>
          </div>
          <div class="address">
            <div class="wrap-alamat is-flex is-align-items-center">
              <img src="@/assets/img/icon-map-blue.png" />
              <div class="ml-3">
                <p class="averta-bold label-16">
                  {{ dataHeader.store.name }}
                </p>
                <p>
                  {{ dataHeader.store.address }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Alamat Proyek -->

        <!-- PIC Proyek -->
        <div class="pic-proyek mb-5">
          <div class="pic-header">
            <p class="averta-bold label-18 title-card">
              2. PIC (Person In Charge) Proyek
            </p>
          </div>
          <div class="pic-content">
            <div class="pic-notes mb-4">
              <p>
                PIlih PIC outlet untuk berkoordinasi dengan konsultan / tukang
                dari Kanggo
              </p>
            </div>
            <div class="wrap-pic is-flex is-align-items-center mb-4">
              <img src="@/assets/img/icon-user-blue.png" />
              <div class="ml-3">
                <p class="averta-bold label-16">{{dataPIC.name}}</p>
                <p>{{dataPIC.phone}}</p>
              </div>
            </div>
            <div class="is-flex is-justify-content-center">
              <ButtonApp
                :isBold="true"
                :isSecondary="true"
                height="3em"
                width="326px"
                @click="handleUpdatePIC"
                >Ubah PIC</ButtonApp
              >
            </div>
          </div>
        </div>
        <!-- PIC Proyek -->

        <!-- Detail Proyek -->
        <div class="detail-proyek mb-5">
          <div class="detail-header">
            <p class="averta-bold label-18 title-card">
              3. Detail Proyek
            </p>
          </div>
          <div class="detail-content">
            <b-input
              type="textarea"
              row="4"
              placeholder="Deskripsikan dengan Detail perbaikan proyek yang perlu dikerjakan di sini"
              v-model="description"
            />
          </div>
        </div>
        <!-- Detail Proyek -->

        <!-- Tanggal Survey -->
        <div class="tanggal-survey mb-5">
          <div class="survey-header">
            <p class="averta-bold label-18 title-card">
              4. Tanggal Survey
            </p>
          </div>
          <div class="survey-content">
            <div class="is-flex is-align-items-center mb-4 wrap-date">
              <CalendarIcon :color="selectedWorkDate ? '#3185FC' : '#868686'" class="mr-3" />
              <p v-if="!selectedWorkDate" class="label-14 text-merah">
                <i>Tanggal Survey belum ditentukan</i>
              </p>
              <div v-else>
                <p class="averta-bold">Tanggal Pengerjaan</p>
                <p>{{formatSelectedWorkDate}}</p>
              </div>
            </div>
            <div class="is-flex is-justify-content-center">
              <ButtonApp
                :isBold="true"
                :isSecondary="true"
                height="3em"
                width="326px"
                @click="handleSelectDate"
                >Pilih Tanggal</ButtonApp
              >
            </div>
          </div>
        </div>
        <!-- Tanggal Survey -->

        <!-- Foto Masalah -->
        <div class="foto-masalah mb-5">
          <div class="foto-header">
            <p class="label-18">
              <span class="averta-bold title-card">5. Foto Masalah </span>
              <i class="averta-regular text-grey">(Opsional)</i>
            </p>
          </div>
          <div class="foto-content">
            <p class="averta-reguler label-14">Foto (Maks. {{maxSize}}MB/Foto)</p>
            <div class="image-upload is-flex is-align-items-center">
              <div
                v-if="dataUpload.length"
                class="is-flex is-align-items-center my-3"
              >
                <div
                  v-for="(image, index) in dataUpload"
                  :key="index"
                  class="image-preview is-flex is-align-items-center"
                >
                  <div
                    class="wrap-preview is-flex is-align-items-center is-justify-content-center"
                  >
                    <img :src="image.url" alt="Image preview" class="preview" />
                  </div>
                  <div class="overlay">
                    <div class="delete-image" @click="deleteImage(index)">
                      Hapus
                    </div>
                  </div>
                  <div class="wrap-progress m-2" v-if="image.progress < 100">
                    <ProgressBar :percent="image.progress" />
                  </div>
                </div>
              </div>
              <div v-if="dataUpload.length < 5">
                <input
                  type="file"
                  @change="onFileChange"
                  id="image-upload"
                  hidden
                  multiple
                  ref="imageUpload"
                />
                <label for="image-upload" class="image-label">
                  <div
                    class="upload-img-wrapper is-flex is-justify-content-center is-align-items-center is-clickable my-3"
                  >
                    <div class="has-text-centered">
                      <AddPhotoIcon />
                      <p class="averta-bold label-14 text-merah">Unggah Foto</p>
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <p class="averta-regular text-merah label-14">
              Max. foto diupload sebanyak 5 foto
            </p>
          </div>
        </div>
        <!-- Foto Masalah -->
      </div>
      <!-- Body Content -->
    </div>
    <!-- Body Content -->

    <!-- Modal Update PIC -->
    <ModalUpdatePIC
      :showModal="modalUpdatePIC"
      :dataHeader="dataHeader"
      :dataPIC="dataPIC"
      @closeModal="closeUpdatePIC"
      @createPIC="handleCreatePIC"
      @updatePIC="handleSwitchPIC($event)"
    />

    <!-- Modal Create PIC -->
    <ModalCreatePIC
      :showModal="modalCreatePIC"
      :dataHeader="dataHeader"
      @closeModal="closeCreatePIC"
    />

    <!-- Modal Work Date -->
    <ModalWorkDate
      :showModal="modalDateSurvey"
      :dataHeader="dataHeader"
      @closeModal="closeDateSurvey"
      @onSubmitCalender="handleConfirmDate($event)"
    />

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { alertErrorMessage } from '@/helpers'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CalendarIcon,
  AddPhotoIcon,
  SpinnerIcon
} from '@/components/icons'
import ButtonApp from '@/components/button/ButtonApp'
import ModalUpdatePIC from './components/ModalUpdatePIC.vue'
import ModalCreatePIC from './components/ModalCreatePIC.vue'
import ModalWorkDate from './components/ModalWorkDate.vue'
import DetailSkeleton from '@/components/skeleton/DetailSkeleton.vue'
import ProgressBar from './components/ProgressBar.vue'
import confirmationImage from '@/assets/img/delete-area-component.png'
import successCreate from '@/assets/img/illustrasi_submit_success.gif'
import stepSuccess from '@/assets/img/illustrasi_step_project_success.png'

export default {
  name: 'SurveyComplaint',
  components: {
    ArrowLeftIcon,
    ArrowRightIcon,
    CalendarIcon,
    AddPhotoIcon,
    SpinnerIcon,
    ButtonApp,
    DetailSkeleton,
    ModalUpdatePIC,
    ModalCreatePIC,
    ModalWorkDate,
    // eslint-disable-next-line vue/no-unused-components
    ProgressBar
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      heightNavbar: 'dashboard/getNavbarHeight'
    }),
    buttonDisabled () {
      const isOnlyWhitespace = this.description.trim() === ''
      if (!this.description || isOnlyWhitespace || !this.dataPIC.id || !this.selectedWorkDate) return true
      return false
    },
    formatSelectedWorkDate () {
      return moment(this.selectedWorkDate).format('DD MMMM YYYY')
    }
  },
  data () {
    return {
      isLoading: false,
      dataUpload: [],
      dataHeader: null,
      dataPIC: {
        id: null,
        name: null,
        phone: null
      },
      description: '',
      modalUpdatePIC: false,
      modalCreatePIC: false,
      modalDateSurvey: false,
      selectedWorkDate: null,
      maxSize: 5, // Size in MB
      isSticky: false
    }
  },
  methods: {
    goBack () {
      this.$router.push({ path: `/am/project/${this.dataHeader.id}/detail` })
    },
    getDataProyekHeader () {
      this.isLoading = true

      const payload = {
        url: this.url,
        proyek_id: this.$route.params.projectId
      }

      this.$store
        .dispatch('projectV2/getProjectHeader', payload)
        .then(response => {
          this.dataHeader = response.data.data

          const { id, name, phone } = this.dataHeader.offering.pic
          this.dataPIC = {
            id,
            name,
            phone
          }
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    validateFile (file) {
      const allowedTypes = [
        'image/jpeg',
        'image/png',
        'image/jpg',
        'image/JPEG',
        'image/PNG',
        'image/JPG'
      ]

      if (!allowedTypes.includes(file.type)) {
        return false
      }

      return true
    },
    onFileChange (event) {
      const files = event.target.files

      if (
        files.length > 5 ||
        this.dataUpload.length > 5 ||
        this.dataUpload.length + files.length > 5
      ) {
        this.$buefy.toast.open({
          message: 'Maaf, maksimal hanya 5 gambar',
          type: 'is-danger'
        })
        return
      }

      for (let i = 0; i < files.length; i++) {
        if (!this.validateFile(files[i])) {
          this.$buefy.toast.open({
            message:
              'Ada file yang tidak sesuai dengan format, harap masukan file berformat gambar',
            type: 'is-danger'
          })
          break
        }

        const size = files[i].size / (1024 * 1024)

        if (size > this.maxSize) {
          this.$buefy.toast.open({
            message: 'Maaf, maksimal hanya 5 MB',
            type: 'is-danger'
          })
          this.$refs.imageUpload.value = null
          break
        }

        const reader = new FileReader()
        reader.onload = e => {
          const image = {
            url: e.target.result,
            file: files[i],
            name: files[i].name,
            progress: 0
          }
          this.dataUpload.push(image)

          // Upload Progress Bar
          let progress = 0
          const interval = setInterval(() => {
            progress += 10
            if (progress <= 100) {
              image.progress = progress
            } else {
              clearInterval(interval)
            }
          }, 150)
        }
        reader.readAsDataURL(files[i])
      }
    },
    deleteImage (index) {
      this.dataUpload.splice(index, 1)
    },
    createSurveyComplaint () {
      this.$swal({
        imageUrl: confirmationImage,
        imageAlt: 'save data',
        width: '550px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Lanjut Proses Proyek Ini?</p>',
        html:
          '<p class="averta-regular">Pastikan kamu sudah cek kembali proyek ini sebelum di proses</p>',
        showCancelButton: true,
        confirmButtonText: 'Proses',
        cancelButtonText: 'Cek Kembali',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then(result => {
        if (result.isConfirmed) {
          this.isLoading = true

          const payload = {
            url: this.url,
            survey_id: this.dataHeader.survey_id,
            description: this.description,
            pic_id: this.dataPIC.id,
            date_sesi: moment(this.selectedWorkDate).format('YYYY-MM-DD'),
            problem_images: this.dataUpload
          }

          this.$store
            .dispatch('project/createSurveyComplaint', payload)
            .then((response) => {
              this.isLoading = false
              const res = response.data.data
              this.successConfirmation(res.survey_complaint_id)
            })
            .catch(error => {
              this.isLoading = false
              alertErrorMessage(error)
            })
        }
      })
    },
    successConfirmation (surveyID) {
      this.$swal({
        imageUrl: successCreate,
        imageWidth: 150,
        imageAlt: 'success create',
        width: '550px',
        padding: '5px 10px 10px 10px',
        title: '<p class="averta-bold label-22" style="margin-top: -30px">Proyek Berhasil Dibuat!</p>',
        html: `
          <div>
            <p class="averta-regular mb-5">Konsultan kanggo akan datang ke lokasi proyek untuk melakukan survey pengecekkan masalah/kendala</p>
            <img src="${stepSuccess}" alt="step success">
          </div>
        `,
        confirmButtonText: 'Lanjutkan',
        customClass: {
          confirmButton: 'btn-swal-confirm-long'
        }
      }).then(result => {
        if (result.isConfirmed) {
          this.$router.push(`/am/project/survey/${surveyID}/detail`)
        }
      })
    },
    handleUpdatePIC () {
      this.modalUpdatePIC = !this.modalUpdatePIC
    },
    closeUpdatePIC () {
      this.modalUpdatePIC = false
    },
    handleCreatePIC () {
      this.closeUpdatePIC()
      setTimeout(() => {
        this.modalCreatePIC = !this.modalCreatePIC
      }, 100)
    },
    closeCreatePIC () {
      this.modalCreatePIC = false
      setTimeout(() => {
        this.handleUpdatePIC()
      }, 100)
    },
    handleSwitchPIC (value) {
      this.dataPIC = value
      this.closeUpdatePIC()
    },
    handleSelectDate () {
      this.modalDateSurvey = !this.modalDateSurvey
    },
    closeDateSurvey () {
      this.modalDateSurvey = false
    },
    handleConfirmDate (date) {
      this.selectedWorkDate = date
    },
    handleScroll () {
      // Periksa apakah scroll melebihi posisi awal header
      if (window.scrollY > 0) {
        this.isSticky = true
      } else {
        this.isSticky = false
      }
    }
  },
  created () {
    this.getDataProyekHeader()
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style scoped>
.wrap-header {
  position: sticky;
  background: white;
  padding: 12px 0px;
  z-index: 10;
  margin: 0px -40px;
  padding: 12px 40px;
  border-bottom: 1px solid #e1e1e1;
}

.wrap-survey-complaint {
  min-height: 100vh;
  width: 100%;
}

.wrap-id-client {
  background: #fafafa;
  padding: 12px;
  border-radius: 20px;
}

.id-terkait {
  border-right: 1px solid #e1e1e1;
}

.alamat-header,
.pic-header,
.detail-header,
.survey-header,
.foto-header {
  background: #006aff33;
  padding: 30px;
  border-radius: 20px 20px 0px 0px;
}

.title-card {
  color: #252525;
}

.address,
.pic-content,
.detail-content,
.survey-content,
.foto-content {
  background: white;
  border-radius: 0px 0px 20px 20px;
  padding: 20px 30px;
  border: 1px solid #e1e1e1;
}

.wrap-alamat,
.wrap-pic {
  background: #fafafa;
  padding: 20px;
  border-radius: 20px;
}

.pic-notes {
  background: #ffe7e8;
  padding: 15px 20px;
  border-radius: 20px;
}

.wrap-date {
  border: 1px solid #e1e1e1;
  padding: 20px;
  border-radius: 20px;
}

.upload-img-wrapper {
  width: max-content;
  border: 2px dashed #e10009;
  width: 230px;
  height: 230px;
  border-radius: 20px;
}

.image-label {
  display: flex;
  width: max-content;
}

.image-preview {
  width: 230px;
  height: 230px;
  border-radius: 20px;
  border: 1px solid #e1e1e1;
  margin-right: 30px;
  position: relative;
}

.image-preview:hover .overlay {
  bottom: 0;
  height: 100%;
  pointer-events: none;
}

.overlay {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
  border-radius: 8px;
  pointer-events: none;
}

.delete-image {
  color: #ffffff;
  font-size: 14px;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  padding: 4px 14px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid var(--light-white, #fff);
  cursor: pointer;
  pointer-events: auto;
}

.wrap-preview {
  height: 229px;
  width: 230px;
  border-radius: 20px;
  position: relative;
}

.wrap-preview .preview {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: contain;
}

.wrap-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 93%;
}
</style>
