<template>
  <Skeleton v-if="isLoading" class="p-6" />
  <div v-else>
    <div class="notification is-success is-light has-text-dark">
      <p class="averta-bold is-size-5">Preview</p>
      <p>Periksa kembali seluruh informasi pada penawaran sebelum dikirim.</p>
    </div>
    <div>
      <div>
        <TimelinePhase2
          :dataProps="dataPreview.timeline"
          :durationProps="dataPreview.duration"
          :slotSkillsProps="dataPreview.slot_skills"
        />
        <!-- <NewTimeline :data="dataPreview.timeline" :duration="dataPreview.duration" /> -->
      </div>
      <div class="mb-5">
        <p class="averta-bold is-size-5 mb-4">Total Harga Penawaran</p>
        <div class="card p-4">
          <div class="is-flex is-justify-content-space-between mb-3 px-3">
            <p class="averta-bold label-14">DPP</p>
            <div class="wrap-price is-flex is-align-items-center is-justify-content-space-between">
              <p class="averta-bold label-14">Rp</p>
              <p class="averta-bold label-14">{{ formatThousand(dataPreview.preview_price.subtotal) }}</p>
            </div>
          </div>
          <div class="is-flex is-justify-content-space-between mb-3 px-3">
            <p class="averta-bold label-14">PPN {{ dataPreview.preview_price.ppn_percent }}</p>
            <div class="wrap-price is-flex is-align-items-center is-justify-content-space-between">
              <p class="averta-bold label-14">Rp</p>
              <p class="averta-bold label-14">{{ formatThousand(dataPreview.preview_price.ppn) }}</p>
            </div>
          </div>
          <div class="is-flex is-justify-content-space-between mb-3 px-3">
            <p class="averta-bold label-14">PPh Final 4 Ayat 2 ({{ dataPreview.preview_price.pph_percent }})</p>
            <div class="wrap-price is-flex is-align-items-center is-justify-content-space-between">
              <p class="averta-bold label-14">Rp</p>
              <p class="averta-bold label-14">({{ formatThousand(dataPreview.preview_price.pph) }})</p>
            </div>
          </div>
          <!-- <div class="is-flex is-justify-content-space-between mb-3 px-3">
            <p>Survey Charge</p>
            <p class="averta-bold">{{ formatThousand(dataPreview.preview_price.survey_charge) }}</p>
          </div>
          <div class="is-flex is-justify-content-space-between mb-3 px-3">
            <p>Transport Charge</p>
            <p class="averta-bold">{{ formatThousand(dataPreview.preview_price.transport_charge) }}</p>
          </div>
          <div class="is-flex is-justify-content-space-between mb-3 px-3">
            <p>Biaya Parkir</p>
            <p class="averta-bold">{{ formatThousand(dataPreview.preview_price.fee_parking) }}</p>
          </div> -->
          <div class="divider"></div>
          <!-- <div class="is-flex is-justify-content-space-between mb-3 px-3">
            <p class="averta-black">Total</p>
            <p class="averta-black">{{ formatThousand(dataPreview.preview_price.total) }}</p>
          </div> -->
          <div class="is-flex is-justify-content-space-between mb-3 is-size-5 wrapper-grand p-3">
            <p class="averta-black">Grand Total</p>
            <div class="text-rp-grand averta-black is-flex is-align-items-center is-justify-content-space-between">
              <p>Rp</p>
              <p class="averta-black text-red">{{ formatThousand(dataPreview.preview_price.total_after_rounding) }}</p>
            </div>
          </div>
          <p class="has-text-right mt-2 label-13">
            (<span class="averta-bold">Terbilang: </span>
            <span class="is-italic">{{ spellRupiah(dataPreview.preview_price.total_after_rounding) }}</span
            >)
          </p>
        </div>
      </div>
      <div class="divider my-6"></div>
      <div>
        <NewDetailPenawaran :hideInformasiPenawaran="true" :hideTimeline="true" :offeringId="dataPreview.offering_id" :isBs="true" :isFromDetail="false" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Skeleton from '@/components/skeleton/Skeleton'
import { formatCurrency, Terbilang } from '@/helpers'
import NewDetailPenawaran from '@/components/project/NewDetailPenawaran'
import NewTimeline from '@/components/project/NewTimeline'
import TimelinePhase2 from '@/components/project/TimelinePhase2.vue'

export default {
  name: 'PreviewCompleteOffering',
  components: {
    Skeleton,
    NewDetailPenawaran,
    // eslint-disable-next-line vue/no-unused-components
    NewTimeline,
    TimelinePhase2
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    currentPath () {
      const path = this.$route.path.split('/')
      const lastElement = path[path.length - 1]

      return lastElement
    }
  },
  data () {
    return {
      isLoading: false,
      dataPreview: null,
      area: null,
      btnTabDetailHarga: true,
      btnTabDetailTukangMaterial: false
    }
  },
  methods: {
    formatThousand (x) {
      return formatCurrency(x, true)
    },
    changeIsActive (areaName) {
      this.area = areaName
    },
    changeIsActiveTab (type) {
      if (type === 'detail-harga') {
        this.btnTabDetailHarga = true
        this.btnTabDetailTukangMaterial = false
      } else {
        this.btnTabDetailHarga = false
        this.btnTabDetailTukangMaterial = true
      }
    },
    getPreviewLengkapiPenawaran () {
      this.isLoading = true
      if (this.currentPath === 'complete-offering') {
        const payload = {
          url: this.url,
          survey_id: this.$route.params.surveyId
        }

        this.$store
          .dispatch('project/getPreviewLengkapiPenawaran', payload)
          .then(response => {
            this.dataPreview = response.data.data
            this.$store.commit('project/setDataDetailPenawaran', response.data.data)
            this.isLoading = false
          })
          .catch(error => {
            this.isLoading = false
            console.log(error)
            this.$buefy.toast.open({
              message: '<p class="averta-regular">Gagal memuat data detail harga, silahkan coba lagi</p>',
              type: 'is-danger'
            })
          })
      } else if (this.currentPath === 'update-price') {
        const payload = {
          url: this.url,
          offering_id: this.$route.params.offeringId
        }

        this.$store
          .dispatch('projectV2/getPreviewReject', payload)
          .then(response => {
            this.dataPreview = response.data.data
            this.$store.commit('project/setDataDetailPenawaran', response.data.data)
            this.isLoading = false
          })
          .catch(error => {
            this.isLoading = false
            console.log(error)
            this.$buefy.toast.open({
              message: '<p class="averta-regular">Gagal memuat data detail harga, silahkan coba lagi</p>',
              type: 'is-danger'
            })
          })
      }
    },
    spellRupiah (value) {
      return Terbilang(value)
    }
  },
  created () {
    this.getPreviewLengkapiPenawaran()
    // set true for download offering ( OfferingProject ), from PreviewPenawaran
    this.$store.commit('project/setPreviewDownloadOffering', true)
  }
}
</script>

<style scoped>
.btn-tabs {
  height: auto;
  border-radius: 100px;
  color: #9a9a9a;
  border: 1px solid #e1e1e1;
}

.btn-tabs.is-active {
  background: rgba(49, 133, 252, 0.1);
  color: #3185fc;
  border: 1px solid #3185fc;
  font-family: 'Averta-Bold';
}

.divider {
  border-top: 2px dashed #E1E1E1;
  margin: 24px 0px;
}

.wrapper-grand {
  background: linear-gradient(90deg, #FFFFF7 0%, #FFF5EA 100%);
  border-radius: 8px;
}

.text-red {
  color: #D9272D;
}

.wrapper-detail {
  background: #FAFAFA;
  border-radius: 20px;
}

.skill-image {
  width: 50px;
  object-fit: cover;
}

.material-image {
  width: 50px;
  object-fit: cover;
}

.bg-grey {
  background: #FAFAFA !important;
}

.wrapper-item-pekerjaan {
  border-radius: 12px;
}

.wrapper-volume {
  background: #ffffff;
  padding: 12px 24px;
  border: 1px solid #E1E1E1;
  border-radius: 10px;
}

.section-volume {
  padding-right: 28px;
}

.section-satuan {
  border-left: 2px solid #E1E1E1;
  padding-left: 14px;
  padding-right: 120px;
}

.wrapper-tukang,
.wrapper-material {
  border-radius: 10px;
}

.wrapper-item-tukang:not(:last-child),
.wrapper-item-material:not(:last-child),
.wrapper-item-variant:not(:last-child) {
  padding-bottom: 24px;
  border-bottom: 1px solid #E1E1E1;
}

.text-hidden {
  background: #3185FC;
  font-family: 'Averta';
  font-style: italic;
  color: white;
  padding: 4px 14px;
  border-radius: 14px;
}

.text-custom {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-style: italic;
}

.skill-img {
  width: 34px;
  margin-right: 10px;
}

.note-material {
  border: 1px solid #E1E1E1;
  border-radius: 12px;
  padding: 10px;
}

.btn-client {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.25%);
  border-radius: 100px;
  font-family: 'Averta-Bold';
  color: white;
  padding: 12px 24px;
  border: none;
}

.wrap-price {
  width: 180px;
}

.text-rp-grand {
  width: 180px;
}

</style>
