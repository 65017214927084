<template>
  <div class="grand-total-project" :class="{'sticky': isSticky, 'is-pinned': isPinned}">
    <div
      class="bg-grand-total"
      :class="{'bg-waiting-payment': isWaitingPayment, 'bg-success-payment': isSuccessPayment, 'border-radius-20': !isSticky}"
    >
      <div
        class="grand-total-wrapper is-relative flex-center-vertical is-justify-content-space-between"
        :class="{'waiting-payment': isWaitingPayment, 'success-payment': isSuccessPayment, 'sticky': isSticky}"
      >
        <div class="flex-center-vertical">
          <div class="icon-coin-img mr-3">
            <img src="@/assets/img/icon-coin.gif" alt="kanggo.id" />
          </div>
          <div>
            <p class="averta-bold">Grand Total Proyek</p>
            <p class="averta-black color-red label-20">{{ formatIdr(dataHeader.price.grand_total) }}</p>
          </div>
        </div>
        <ButtonApp v-if="isWaitingPayment" @click="handleConfirmPayment">
          <p class="averta-bold py-2">Konfirmasi Pembayaran</p>
        </ButtonApp>
        <div class="is-flex bg-white border-radius-20 p-4 width-payment-success" v-if="isSuccessPayment">
          <div class="flex-1 border-right-2 mr-3 pr-3">
            <p class="color-gray label-12">Dikonfirmasi Oleh</p>
            <p v-if="dataHeader.price.confirm_by" class="averta-bold white-space-nowrap">{{dataHeader.price.confirm_by}} ({{dataHeader.price.paid_by_role}})</p>
            <p v-else class="averta-bold">-</p>
          </div>
          <div class="flex-1">
            <p class="color-gray label-12 white-space-nowrap">Tanggal Pembayaran Diterima</p>
            <p class="averta-bold white-space-nowrap">{{formatDate(dataHeader.price.payment_at)}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatCurrency, formatDateHelper } from '@/helpers'
import ButtonApp from '../button/ButtonApp.vue'

export default {
  name: 'CardGrandTotalProject',
  components: {
    ButtonApp
  },
  props: {
    dataHeaderProps: {
      type: Object
    },
    isSticky: {
      type: Boolean,
      default: false
    },
    isPinned: {
      type: Boolean,
      default: false
    },
    navbarPosition: {
      type: String
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showModal: false,
      dataHeader: null
    }
  },
  computed: {
    isWaitingPayment () {
      // eslint-disable-next-line camelcase
      const statusName = this.dataHeader?.status.status_name
      return statusName === 'payment-waiting' || statusName === 'Menunggu Pembayaran' || statusName === 'payment-pending'
    },
    isSuccessPayment () {
      // eslint-disable-next-line camelcase
      const statusName = this.dataHeader?.status.status_name
      return statusName === 'payment-success' || statusName === 'Pembayaran Berhasil'
    }
  },
  methods: {
    formatIdr (val) {
      const removeSpaces = formatCurrency(val)
      if (removeSpaces) {
        return removeSpaces.replace(/\s/g, '')
      }
      return '-'
    },
    formatDate (date) {
      return formatDateHelper({ date, format: 'dddd, DD MMMM YYYY' })
    },
    handleConfirmPayment () {
      this.$emit('openModal')
    }
  },
  created () {
    this.dataHeader = this.dataHeaderProps
  },
  mounted () {
    const stickyAlert = document.querySelector('.grand-total-project')
    if (stickyAlert) {
      stickyAlert.style.setProperty('top', `calc(${this.navbarPosition || '0px'} + 9vh - 0.5px)`) // 9vh = height sticky-header
    }
  },
  watch: {
    dataHeaderProps (val) {
      this.dataHeader = val
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.grand-total-wrapper {
  padding: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.grand-total-wrapper.sticky {
  padding: 5px 20px;
}

.waiting-payment {
  background-image: url('../../assets/img/wave-2.png');
}

.bg-waiting-payment {
  background: linear-gradient(90deg, #ffdedf 0%, #fcdeff 100%);
  border: 0.65px solid $color-grey-2;
}

.success-payment {
  background-image: url('../../assets/img/wave-3.png');
}

.bg-success-payment {
  background: linear-gradient(90deg, rgba(16, 189, 65, 0.2) 0%, rgba(85, 248, 131, 0.2) 100%);
  border: 2px solid $color-green-2;
}

.bg-grand-total {
  overflow: hidden;
  box-shadow: 0px 6px 24px 0px #2525251a;
}

.icon-coin-img {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  object-fit: contain;
  padding: 3px;
  background: white;
}

.icon-coin-img img {
  height: 100%;
}

.width-payment-success {
  // max-width: 450px;
}

.grand-total-project.sticky.is-pinned {
  width: calc(100% - 70px); /* 70px = sidebar width */
  position: fixed;
}

</style>
