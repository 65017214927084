<template>
  <SidebarClientTemplate
    :show="showSidebar"
    :disabledSubmit="disabledSubmit"
    @closeSidebar="showModalConfirm"
    @submit="handleSubmit"
  >
    <template v-slot:bodySidebar>
      <b-loading
        :is-full-page="true"
        v-model="isLoadingSubmit"
        :can-cancel="false"
      >
        <SpinnerIcon />
      </b-loading>
      <div>
        <p class="averta-black label-20 mb-4">Silakan pilih sebelum isi form</p>
        <!-- Tab Tambah Cabang dan Tambah PIC Cabang -->
        <div
          class="is-flex is-justify-content-space-between is-align-items-center mb-4"
        >
          <button
            class="button-tab averta-bold label-16"
            :class="{ active: currentTab === 'branch' }"
            @click="changeTab"
          >
            Tambah Cabang
          </button>
          <button
            class="button-tab averta-bold label-16"
            :class="{ active: currentTab === 'pic' }"
            @click="changeTab"
          >
            Tambah PIC Cabang
          </button>
        </div>
        <!-- Tab Tambah Cabang dan Tambah PIC Cabang -->

        <!-- Tab Tambah Cabang -->
        <div v-if="currentTab === 'branch'">
          <Branch />
        </div>
        <!-- Tab Tambah Cabang -->

        <!-- Tab Tambah PIC Cabang -->
        <div v-if="currentTab === 'pic'">
          <PIC />
        </div>
        <!-- Tab Tambah PIC Cabang -->
      </div>

      <!-- Modal Close Confirm -->
      <ModalConfirm
        :showModal="showModal"
        imageProps="sunset.png"
        :titleProps="
          currentTab === 'branch'
            ? propsModal.branch.title
            : propsModal.pic.title
        "
        :descProps="descProps()"
      >
        <template v-slot:button>
          <div class="is-flex is-align-items-center mt-4">
            <ButtonApp
              :isSecondary="true"
              :is-bold="true"
              width="185px"
              height="52px"
              class="mr-3"
              @click="cancelClose"
            >
              {{ moveTab ? "Batalkan" : "Cek Ulang" }}
            </ButtonApp>
            <ButtonApp
              :is-bold="true"
              width="185px"
              height="52px"
              @click="confirmModal"
            >
              {{ moveTab ? "Pindah Halaman" : "Batalkan" }}
            </ButtonApp>
          </div>
        </template>
      </ModalConfirm>
      <!-- Modal Close Confirm -->

      <!-- Modal Submit Form PIC -->
      <ModalConfirm
        :showModal="showModalSubmit"
        imageProps="data-not-found.png"
        imageClass="mb-3"
        :titleProps="MODAL_TITLE_SUBMIT_FORM"
        :descProps="MODAL_DESC_SUBMIT_FORM"
      >
        <template v-if="MODAL_ADDITIONAL_SECTION_FORM" v-slot:additional>
          <ul class="wrap-desc mb-6">
            <li class="has-text-left">
              <p class="is-inline averta-bold label-14">
                {{ MODAL_ADDITIONAL_SECTION_FORM }}
              </p>
            </li>
          </ul>
        </template>
        <template v-slot:button>
          <div class="is-flex">
            <ButtonApp
              @click="handleCloseSubmit"
              width="185px"
              height="52px"
              class="mr-3"
              :isSecondary="true"
            >
              <p class="averta-bold px-4 py-1">Cek Lagi</p>
            </ButtonApp>
            <ButtonApp
              @click="handleCreateBranchAndPIC"
              width="185px"
              height="52px"
            >
              <p class="averta-bold px-4 py-1">
                {{ MODAL_BUTTON_SUBMIT_FORM }}
              </p>
            </ButtonApp>
          </div>
        </template>
      </ModalConfirm>
    </template>
  </SidebarClientTemplate>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarClientTemplate from '@/components/sidebar/SidebarClientTemplate.vue'
import Branch from './branch-components/Branch.vue'
import PIC from './branch-components/PIC.vue'
import ModalConfirm from './ModalConfirm.vue'
import ButtonApp from '@/components/button/ButtonApp.vue'
import { SpinnerIcon } from '@/components/icons'

export default {
  name: 'SidebarBranch',
  components: {
    SidebarClientTemplate,
    Branch,
    ModalConfirm,
    ButtonApp,
    PIC,
    SpinnerIcon
  },
  props: {
    show: {
      type: Boolean
    },
    close: {
      type: Function
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      payloadAddBranch: 'client/getPayloadAddBranch',
      payloadAddPicBranch: 'client/getPayloadAddPicBranch',
      addBranchFromList: 'client/getAddBranchFromList'
    })
  },
  data () {
    return {
      showSidebar: false,
      currentTab: 'branch',
      disabledSubmit: true,
      showModal: false,
      moveTab: false,
      closeSidebarBranch: false,
      propsModal: {
        branch: {
          image: 'diskon.png',
          title: 'Batalkan Tambah Cabang?',
          desc_move:
            'Jika data sudah diinput akan dihapus oleh sistem dan kamu akan masuk ke page tambah PIC Cabang'
        },
        pic: {
          image: 'diskon.png',
          title: 'Batalkan Tambah PIC Cabang?',
          desc_move:
            'Jika data sudah diinput akan dihapus oleh sistem dan kamu akan masuk ke page tambah Cabang'
        },
        desc_close: 'Jika data sudah diinput akan dihapus oleh sistem'
      },
      isLoadingSubmit: false,
      showModalSubmit: false,
      MODAL_TITLE_SUBMIT_FORM: '',
      MODAL_DESC_SUBMIT_FORM: '',
      MODAL_BUTTON_SUBMIT_FORM: '',
      MODAL_ADDITIONAL_SECTION_FORM: ''
    }
  },
  methods: {
    showModalConfirm (moveTab) {
      if (
        (this.currentTab === 'branch' &&
          Object.keys(this.payloadAddBranch.clientSelected).length === 0) ||
        (this.currentTab === 'pic' &&
          Object.keys(this.payloadAddPicBranch.clientSelected).length === 0) ||
        (this.addBranchFromList === true &&
          this.currentTab === 'branch' &&
          Object.keys(this.payloadAddBranch.clientSelected).length > 0 &&
          this.payloadAddBranch.branchName === '') ||
        (this.addBranchFromList === true &&
          this.currentTab === 'pic' &&
          Object.keys(this.payloadAddPicBranch.clientSelected).length > 0 &&
          Object.keys(this.payloadAddPicBranch.branchSelected).length === 0)
      ) {
        this.clearPayloadAddBranch()
        this.clearPayloadAddPicBranch()
        this.moveTab = false
        this.closeSidebarBranch = false
        this.currentTab = 'branch'
        this.showSidebar = false
        this.close()
        this.$store.commit('client/setAddBranchFromList', false)
      } else {
        // Jika ada parameter berarti pindah tab, jika tidak ada berarti dia close sidebar
        if (moveTab) {
          this.moveTab = moveTab
        } else {
          this.closeSidebarBranch = true
        }
        this.showModal = true
      }
    },
    cancelClose () {
      this.moveTab = false
      this.closeSidebarBranch = false
      this.showModal = false
    },
    clearPayloadAddBranch () {
      // Trigger for clear state in child component
      this.$store.commit('client/setClearDataPayloadBranch', true)

      const storeToVuex = {
        type: 'clearPayload',
        data: {
          branchName: '',
          dataPic: []
        }
      }

      // Jika tambah branch dari modal, maka hapus clientSelected
      // Jika tambah branch dari button tambah pada list cabang, maka jangan hapus clientSelected
      if (this.addBranchFromList === false) {
        storeToVuex.data.clientSelected = {}
      } else {
        storeToVuex.data.clientSelected = this.payloadAddBranch.clientSelected
      }

      // Reset state global vuex for payloadAddBranch
      this.$store.commit('client/setPayloadAddBranch', storeToVuex)

      // Change setClearDataPayloadBranch to false for 1 second after clearDataPayloadBranch
      setTimeout(() => {
        this.$store.commit('client/setClearDataPayloadBranch', false)
      }, 1000)
    },
    clearPayloadAddPicBranch () {
      // Trigger for clear state in child component
      this.$store.commit('client/setClearDataPayloadPicBranch', true)

      const storeToVuex = {
        type: 'clearPayload',
        data: {
          branchSelected: {},
          dataPic: []
        }
      }

      // Jika tambah branch dari modal, maka hapus clientSelected
      // Jika tambah branch dari button tambah pada list cabang, maka jangan hapus clientSelected
      if (this.addBranchFromList === false) {
        storeToVuex.data.clientSelected = {}
      } else {
        storeToVuex.data.clientSelected = this.payloadAddBranch.clientSelected
      }

      // Reset state global vuex for payloadAddPicBranch
      this.$store.commit('client/setPayloadAddPicBranch', storeToVuex)

      // Change setClearDataPayloadPicBranch to false for 1 second after action
      setTimeout(() => {
        this.$store.commit('client/setClearDataPayloadPicBranch', false)
      }, 1000)
    },
    confirmModal () {
      // Kondisi jika sidebar ditutup atau click tombol dibatalkan
      if (this.closeSidebarBranch) {
        this.clearPayloadAddBranch()
        this.clearPayloadAddPicBranch()
        this.moveTab = false
        this.closeSidebarBranch = false
        this.currentTab = 'branch'
        this.showSidebar = false
        this.close()
        this.$store.commit('client/setAddBranchFromList', false)
      } else {
        // Kondisi jika tab di pindah dari tab tambah cabang ke tab tambah pic cabang
        if (this.moveTab && this.currentTab === 'branch') {
          this.clearPayloadAddBranch()
          this.currentTab = 'pic'
          this.moveTab = false
          this.closeSidebarBranch = false
        } else if (
          // Kondisi jika tab di pindah dari tab tambah pic cabang ke tab tambah cabang
          this.moveTab &&
          this.currentTab === 'pic'
        ) {
          this.clearPayloadAddPicBranch()
          this.currentTab = 'branch'
          this.moveTab = false
          this.closeSidebarBranch = false
        }
      }

      this.showModal = false
    },
    descProps () {
      if (this.closeSidebarBranch) {
        return this.propsModal.desc_close
      } else {
        // Kondisi wording desc jika tab di pindah dari tab tambah cabang ke tab tambah pic cabang
        if (this.moveTab && this.currentTab === 'branch') {
          return this.propsModal.branch.desc_move
        } else if (
          // Kondisi wording desc jika tab di pindah dari tab tambah pic cabang ke tab tambah cabang
          this.moveTab &&
          this.currentTab === 'pic'
        ) {
          return this.propsModal.pic.desc_move
        }
      }
    },
    handleSubmit () {
      if (this.currentTab === 'branch') {
        console.log(this.currentTab)
        console.log('payload Add Branch : ', this.payloadAddBranch)

        this.MODAL_TITLE_SUBMIT_FORM =
          'Semua Data Yang Dimasukkan Sudah Benar?'
        this.MODAL_DESC_SUBMIT_FORM =
          'Pastikan kamu sudah melakukan pengecekkan sebelum dikirim'
        this.MODAL_BUTTON_SUBMIT_FORM = 'Buat Akun'
        this.MODAL_ADDITIONAL_SECTION_FORM =
          'PIC Cabang akan menerima email untuk ubah password sebelum masuk ke dashboard B2B Kanggo'
      } else if (this.currentTab === 'pic') {
        console.log(this.currentTab)
        console.log('payload Add Pic Branch : ', this.payloadAddPicBranch)

        this.MODAL_TITLE_SUBMIT_FORM =
          'Semua Data Yang Dimasukkan Sudah Benar?'
        this.MODAL_DESC_SUBMIT_FORM =
          'Pastikan kamu sudah melakukan pengecekkan sebelum dikirim'
        this.MODAL_BUTTON_SUBMIT_FORM = 'Buat Akun'
        this.MODAL_ADDITIONAL_SECTION_FORM =
          'PIC Cabang akan menerima email untuk ubah password sebelum masuk ke dashboard B2B Kanggo'
      }
      this.showModalSubmit = true
    },
    changeTab () {
      if (
        this.currentTab === 'branch' &&
        Object.keys(this.payloadAddBranch.clientSelected).length === 0
      ) {
        this.clearPayloadAddBranch()
        this.currentTab = 'pic'
        this.moveTab = false
        this.closeSidebarBranch = false
      } else if (
        this.currentTab === 'pic' &&
        Object.keys(this.payloadAddPicBranch.clientSelected).length === 0
      ) {
        this.clearPayloadAddPicBranch()
        this.currentTab = 'branch'
        this.moveTab = false
        this.closeSidebarBranch = false
      } else if (
        this.addBranchFromList === true &&
        this.currentTab === 'branch' &&
        Object.keys(this.payloadAddBranch.clientSelected).length > 0 &&
        this.payloadAddBranch.branchName === ''
      ) {
        this.currentTab = 'pic'
        this.moveTab = false
        this.closeSidebarBranch = false
      } else if (
        this.addBranchFromList === true &&
        this.currentTab === 'pic' &&
        Object.keys(this.payloadAddPicBranch.clientSelected).length > 0 &&
        Object.keys(this.payloadAddPicBranch.branchSelected).length === 0
      ) {
        this.currentTab = 'branch'
        this.moveTab = false
        this.closeSidebarBranch = false
      } else {
        this.showModalConfirm(true)
      }
    },
    validationPayload (value, currentTab) {
      const data = value

      function checkClientSelected () {
        if (
          data.clientSelected === null ||
          typeof data.clientSelected !== 'object' ||
          Object.keys(data.clientSelected).length === 0
        ) {
          return true
        }
      }

      function checkBranch () {
        if (currentTab === 'branch') {
          if (data.branchName === '') {
            return true
          }
        } else if (currentTab === 'pic') {
          if (
            data.branchSelected === null ||
            typeof data.branchSelected !== 'object' ||
            Object.keys(data.branchSelected).length === 0
          ) {
            return true
          }
        }
      }

      function checkDataPic () {
        let result = false
        if (data.dataPic.length > 0) {
          data.dataPic.forEach((item, index) => {
            if (item.name === '') {
              result = true
            } else if (item.email === '') {
              result = true
            } else if (item.phone === '') {
              result = true
            }
          })
        }

        return result
      }

      if (checkClientSelected() || checkBranch() || checkDataPic()) {
        this.disabledSubmit = true
      } else {
        this.disabledSubmit = false
      }
    },
    handleCloseSubmit () {
      this.showModalSubmit = false
    },
    handleCreateBranchAndPIC () {
      if (this.currentTab === 'branch') {
        this.isLoadingSubmit = true
        this.showModalSubmit = false

        const payload = {
          url: this.url,
          data: {
            vendor_id: this.payloadAddBranch.clientSelected.id,
            branch_name: this.payloadAddBranch.branchName,
            users: this.payloadAddBranch.dataPic
          }
        }

        this.$store
          .dispatch('client/createBranch', payload)
          .then(() => {
            this.isLoadingSubmit = false
            this.$buefy.toast.open({
              message: '<p class="averta-regular">Cabang Berhasil Di Buat</p>',
              type: 'is-success'
            })
            this.clearPayloadAddBranch()
            this.close()
            this.$emit('refreshListBranch')
          })
          .catch(error => {
            console.log(error)
            this.isLoadingSubmit = false
            this.$buefy.toast.open({
              message:
                error.response.data.message ||
                '<p class="averta-regular">Maaf gagal membuat cabang, silahkan coba lagi</p>',
              type: 'is-danger'
            })
          })
      } else if (this.currentTab === 'pic') {
        this.isLoadingSubmit = true
        this.showModalSubmit = false

        const payload = {
          url: this.url,
          data: {
            vendor_id: this.payloadAddPicBranch.clientSelected.id,
            branch_id: this.payloadAddPicBranch.branchSelected.id,
            users: this.payloadAddPicBranch.dataPic
          }
        }

        this.$store
          .dispatch('client/createBranch', payload)
          .then(() => {
            this.isLoadingSubmit = false
            this.$buefy.toast.open({
              message:
                '<p class="averta-regular">Berhasil menambahkan PIC Cabang</p>',
              type: 'is-success'
            })
            this.clearPayloadAddPicBranch()
            this.close()
            this.$emit('refreshListBranch')
          })
          .catch(error => {
            console.log(error)
            this.isLoadingSubmit = false
            this.$buefy.toast.open({
              message:
                error.response.data.message ||
                '<p class="averta-regular">Maaf menambahkan PIC cabang, silahkan coba lagi</p>',
              type: 'is-danger'
            })
          })
      }
    }
  },
  watch: {
    show (value) {
      this.showSidebar = value
    },
    payloadAddBranch: {
      handler (value) {
        this.validationPayload(value, this.currentTab)
      },
      deep: true
    },
    payloadAddPicBranch: {
      handler (value) {
        this.validationPayload(value, this.currentTab)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.button-tab {
  background: white;
  border: 1px solid #e1e1e1;
  width: 100%;
  padding: 12px;
  border-radius: 100px;
  color: #9a9a9a;
  cursor: pointer;
}

.button-tab:first-child {
  margin-right: 10px;
}

.button-tab.active {
  background: #e10009;
  border: none;
  color: white;
}

.wrap-desc {
  background: #fafafa;
  border: 1px solid #e1e1e1;
  padding: 20px 45px;
  list-style-type: disc;
}
</style>
