<template>
  <Skeleton v-if="!isLoading" class="py-6 mb-6" />
  <div v-else class="mt-5">
    <div class="notification is-success is-light has-text-dark mx-3">
      <p class="averta-bold is-size-5">Preview</p>
      <p>Periksa kembali seluruh informasi pada penawaran sebelum dikirim ke {{ dataUpdateItemPekerjaan.isUpdate ? 'Client' :  currentRole === 'Site Manager' ? 'Purchasing' : 'Site Manager'}} </p>
    </div>
    <div class="mx-3">
      <NewDetailPenawaran :hideInformasiPenawaran="true" :purchasingModal="dataUpdateItemPekerjaan.isUpdate && true" :isFromDetail="false" class="wrap-penawaran" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Skeleton from '@/components/skeleton/Skeleton'
import { formatCurrency } from '@/helpers'
import NewDetailPenawaran from '@/components/project/NewDetailPenawaran'

export default {
  name: 'StepTwo',
  props: ['currentRole'],
  components: {
    Skeleton,
    NewDetailPenawaran
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      dataUpdateItemPekerjaan: 'project/getDataUpdateItemPekerjaanBS'
    }),
    loopDuration () {
      const duration = []
      for (let i = 1; i <= this.dataPreview.duration; i++) {
        duration.push(i)
      }
      return duration
    }
  },
  data () {
    return {
      isLoading: false,
      dataPreview: null,
      area: null
    }
  },
  methods: {
    formatThousand (x) {
      return formatCurrency(x)
    },
    changeIsActive (areaName) {
      this.area = areaName
    },
    getDataPreview () {
      const payload = {
        url: this.url,
        survey_id: this.$route.params.surveyId
      }

      this.$store
        .dispatch('projectV2/getDataPreview', payload)
        .then(response => {
          this.dataPreview = response.data.data
          this.$store.commit('project/setDataDetailPenawaran', {
            area_component: response.data.data.area_components,
            duration: response.data.data.duration,
            timeline: response.data.data.timeline,
            slot_skills: response.data.data.slot_skills
          })
        })
        .catch(error => {
          console.log(error)
          this.$buefy.toast.open({
            message:
              'Gagal memuat data preview penawaran, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    }
  },
  created () {
    this.getDataPreview()
  },
  mounted () {
    setTimeout(() => {
      this.isLoading = true
    }, 500)
  }
}
</script>

<style scoped>
.btn-tabs {
  height: auto;
  border-radius: 100px;
  color: #9a9a9a;
  border: 1px solid #e1e1e1;
}

.btn-tabs.is-active {
  background: rgba(49, 133, 252, 0.1);
  color: #3185fc;
  border: 1px solid #3185fc;
  font-family: 'Averta-Bold';
}

.text-note {
  color: #868686;
}

.bg-grey {
  background: #FAFAFA !important;
}

.text-grey {
  color: #868686;
}

.wrapper-volume {
  background: #ffffff;
  padding: 12px 24px;
  border: 1px solid #E1E1E1;
  border-radius: 10px;
}

.section-volume {
  padding-right: 28px;
}

.section-satuan {
  border-left: 2px solid #E1E1E1;
  padding-left: 14px;
  padding-right: 120px;
}

.wrapper-tukang,
.wrapper-material {
  border-radius: 10px;
}

.wrapper-item-tukang:not(:last-child),
.wrapper-item-material:not(:last-child),
.wrapper-item-variant:not(:last-child) {
  padding-bottom: 24px;
  border-bottom: 1px solid #E1E1E1;
}

.wrapper-item-pekerjaan {
  border-radius: 12px;
}

.skill-img {
  width: 34px;
  margin-right: 10px;
}

.divider {
  border-top: 2px dashed #E1E1E1;
  margin: 32px 0px;
}

.text-hidden {
  background: #3185FC;
  font-family: 'Averta';
  font-style: italic;
  color: white;
  padding: 4px 14px;
  border-radius: 14px;
}

.text-custom {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-style: italic;
}

.wrapper-table {
  overflow-y: auto;
}

.wrapper-tukang-table {
  width: 320px !important;
}

.bg-td-grey {
  background: #FAFAFA;
}

.background-konsultan {
  background: rgba(49, 133, 252, 0.1);
}

.wrapper-colspan-book {
  background: #3185FC;
  padding: 12px 4rem;
  border-radius: 24px;
  color: white;
}

.wrapper-colspan-half {
  background: #D96727 !important;
  padding: 12px 4rem;
  border-radius: 24px;
  color: white;
}

.wrapper-colspan-schedule {
  background: #D9272D;
  padding: 12px 4rem;
  border-radius: 24px;
  color: white;
}

.schedule-td {
  padding: 12px 48px;
}

.note-material {
  border: 1px solid #E1E1E1;
  border-radius: 12px;
  padding: 10px;
}

.wrap-penawaran {
  scroll-padding-top: 200px;
}

@media only screen and (max-width: 768px) {
  .wrapper-perkerjaan {
    display: block !important;
  }

  .wrapper-perkerjaan .item-kerja {
    margin-bottom: 10px;
  }

  .section-volume {
    width: 70%;
  }
  .section-volume .text-grey {
    white-space: normal;
  }
}
</style>
