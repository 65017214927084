<template>
  <ModalTemplate :showModal="isModalActive" :width="widthModal" @closeModal="closeModal">
    <template v-slot:headerModal>
      <div class="is-flex is-justify-content-space-between is-align-items-center">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <EditIcon v-if="selectedWorker.worker_id" color="#fff" size="18" />
          <CheckIcon v-else color="#fff" size="20" />

          <p v-if="selectedWorker.worker_id" class="averta-bold label-18 ml-2">Ganti Tukang</p>
          <p v-else class="averta-bold label-18 ml-2">Tugaskan {{skillName}}</p>
        </div>
        <div class="is-clickable" @click="closeModal">
          <b-icon icon="close"></b-icon>
        </div>
      </div>
    </template>
    <template v-slot:bodyModal>
      <div class="wrap-body-modal">
        <b-field class="input-wrapper mb-5 px-5 pt-5">
          <b-input class="search-wrapper" placeholder="Cari Tukang Jagoan" type="search" icon="magnify" v-model="keywordAssigment" @input="handleSearchWorker"></b-input>
        </b-field>
        <div v-if="isLoadingSubmit" class="is-flex is-align-items-center is-justify-content-center c-w-100 mt-3 is-absolute">
          <SpinnerIcon />
        </div>
        <div v-if="isLoading" class="is-flex is-align-items-center is-justify-content-center w-100 h-100">
          <SpinnerIcon />
        </div>
        <div v-else class="worker-wrapper" ref="workerWrapper" @scroll="handleScroll">
          <div v-if="dataWorker.length > 0 && !isLoading">
            <div v-for="(itemWorker, index) in dataWorker" :key="`item-worker${index}`">
              <div v-if="itemWorker && !itemWorker.duplicate">
                <div class="is-flex is-justify-content-space-between is-align-items-center px-5 py-3"
                :class="{'is-selected': selectedWorker.worker_id === (itemWorker ? itemWorker.worker_id : null), 'is-not-selected': selectedWorker.worker_id && selectedWorker.worker_id !== (itemWorker ? itemWorker.worker_id : null)}">
                  <div class="is-flex is-justify-content-space-between is-align-items-center">
                    <div class="flex-1 mr-2 max-width-40">
                      <div class="img-worker-wrapper">
                        <img v-if="itemWorker.worker_image" :src="itemWorker.worker_image" alt="worker" />
                        <img v-else :src="require('@/assets/img/profile-icon-black.png')" alt="empty-worker" />
                      </div>
                    </div>
                    <div>
                      <div class="is-flex is-align-items-center">
                        <p class="averta-bold">{{ itemWorker.worker_name }}</p>
                        <div v-if="selectedWorker.worker_id === itemWorker.worker_id" class="is-flex is-align-items-center ml-1">
                          <CheckIcon color="#10BD41" size="14" />
                          <p class="color-green label-12 is-italic">Terpilih</p>
                        </div>
                      </div>
                      <p class="is-italic color-gray label-12">{{itemWorker.worker_id ? `ID${itemWorker.worker_id}` : '-'}}</p>
                    </div>
                  </div>
                  <div v-if="selectedWorker.worker_id === itemWorker.worker_id">
                    <ButtonApp :isTransparent="true" :isSecondary="true" @click="handleCancelSelectTukang">
                      <p class="color-red averta-regular py-1">Batalkan</p>
                    </ButtonApp>
                  </div>
                  <div v-else>
                    <ButtonApp :isDisabled="selectedWorker.worker_id && selectedWorker.worker_id !== itemWorker.worker_id" :isTransparent="true" :isSecondary="true" @click="handleSelectTukang(itemWorker)">
                      <p class="color-red averta-regular py-1" :class="{'color-gray': selectedWorker.worker_id && selectedWorker.worker_id !== itemWorker.worker_id}">Pilih</p>
                    </ButtonApp>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="w-100 h-100 is-flex is-align-items-center is-justify-content-center is-flex-direction-column">
            <ProyekEmptyIcon />
            <p class="averta-bold">Pencarian tidak ditemukan</p>
            <p class="averta-regular color-gray">Periksa kembali kata kunci pencarian yang dimasukan</p>
          </div>
        </div>
      </div>

      <div class="footer-modal px-5">
        <ButtonApp v-if="!isLoadingSubmit" :isDisabled="!selectedWorker.worker_id" @click="handleAssignTukang">
          <p class="color-white averta-bold py-2">Assign Tukang</p>
        </ButtonApp>
      </div>
    </template>
  </ModalTemplate>
</template>

<script>
import { mapGetters } from 'vuex'

// Assets
import { CheckIcon, EditIcon, SpinnerIcon, ProyekEmptyIcon } from '@/components/icons'
import confirmOfferingImage from '@/assets/img/delete-area-component.png'
import sunsetImage from '@/assets/img/sunset.png'

// Helpers
import { alertErrorMessage } from '@/helpers'

// Components
import ModalTemplate from '@/components/modal/ModalTemplate.vue'
import ButtonApp from '@/components/button/ButtonApp'

export default {
  name: 'ModalAssignTukang',
  components: {
    CheckIcon,
    EditIcon,
    SpinnerIcon,
    ProyekEmptyIcon,
    ModalTemplate,
    ButtonApp
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '40vw'
    },
    jobId: {
      type: Number,
      default: null
    },
    dataWorkerProps: {
      type: Object,
      default: null
    },
    skillId: {
      type: Number,
      default: null
    },
    skillNameProps: {
      type: String,
      default: null
    },
    selectedDate: {
      type: String
    },
    loadData: {
      type: Function
    }
  },
  data () {
    return {
      isLoading: false,
      isLoadingSubmit: false,
      isModalActive: false,
      widthModal: this.width,
      keywordAssigment: '',
      initialDataWorker: [],
      dataWorker: [],
      selectedWorker: {
        worker_id: null,
        worker_image: null,
        worker_name: null
      },
      skillName: null,

      // Paging
      limitPage: 10,
      currentPage: 1,
      totalPage: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user'
    })
  },
  methods: {
    closeModal () {
      this.isLoading = false
      this.isLoadingSubmit = false
      this.initialDataWorker = []
      this.dataWorker = []
      this.selectedWorker = {
        worker_id: null,
        worker_image: null,
        worker_name: null
      }
      this.keywordAssigment = ''
      this.skillName = null

      this.currentPage = 1
      this.totalPage = null

      this.$emit('closeModal')
    },
    handleSearchWorker () {
      const isEmptyOrSpaces = this.keywordAssigment === null || this.keywordAssigment.match(/^ *$/) !== null

      if (isEmptyOrSpaces) {
        this.currentPage = 1 // reset page
        this.getListWorkers({ isEmptySearch: true })
      } else {
        this.getListWorkers({ isSearch: true })
      }

      // Reset the worker position,
      // if the worker's position has been selected it will be at the top of the list,
      // if unselected it will reset to the original list
      // This is related to handleCancelSelectTukang
      if (!this.selectedWorker.worker_id) {
        const foundDuplicate = this.initialDataWorker.find(item => item.duplicate)
        if (foundDuplicate) {
          delete foundDuplicate.duplicate
        }
      }
    },
    handleSelectTukang (data) {
      if (this.selectedWorker.worker_id) return // wich means button already selected
      this.selectedWorker = data
    },
    handleCancelSelectTukang () {
      this.selectedWorker = {
        worker_id: null,
        worker_image: null,
        worker_name: null
      }
    },
    handleAssignTukang () {
      if (!this.selectedWorker.worker_id) return

      this.$swal({
        imageUrl: confirmOfferingImage,
        imageAlt: 'confirm offering',
        width: '540px',
        padding: '10px 10px 30px 10px',
        title: `<p class="averta-bold label-22" style="margin-bottom: -10px;">${this.dataWorkerProps ? 'Simpan Perubahan' : `Assign ${this.skillName}`}</p>`,
        html: '<p class="averta-regular">Pastikan seluruh informasi yang diinginkan telah sesuai.</p>',
        showCancelButton: true,
        confirmButtonText: `${this.dataWorkerProps ? 'Simpan Perubahan' : 'Assign Tukang'}`,
        cancelButtonText: 'Batalkan',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.value) {
          this.isLoadingSubmit = true
          const payload = {
            url: this.url,
            job_id: this.jobId,
            worker_id: this.selectedWorker.worker_id
          }

          this.$store
            .dispatch('projectV2/postAssignChangeWorker', payload)
            .then(() => {
              this.isLoadingSubmit = false
              this.loadData(this.selectedDate, true) // true = refreshData
              this.closeModal()
            })
            .catch((error) => {
              this.$swal({
                imageUrl: sunsetImage,
                imageAlt: 'alert',
                width: '500px',
                padding: '10px 10px 30px 10px',
                title: '<p class="averta-bold label-22" style="margin-bottom: -10px;">Tukang Jagoan Telah Memiliki Jadwal</p>',
                html: `
                  <div>
                    <p class="mb-3 label-14 averta-regular">Pastikan seluruh informasi yang diinginkan telah sesuai.</p>
                  </div>
                  `,
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'btn-hapus-detail-component',
                  cancelButton: 'btn-batal-detail-component'
                }
              }).then((result) => {
                if (result.isConfirmed) {
                  this.isLoadingSubmit = false
                }
              })
              alertErrorMessage(error)
            })
        } else if (result.dismiss === 'cancel') {
          console.log('cancel')
        }
      }).catch((error) => {
        alertErrorMessage(error)
      })
    },
    handleScroll () {
      const workerWrapper = this.$refs.workerWrapper
      const heightWorkerWrapper = workerWrapper.scrollHeight - workerWrapper.clientHeight

      if (Math.floor(workerWrapper.scrollTop) === heightWorkerWrapper || Math.ceil(workerWrapper.scrollTop) === heightWorkerWrapper) {
        if (this.currentPage < this.totalPage) {
          this.currentPage++
          this.getListWorkers({ loadMore: true })
        }
      }
    },
    getListWorkers (props = {}) {
      const { loadMore, isSearch, isEmptySearch } = props

      if (!loadMore) {
        this.isLoading = true
      }

      const payload = {
        url: this.url,
        skill_id: this.skillId,
        limit: this.limitPage
      }

      if (isSearch) {
        payload.keyword = this.keywordAssigment
      } else {
        payload.page = this.currentPage
      }

      this.$store
        .dispatch('projectV2/getListWorkers', payload)
        .then((response) => {
          const res = response.data
          const resData = res.data

          if (this.selectedWorker.worker_id) {
            const foundWorker = resData.find(item => item.worker_id === this.selectedWorker.worker_id)

            if (foundWorker) {
              foundWorker.duplicate = true // data will not be displayed, because data worker (from selectedWorker) will be added to the top list
            }

            // unshift / move to the top list only in page 1
            if (this.selectedWorker.worker_id && (this.currentPage === 1 || isSearch || isEmptySearch)) {
              resData.unshift(this.selectedWorker)
            }
          }

          // keep data if searching
          if (!isSearch) {
            this.initialDataWorker = [...this.initialDataWorker, ...resData]
          }

          if (isSearch || isEmptySearch) {
            this.dataWorker = resData
          } else {
            this.dataWorker = [...this.dataWorker, ...resData]
          }

          // no need to re-assign variable
          if (!loadMore) {
            this.totalPage = res.total_page
            this.currentPage = res.current_page
          }

          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    }
  },
  watch: {
    showModal (newValue) {
      this.isModalActive = newValue

      if (newValue) {
        this.getListWorkers({ page: 1 })

        if (this.dataWorkerProps) {
          this.selectedWorker = this.dataWorkerProps
        }
      }
    },
    width (newValue) {
      this.widthModal = newValue
    },
    skillNameProps (newValue) {
      if (!newValue) return 'Jagoan'
      const result = newValue.replace('Tukang', 'Jagoan')
      this.skillName = result
    }
  }
}
</script>

<style scoped>
.wrap-body-modal {
  height: 70vh;
  overflow-y: auto;
  position: relative;
}

.max-width-40 {
  max-width: 40px;
}

.worker-wrapper {
  height: 70%;
  overflow: auto;
  padding-bottom: 5em;
}

.img-worker-wrapper {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 5px #eaeaea;
  border-radius: 20px;
  overflow: hidden;
}

.img-worker-wrapper img {
  object-fit: cover;
}

.footer-modal {
  position: absolute;
  z-index: 2;
  bottom: 0;
  height: 5em;
  width: 100%;
  background: white;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0px -3px 30px 0px rgba(0, 0, 0, 0.07);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.is-selected {
  background: rgba(16, 189, 65, 0.10);
}

.is-not-selected {
  opacity: 0.5;
}
</style>
