<template>
  <div class="loading-wrapper" v-if="isLoading">
    <Skeleton class="p-6" />
  </div>
  <div class="c-w-100 p-5 px-6 bg-white px-sm-1" v-else >
    <div class="is-flex is-justify-content-space-between mb-5">
      <div class="is-flex is-align-items-center is-clickable mb-5 mt-3" @click="goBack()">
        <ArrowLeftIcon class="mr-3" />
        <p class="averta-bold">Kembali</p>
      </div>
      <ButtonApp @click="previewOffering" :is-bold="true" height="3em">
        <div class="is-flex is-align-items-center pr-2">
          Preview Unduh Penawaran
          <img src="@/assets/img/download.png" class="ml-2" />
        </div>
      </ButtonApp>
    </div>

    <div ref="document" class="px-3 averta-regular">
      <div>
        <p class="averta-black label-16">Durasi Pengerjaan Proyek</p>
        <div class="is-flex is-align-items-center mb-4">
          <p class="text-grey">Tanggal Pengerjaan : </p>
          <p class="averta-bold text-grey ml-1">{{formatDateDurasi()}}</p>
        </div>
      </div>

      <div class="durasi-wrapper">
        <p class="averta-bold">{{ dataOffering.store_name }} | ID{{ dataOffering.survey_id }}</p>
          <p>Durasi Kerja: <span class="averta-black color-blue-1 label-22">{{dataOffering.duration}}</span> Hari</p>
      </div>

      <div class="detail-pekerjaan-wrapper">
        <div class="body-detail">
          <div class="header averta-bold">
            <div class="th first">
              <p class="label-16 ml-2">Detail Pekerjaan</p>
            </div>
            <div class="th sec">
              <p class="label-16 has-text-right">Volume<br />Pekerjaan</p>
            </div>
            <div class="th three">
              <p class="label-16 has-text-centered">Satuan</p>
            </div>
            <div class="th four">
              <p class="label-16 has-text-right">Harga Satuan</p>
            </div>
            <div class="th five">
              <p class="label-16 has-text-right">Total Harga</p>
            </div>
          </div>
          <div v-for="area, index in dataOffering.item_components" :key="`${index}-offer`">
            <div class="is-flex is-justify-content-space-between mb-4">
              <p class="averta-black label-14">{{ area.area_name }}</p>
              <div class="averta-black label-14 is-flex is-justify-content-space-between pl-5" style="width: 10%;">
                <p>Rp</p>
                <p>{{ formatIdr(area.total_price, true) }}</p>
              </div>
            </div>
            <div v-for="subArea, indexSubArea in area.components" :key="`${indexSubArea}-subarea`">
              <p class="averta-bold ml-2 mb-3 label-14">{{ subArea.component_name }}</p>
              <div class="row-wrapper" v-for="itemWorking, indexItemWorking in subArea.item_components" :key="`${indexItemWorking}-item-working`">
                <div class="td first is-flex">
                  <div class="ml-2 c-w-100">
                    <div class="mb-4">
                      <div class="is-flex mb-3">
                        <p class="label-14">{{ itemWorking.numbering }}.</p>
                        <p class="label-14 ml-5">{{ itemWorking.note_item_working }}</p>
                      </div>
                      <div class="foto-wrapper ml-5">
                        <div class="is-flex is-justify-content-space-between is-align-items-center mb-1">
                          <p class="averta-regular label-14">Foto Survey</p>
                          <p class="averta-bold color-red label-12 is-clickable" @click="openModalImage(itemWorking.images)">Lihat Foto</p>
                        </div>
                        <div class="is-flex overflow-auto">
                          <div class="foto-img mr-3 mb-1" v-for="image, indexImage in itemWorking.images" :key="`${indexImage}-img`">
                            <img v-if="image.picture_url" class="is-clickable" :src="image.picture_url" alt="" @click="openModalImage(itemWorking.images)">
                            <img v-else :src="require('@/assets/img/empty-img-2.png')" alt="">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="td sec">
                  <p class="label-14 has-text-right">{{ itemWorking.volume }}</p>
                </div>
                <div class="td three">
                  <p class="label-14 has-text-centered">{{ itemWorking.unit_name }}</p>
                </div>
                <div class="td four is-flex is-justify-content-space-between">
                  <p class="label-14">Rp</p>
                  <p class="label-14">{{ formatIdr(itemWorking.unit_price, true) }}</p>
                </div>
                <div class="td five is-flex is-justify-content-space-between pl-5">
                  <p class="label-14">Rp</p>
                  <p class="label-14">{{ formatIdr(itemWorking.total_price, true) }}</p>
                </div>
              </div>
            </div>
            <div class="divider mb-5" v-if="index < dataOffering.item_components.length - 1"></div>
          </div>

          <!-- Doesn't Have a Discount -->
          <div v-if="dataOffering.prices && !dataOffering.prices.discount" class="footer-wrapper averta-bold">
            <div class="prices-wrapper">
              <div class="prices">
                <p>DPP</p>
                <div class="is-flex is-justify-content-space-between is-align-items-center" style="width: 160px;">
                  <p>Rp</p>
                  <p>{{formatIdr(dataOffering.prices && dataOffering.prices.subtotal, true)}}</p>
                </div>
              </div>
            </div>
            <div class="prices-wrapper">
              <div class="prices">
                <p>PPN {{dataOffering.prices && dataOffering.prices.ppn_percent}}%</p>
                <div class="is-flex is-justify-content-space-between is-align-items-center" style="width: 160px;">
                  <p>Rp</p>
                  <p>{{formatIdr(dataOffering.prices && dataOffering.prices.ppn, true)}}</p>
                </div>
              </div>
            </div>
            <div class="prices-wrapper" v-if="dataOffering.prices && dataOffering.prices.pph">
              <div class="prices">
                <p class="white-space-nowrap">PPh Final 4 Ayat 2 ({{dataOffering.prices && dataOffering.prices.pph_percent}}%)</p>
                <div class="is-flex is-justify-content-space-between is-align-items-center" style="width: 160px;">
                  <p>Rp</p>
                  <p>({{formatIdr(dataOffering.prices && dataOffering.prices.pph, true)}})</p>
                </div>
              </div>
            </div>
            <div class="prices-wrapper last">
              <div class="prices">
                <p class="label-18">Grand Total</p>
                <div class="is-flex is-justify-content-space-between is-align-items-center" style="width: 160px;">
                  <p class="label-18">Rp</p>
                  <p class="label-18 averta-black color-red">{{formatIdr(dataOffering.prices && dataOffering.prices.total, true)}}</p>
                </div>
              </div>
            </div>
          </div>

          <!-- Discount -->
          <div v-else-if="dataOffering.prices && dataOffering.prices.discount" class="footer-wrapper averta-bold">
            <div class="prices-wrapper">
              <div class="prices">
                <p>DPP</p>
                <div class="is-flex is-justify-content-space-between is-align-items-center" style="width: 160px;">
                  <p>Rp</p>
                  <p>{{formatIdr(dataOffering.prices && dataOffering.prices.subtotal, true)}}</p>
                </div>
              </div>
            </div>
            <div class="prices-wrapper">
              <div class="prices">
                <p>Discount</p>
                <div class="is-flex is-justify-content-space-between is-align-items-center" style="width: 160px;">
                  <p>Rp</p>
                  <p>({{formatIdr(dataOffering.prices && dataOffering.prices.discount, true)}})</p>
                </div>
              </div>
            </div>
            <!-- <div class="prices-wrapper">
              <div class="prices">
                <p>Total</p>
                <div class="is-flex is-justify-content-space-between is-align-items-center" style="width: 160px;">
                  <p>Rp</p>
                  <p>{{formatIdr(dataOffering.prices && dataOffering.prices.total_after_discount, true)}}</p>
                </div>
              </div>
            </div> -->
            <div class="prices-wrapper">
              <div class="prices">
                <p>PPN({{dataOffering.prices && dataOffering.prices.ppn_percent}}%)</p>
                <div class="is-flex is-justify-content-space-between is-align-items-center" style="width: 160px;">
                  <p>Rp</p>
                  <p>{{formatIdr(dataOffering.prices && dataOffering.prices.ppn, true)}}</p>
                </div>
              </div>
            </div>
            <div class="prices-wrapper" v-if="dataOffering.prices && dataOffering.prices.pph">
              <div class="prices">
                <p class="white-space-nowrap">PPH FINAL 4 AYAT 2 ({{dataOffering.prices && dataOffering.prices.pph_percent}}%)</p>
                <div class="is-flex is-justify-content-space-between is-align-items-center" style="width: 160px;">
                  <p>Rp</p>
                  <p>({{formatIdr(dataOffering.prices && dataOffering.prices.pph, true)}})</p>
                </div>
              </div>
            </div>
            <div class="prices-wrapper last">
              <div class="prices">
                <p>Grand Total</p>
                <div class="is-flex is-justify-content-space-between is-align-items-center" style="width: 160px;">
                  <p>Rp</p>
                  <p class="averta-black label-20 color-red">{{formatIdr(dataOffering.prices && dataOffering.prices.total, true)}}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="wrap-terbilang label-16 has-text-right mt-3">
            <p>(<span class="averta-bold">Terbilang:</span> <i>{{ formatSpellOut(dataOffering.prices && dataOffering.prices.total) }}</i>)</p>
          </div>

        </div>
      </div>
    </div>

    <ModalPreviewImage :isActive="modalPreviewImage" :dataImage="dataModalImage" title="" @closeModal="closeModalImage()" />

  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import Skeleton from '@/components/skeleton/Skeleton'
import { ArrowLeftIcon } from '@/components/icons'
import ButtonApp from '@/components/button/ButtonApp.vue'
import { formatCurrency, Terbilang } from '@/helpers'
import ModalPreviewImage from '@/components/modal/ModalPreviewImage'

export default {
  name: 'PenawaranClient',
  props: {
    offeringId: {
      type: Number
    },
    dateWorking: {
      type: Object
    }
  },
  components: {
    Skeleton,
    ArrowLeftIcon,
    ButtonApp,
    ModalPreviewImage
  },
  data () {
    return {
      isLoading: false,
      dataOffering: [],
      dataModalImage: [],
      modalPreviewImage: false
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      isPreviewDownload: 'project/getPreviewDownloadOffering'
    })
  },
  methods: {
    goBack () {
      this.$emit('closeModal', true)
    },
    getDataDetailClient () {
      this.isLoading = true
      const payload = {
        url: this.url,
        offering_id: this.offeringId
      }

      this.$store
        .dispatch('project/getDataDetailClient', payload)
        .then(response => {
          this.dataOffering = response.data.data
          let numbering = 0
          this.dataOffering.item_components.forEach(area => {
            area.components.forEach(subArea => {
              subArea.item_components.forEach(component => {
                numbering++
                component.numbering = numbering
              })
            })
          })
          this.$store.commit('project/setDataUpdateItemPekerjaanBS', {
            dataOffering: this.dataOffering
          })
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
        })
    },
    formatIdr (value, hideCurrency) {
      return formatCurrency(value, hideCurrency)
    },
    previewOffering () {
      const routeData = this.$router.resolve({ path: `/am/offering-project/${this.offeringId}`, query: { preview: this.isPreviewDownload || false } })
      window.open(routeData.href, '_blank')
    },
    closeModalImage () {
      this.dataModalImage = []
      this.modalPreviewImage = false
    },
    openModalImage (data) {
      const surveyImages = data.map(item => item.picture_url)
      this.dataModalImage = surveyImages
      this.modalPreviewImage = true
    },
    formatDateDurasi () {
      if (this.dateWorking && Object.keys(this.dateWorking).length > 0) {
        const startDate = moment(this.dateWorking.date_working || this.dateWorking.working_date)
        const endDate = moment(this.dateWorking.estimate_date_finish || this.dateWorking.estimate_finish_project)

        let resDate = '-'

        if (startDate.isValid()) {
          resDate = moment(startDate).format('DD MMMM YYYY')
        }

        if (endDate.isValid()) {
          if (resDate !== '-') {
            resDate += ' - '
          }
          resDate += moment(endDate).format('DD MMMM YYYY')
        }

        return resDate
      }
      return '-'
    },
    formatSpellOut (value) {
      return Terbilang(value)
    }
  },
  mounted () {
    this.getDataDetailClient()
  }
}
</script>

<style scoped>
  .divider {
    border-top: 1px solid #E1E1E1;
  }

  .durasi-wrapper {
    border: 2px solid #3185FC;
    border-radius: 12px;
    background: rgba(49, 133, 252, 0.1);
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .detail-pekerjaan-wrapper {
    border-radius: 20px;
    border: 1px solid #E1E1E1;
    padding: 20px;
    overflow: auto;
    margin-bottom: 2em;
  }

  .body-detail {
    width: 100%;
  }

  .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E1E1E1;
    margin-bottom: 20px;
  }

  .row-wrapper {
    flex-shrink: 0;
  }

  .header .th, .td {
    flex-shrink: 0;
    margin-bottom: 20px;
  }

  .th.numbering, .td.numbering {
    width: 5%;
  }

  .th.first, .td.first {
    width: 62%;
  }

  .th.sec, .td.sec {
    width: 10%;
  }

  .th.three, .td.three {
    width: 10%;
  }

  .th.four, .td.four {
    width: 8%;
  }

  .th.five, .td.five {
    width: 10%;
  }

  .row-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .foto-img {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex-shrink: 0;
  }

  .foto-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }

  .foto-wrapper {
    border-radius: 16px;
    background: #FAFAFA;
    padding: 12px;
    width: 50%;
  }

  .prices-wrapper, .prices {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .prices-wrapper {
    padding: 12px;
  }

  .prices-wrapper.last {
    background: linear-gradient(90deg, #FFFFF7 0%, #FFF5EA 100%);
    border-radius: 8px;
  }

  .prices {
    width: 24em;
    justify-content: space-between;
  }

  .footer-wrapper {
    border-top: 1px solid #E1E1E1;
  }

  .loading-wrapper {
    width: 100%;
    height: 80vh;
  }

</style>
