<template>
  <ModalTemplate :showModal="showModal" :width="widthModal" @closeModal="closeModal">
    <template v-slot:headerModal>
      <div class="is-flex is-justify-content-space-between is-align-items-center">
        <p class="averta-bold label-18 ml-2">Tentukan Jadwal Pengerjaan</p>
        <div class="is-clickable" @click="closeModal">
          <b-icon icon="close"></b-icon>
        </div>
      </div>
    </template>

    <template v-slot:bodyModal>
      <div class="body-modal">
        <p class="averta-bold">Pilih satu tanggal</p>
        <div class="vc-date-picker-wrapper mt-2 c-w-100 flex-center-horizontal">
          <v-date-picker
            v-model="selectedDate"
            is-expanded
            color="red"
            timezone="utc"
            :min-date="new Date()"
            @input="handleChangeDate"
          />
        </div>
        <ErrorComponent v-if="errorMsg" :title="errorMsg" />
      </div>

      <div class="footer-modal">
        <ButtonApp
          class="c-w-100"
          :isDisabled="!selectedDate || isLoadingCheck || (errorMsg ? true : false)"
          @click="handleSubmitCalender"
        >
          <p class="averta-bold py-2">{{isLoadingCheck ? 'Loading' : 'Proses'}}</p>
        </ButtonApp>
      </div>
    </template>
  </ModalTemplate>
</template>

<script>
import { mapGetters } from 'vuex'
import { alertErrorMessage } from '@/helpers'
import ModalTemplate from '@/components/modal/ModalTemplate.vue'
import ButtonApp from '@/components/button/ButtonApp.vue'
import ErrorComponent from '@/components/ErrorComponent.vue'
import moment from 'moment'

export default {
  name: 'ModalWorkDate',
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    dataHeader: {
      type: Object
    }
  },
  components: {
    ModalTemplate,
    ButtonApp,
    // eslint-disable-next-line vue/no-unused-components
    ErrorComponent
  },
  data () {
    return {
      isLoading: false,
      isLoadingCheck: false,
      widthModal: '400px',
      selectedDate: null,
      errorMsg: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
    handleChangeDate (val) {
      this.isLoadingCheck = true
      this.errorMsg = null
      const payload = {
        url: this.url,
        surveyId: this.dataHeader.survey_id,
        date: moment(val).format('YYYY-MM-DD')
      }
      this.$store
        .dispatch('project/checkScheduleComplain', payload)
        .then(response => {
          const res = response.data
          const code = res.code

          if (code === 1 || code === '1') {
            this.errorMsg = null
          }
          this.isLoadingCheck = false
        }).catch(error => {
          this.isLoadingCheck = false
          const res = error.response.data
          if (res.code === 0 || res.code === '0') {
            this.errorMsg = res.message || 'Tanggal yang kamu pesan sudah penuh. Mohon ganti ke tanggal lain'
          } else {
            alertErrorMessage(error)
          }
        })
    },
    handleSubmitCalender () {
      this.$emit('onSubmitCalender', this.selectedDate)
      this.closeModal()
    }
  }
}
</script>

<style scoped>
.body-modal {
  min-height: 300px;
  padding: 20px 25px 5px 25px;
}

.footer-modal {
  padding: 10px 20px 15px 20px;
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
}

.vc-date-picker-wrapper {
  height: 230px;
  overflow: hidden;
}
</style>

<style>
  .vc-date-picker-wrapper .vc-container {
    border: none !important;
    font-family: 'Averta' !important;
  }
</style>
