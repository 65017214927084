<template>
  <div>
    <div
      class="is-flex is-justify-content-space-between is-align-items-center mb-4"
    >
      <p class="label-20 averta-bold mb-4">Daftar Survey Komplain</p>
      <ButtonApp
        :isSecondary="true"
        :isBold="true"
        height="40px"
        @click="onCreateComplain"
        v-if="isRoleBs && canCreateComplaint"
        >Buat Survey Komplain</ButtonApp
      >
    </div>
    <div v-if="dataSurveyComplaint && dataSurveyComplaint.length < 1">
      <div class="has-text-centered">
        <ProyekEmptyIcon />
        <p class="averta-bold mb-5">
          Belum Ada Survey Komplain
        </p>
        <ButtonApp
          :isSecondary="true"
          :isBold="true"
          height="40px"
          @click="onCreateComplain"
          v-if="isRoleBs && canCreateComplaint"
          >Buat Survey Komplain</ButtonApp
        >
      </div>
    </div>
    <div v-else>
      <div>
        <table class="table is-bordered is-hoverable is-fullwidth">
          <tr
            class="bg-dark-grey-5 has-text-white has-text-centered averta-bold"
          >
            <td class="vertical-align-midle" style="height: 54px;">
              <p class="label-14">No</p>
            </td>
            <td class="vertical-align-midle">
              <p class="label-14">ID Komplain</p>
            </td>
            <td class="vertical-align-midle">
              <p class="label-14">Tanggal Dibuat/Dibuat Oleh</p>
            </td>
            <td class="vertical-align-midle">
              <p class="label-14">Tanggal Survey Kembali</p>
            </td>
            <td class="vertical-align-midle">
              <p class="label-14">Konsultan Survey</p>
            </td>
            <td class="vertical-align-midle">
              <p class="label-14">Action</p>
            </td>
          </tr>
          <tr
            v-for="(item, index) in dataSurveyComplaint"
            :key="item.created_at"
            class="has-text-centered label-14"
          >
            <td class="vertical-align-midle averta-bold">{{ index + 1 }}</td>
            <td class="vertical-align-midle averta-bold">{{ item.id }}</td>
            <td class="vertical-align-midle">
              <p class="averta-bold">{{ convertDate(item.created_at) }}</p>
              <p class="averta-regular">
                <i v-if="item.created_by && item.created_by !== '-'"
                  >{{ item.created_by }} (Business Support)</i
                >
                <i v-else>-</i>
              </p>
            </td>
            <td class="vertical-align-midle averta-bold">
              {{ convertDate(item.start_date) }}
            </td>
            <td class="vertical-align-midle averta-bold">
              {{ item.worker_name }}
            </td>
            <td class="vertical-align-midle">
              <ButtonApp
                :isSecondary="true"
                :isBold="true"
                height="40px"
                @click="redirectToProjectChild(item)"
                >Lihat Detail</ButtonApp
              >
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import ButtonApp from '@/components/button/ButtonApp'
import { ProyekEmptyIcon } from '@/components/icons'

export default {
  name: 'TabSurveyComplaint',
  components: {
    ProyekEmptyIcon,
    ButtonApp
  },
  props: {
    dataSurveyComplaint: {
      type: Array,
      default: () => []
    },
    canCreateComplaint: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  data () {
    return {
      isRoleBs: false
    }
  },
  methods: {
    convertDate (date) {
      if (!date || date === '-') {
        return '-'
      }

      return moment(date).format('DD MMMM YYYY')
    },
    onCreateComplain () {
      this.$emit('createComplaint')
    },
    redirectToProjectChild (item) {
      if (item.project_information.project_id) {
        if (this.user.roles[0].name === 'Account Manager') {
          const routeData = this.$router.resolve({
            path: `/am/project/${item.project_information.project_id}/detail`
          })
          window.open(routeData.href, '_blank')
        } else if (this.user.roles[0].name === 'Site Manager') {
          const routeData = this.$router.resolve({
            path: `/sm/project/${item.project_information.project_id}/detail`
          })
          window.open(routeData.href, '_blank')
        }
      } else {
        if (this.user.roles[0].name === 'Account Manager') {
          const routeData = this.$router.resolve({
            path: `/am/project/survey/${item.project_information.survey_id}/detail`
          })
          window.open(routeData.href, '_blank')
        } else if (this.user.roles[0].name === 'Site Manager') {
          const routeData = this.$router.resolve({
            path: `/sm/project/${item.project_information.survey_id}/detail`
          })
          window.open(routeData.href, '_blank')
        }
      }
    }
  },
  mounted () {
    if (
      this.user.roles[0].name === 'Account Manager' ||
      this.user.roles[0].name === 'Business Support'
    ) {
      this.isRoleBs = true
    } else {
      this.isRoleBs = false
    }
  }
}
</script>

<style scoped>
.table.is-bordered tr,
.table.is-bordered td {
  border: 1px solid #c0c0c0;
}
</style>
