<template>
  <div class="mb-4 wrap-timeline">
    <div class="mb-5">
      <div
        class="is-flex is-justify-content-space-between is-align-items-center mb-4 flex-sm-column align-items-sm-start mt-sm-2">
        <div>
          <p class="averta-bold mb-1 label-sm-16">Timeline Proyek</p>
          <div v-if="dateWorking" class="is-flex is-align-items-center">
            <p class="label-sm-12">Tanggal Pengerjaan :</p>
            <p class="averta-bold ml-1 label-sm-12">{{ formatDate() }}</p>
          </div>
        </div>

        <div class="session-status-info">
          <div v-for="(item, index) in staticStatusInformation" :key="`session-info-${index}`"
            class="wrapper-session-info is-flex is-align-items-center" :class="{ 'mb-sm-1': index < 6 }">
            <div class="item-session-info mr-1" :class="sessionWorker(item.type, 'background')">
              <p class="label-12 averta-bold color-white">{{ sessionWorker(item.type, 'stand-for') }}</p>
            </div>
            <p class="label-12">{{ item.type }}</p>
          </div>
        </div>
      </div>

      <!-- <div v-if="!dateWorking"
        class="bg-lightgray is-relative is-flex is-justify-content-space-between py-2 px-3 border-radius-10">
        <p>Durasi Pengerjaan</p>
        <p>
          <span class="averta-bold">{{ duration || '-' }}</span> Hari
        </p>
      </div> -->

      <!-- New Duration Working & Duration Preparation Material -->
      <div class="wrap-new-duration is-flex is-justify-content-space-between mt-4" v-if="!dateWorking">
        <!-- New Duration Working -->
        <div :class="`${durationMaterial === null ? 'wrap-duration-working-full' : 'wrap-duration-working' }`">
          <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
          <div class="is-flex is-align-items-center is-justify-content-space-between px-5 py-3">
            <p class="has-text-white label-16 averta-bold">Durasi Pengerjaan</p>
            <p class="averta-bold has-text-white">{{ duration || '-' }} Hari</p>
          </div>
        </div>
        <!-- New Duration Working -->

        <!-- Duration Preparation Material -->
        <div class="wrap-duration-material" v-if="durationMaterial">
          <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
          <div class="is-flex is-align-items-center is-justify-content-space-between px-5 py-3">
            <p class="has-text-white label-16 averta-bold">Durasi Persiapan Material</p>
            <p class="averta-bold has-text-white">{{ durationMaterial || '-' }} Hari</p>
          </div>
        </div>
      </div>
      <!-- Duration Preparation Material -->
    </div>
    <!-- New Duration Working & Duration Preparation Material -->

    <!-- Counter Slot Skill -->
    <div class="divider" v-if="slotSkills !== null"></div>
    <CountSkill :dataSlot="slotSkills" v-if="slotSkills !== null" />
    <!-- Counter Slot Skill -->

    <img v-if="dataTimeline && dataTimeline.length < 1" :src="require('@/assets/img/timeline-lock.jpg')" alt=""
      class="lock-img" />

    <!-- HERE -->
    <div v-else class="mb-6">
      <div class="container-timeline scroll-position thin-scroll">
        <div class="wrapper-wrapper">
          <div class="header-table">
            <p class="averta-bold">Hari Kerja</p>
          </div>

          <div class="header-wrapper" :style="{ width: `${leftSide}px` }">
            <div class="numbering center-flex">
              <p class="text-center averta-bold">No</p>
            </div>
            <div class="item center-flex">
              <p class="text-center averta-bold">Item Pekerjaan</p>
            </div>
            <div class="skill center-flex">
              <p class="text-center averta-bold">Daftar Keahlian</p>
            </div>
          </div>
          <div class="right-side">
            <div class="project-date-wrapper hide-scroll">
              <div v-for="(item, index) in duration" :key="`project-date-${index}`"
                class="item-project-date"
                :style="{ width: `${widthColumn}px`, height: `${heightColumn}px` }">
                <p><span class="averta-bold">{{ index + 1 }}</span> <span v-if="showExtend(projectDate[index])" class="color-gray is-italic">(Extend)</span></p>
                <p v-if="projectDate && projectDate.length > 0" class="color-blue-1 averta-bold">{{
            formatProjectDate(projectDate[index].date_format) }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex bg-softgray">
          <div class="left-wrapper" :style="{ width: `${leftSide}px` }">
            <div class="separate bg-blue-separate is-flex is-align-items-center"
              :style="{ height: `${heightColumn}px` }">
              <div class="logo-inside-table">
                <img v-if="dataTimeline && dataTimeline[0].skill_image" :src="dataTimeline[0].skill_image" alt="">
                <img v-else :src="require('@/assets/img/empty-img-2.png')" alt="">
              </div>
              <p class="averta-bold">{{ dataTimeline[0].skill_name }}</p>
            </div>

            <!-- Looping Item -->
            <div class="looping-wrapper" v-for="(itemNote, indexNote) in dataTimeline.slice(1)"
              :key="`consultan-${indexNote}`">
              <div class="item-number averta-bold p-2 thin-scroll-dekstop adjust-padding-top">
                <p>{{ indexNote + 1 }}.</p>
              </div>
              <div class="item-note p-2 thin-scroll-dekstop averta-bold adjust-padding-top"
                :style="{ height: `${itemNote.skills.length > 0 ? itemNote.skills.length * heightColumn : heightColumn}px` }">
                <p>{{ itemNote.item_component_note }}</p>
              </div>
              <div class="item-skill" :class="{ 'skill-column': itemNote.skills.length < 1 }"
                :style="{ minHeight: `${heightColumn}px` }">
                <div v-if="itemNote.skills.length > 0">
                  <div class="skill-column p-2 thin-scroll-dekstop averta-bold adjust-padding-top"
                    v-for="(itemNoteChild, indexChild) in itemNote.skills" :key="`consultan-item-${indexChild}`"
                    :style="{ height: `${heightColumn}px` }">
                    <div class="logo-inside-table small">
                      <img v-if="itemNoteChild.skill_image" :src="itemNoteChild.skill_image" alt="" />
                      <img v-else :src="require('@/assets/img/empty-img-2.png')" alt="" />
                    </div>
                    <p class="label-12">{{ itemNoteChild.skill_name }}</p>
                  </div>
                </div>
                <div v-else class="is-flex is-align-items-center is-justify-content-center c-w-100">
                  <p class="color-gray is-italic">Tidak ada keahlian</p>
                </div>
              </div>
            </div>
          </div>

          <div class="right-wrapper hide-scroll">
            <div class="table-timeline-wrapper">
              <div v-for="(itemTable, indexTable) in dataTimeline" :key="`table-${indexTable}`">
                <!-- if skills exist -->
                <div v-if="itemTable.skills.length > 0">
                  <div class="is-flex" :class="{ 'row-timeline': indexTable > 0 }"
                    v-for="(itemSkill, indexSkill) in itemTable.skills" :key="`skill-${indexSkill}`">
                    <div v-for="(itemWorker, indexWorker) in itemSkill.schedules" :key="`column-${indexWorker}`"
                      class="column-status averta-bold"
                      :class="sessionWorker(itemWorker.type, indexTable === 0 && Object.keys(itemWorker).length < 1 ? 'consultan' : 'background')"
                      :style="{ width: `${widthColumn}px`, height: `${heightColumn}px` }">
                      <div v-if="Object.keys(itemWorker).length > 0"
                        class="c-w-100 c-h-100 is-flex is-align-items-center is-justify-content-center is-flex-direction-column">
                        <p>{{ itemWorker.type === 'Tersedia' ? '' : sessionWorker(itemWorker.type, 'stand-for') }}</p>

                        <div v-if="isProject &&
            indexTable > 0 &&
            (itemWorker.total_worker || itemWorker.total_worker >= 0) &&
            projectDate.length > 0 &&
            itemWorker.type !== 'Booking'" class="worker-absent is-clickable" @click="
            openModalPresent({
              itemComponentId: itemTable.item_component_id,
              date: projectDate[indexWorker].date_format,
              skillId: itemSkill.skill_id,
              totalWorker: itemWorker.total_worker,
            })
            ">
                          <!-- total_worker_present kiri, total_worker kanan -->
                          <ListChecklistIcon
                            :color="itemWorker.total_worker_present > 0 && itemWorker.total_worker > 0 && itemWorker.total_worker_present > 0 ? '#10BD41' : '#D92727'" />
                          <p class="averta-bold label-10 ml-1" :class="{
            'color-green': itemWorker.total_worker_present > 0 && itemWorker.total_worker > 0 && itemWorker.total_worker_present > 0,
            'color-red': (itemWorker.total_worker_present === 0 && itemWorker.total_worker === 0) || itemWorker.total_worker_present !== itemWorker.total_worker
          }">
                            {{ itemWorker.total_worker_present }}/{{ itemWorker.total_worker }} hadir
                          </p>
                          <ArrowFoward v-if="itemWorker.total_worker > 0 || itemWorker.total_worker_present > 0"
                            :color="itemWorker.total_worker_present > 0 ? '#10BD41' : '#D92727'" size="14" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- if skills empty -->
                <div v-else>
                  <div class="is-flex" v-for="(skill, indexSkill) in [1]" :key="`empty-skill-${indexSkill}`">
                    <div v-for="(worker, indexWorker) in duration" :key="`empty-worker-${indexWorker}`"
                      class="column-status" :style="{ width: `${widthColumn}px`, height: `${heightColumn}px` }"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="custom-scroll cs-scroll">
          <div class="child-custom-scroll"></div>
        </div>
      </div>

      <ModalTemplate :showModal="isModalActive" width="65vw" @closeModal="closeModalPresent">
        <template v-slot:headerModal>
          <div class="is-flex is-justify-content-space-between is-align-items-center">
            <div class="is-flex is-justify-content-space-between is-align-items-center">
              <ListChecklistIcon color="#fff" size="30" />
              <p class="averta-bold label-18 ml-2">Lihat Status Absen</p>
            </div>
            <div class="is-clickable" @click="closeModalPresent">
              <b-icon icon="close"></b-icon>
            </div>
          </div>
        </template>
        <template v-slot:bodyModal>
          <div class="modal-body">
            <b-loading :is-full-page="false" v-model="isLoadingModal" :can-cancel="false">
              <SpinnerIcon />
            </b-loading>

            <div v-if="!isLoadingModal && dataModalTimeline.length > 0" class="wrapper-item-detail">
              <div v-for="(item, index) in dataModalTimeline" :key="`modal-timeline-${index}`"
                class="item-detail-worker">
                <div v-if="item.worker_id" class="is-flex is-align-items-center">
                  <div class="logo-inside-table modal-worker">
                    <img v-if="item.worker_image || item.worker_image !== '-'" :src="item.worker_image" alt="" />
                    <img v-else :src="require('@/assets/img/empty-img-2.png')" alt="" />
                  </div>
                  <div class="ml-1">
                    <p class="averta-bold mb-1 mb-sm-0">{{ item.worker_name }}</p>
                    <p class="text-grey label-12">{{ item.worker_id }}</p>
                  </div>
                </div>
                <div v-if="item.worker_id" class="ml-1">
                  <p class="text-grey label-12 mb-1">Status</p>
                  <p class="averta-bold label-12 status-absent"
                    :class="{ 'not-present': !item.attendance_status, present: item.attendance_status }">{{
            item.attendance_status ? 'Hadir' : 'Tidak Hadir' }}</p>
                </div>

                <div v-if="!item.worker_id" class="not-assign-wrapper">
                  <p class="averta-bold is-italic color-gray">Belum Di-Assign</p>
                </div>
              </div>
            </div>
            <div v-else-if="!isLoadingModal && dataModalTimeline.length < 1"
              class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center empty-wrapper">
              <ProyekEmptyIcon />
              <p class="averta-bold">Belum Ada Tukang Di-Assign</p>
            </div>
          </div>
        </template>
      </ModalTemplate>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

import { formatSessionWorker, alertErrorMessage, sesiStatusInformation } from '@/helpers'
import { ListChecklistIcon, ArrowFoward, ProyekEmptyIcon, SpinnerIcon } from '@/components/icons'
import ModalTemplate from '@/components/modal/ModalTemplate'
import CountSkill from './CountSkill.vue'

export default {
  name: 'TimelinePhase2',
  components: {
    // Components
    ModalTemplate,
    CountSkill,

    // Icons
    ListChecklistIcon,
    ArrowFoward,
    ProyekEmptyIcon,
    SpinnerIcon
  },
  props: {
    dataProps: {
      type: Array
    },
    durationProps: {
      type: [String, Number]
    },
    dateWorkingProps: {
      type: Object
    },
    projectDateProps: {
      type: Array,
      default: () => []
    },
    activeTab: { // to trigger the timeline scroll
      type: [String, Number]
    },
    isProject: {
      type: Boolean,
      default: false
    },
    slotSkillsProps: {
      type: Object,
      default: null
    },
    durationMaterialProps: {
      type: Number
    }
  },
  data () {
    return {
      widthColumn: 120,
      heightColumn: 65,
      leftSide: 460,
      staticStatusInformation: [],

      isModalActive: false,
      isLoadingModal: false,
      dataModalTimeline: [],

      dataTimeline: [],
      duration: null,
      projectDate: [],
      dateWorking: null,
      slotSkills: null,
      durationMaterial: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    isMobile () {
      if (window.innerWidth <= 768) {
        return true
      }
      return false
    }
  },
  methods: {
    formatDate () {
      const startDate = moment(this.dateWorking.date_working || this.dateWorking.working_date)
      const endDate = moment(this.dateWorking.estimate_date_finish || this.dateWorking.estimate_finish_project)

      let resDate = '-'

      if (startDate && startDate.isValid()) {
        resDate = moment(startDate).format('DD MMMM YYYY')
      }

      if (endDate && endDate.isValid()) {
        if (resDate !== '-') {
          resDate += ' - '
        }
        resDate += moment(endDate).format('DD MMMM YYYY')
      }

      return resDate
    },
    formatProjectDate (date) {
      if (!date) {
        return ''
      }
      return moment(date).format('DD MMM YYYY')
    },
    sessionWorker (status, type) {
      if (type === 'consultan') {
        return 'bg-blue-separate'
      }
      const dataSessionProps = {
        status
      }
      if (type === 'background') {
        dataSessionProps.isForBackground = true
      }
      if (type === 'stand-for') {
        dataSessionProps.isStandFor = true
      }
      return formatSessionWorker(dataSessionProps)
    },
    openModalPresent (props = {}) {
      const { itemComponentId, date, skillId, totalWorker } = props
      this.isModalActive = !this.isModalActive

      if ((this.$route.params.proyekId || this.$route.params.projectId) && (totalWorker > 0 && this.projectDate.length > 0)) {
        this.isLoadingModal = true

        const payload = {
          url: this.url,
          proyek_id: this.$route.params.proyekId || this.$route.params.projectId,
          item_component_id: itemComponentId,
          date,
          skill_id: skillId
        }
        this.$store
          .dispatch('projectV2/getDetailTimeline', payload)
          .then((response) => {
            this.dataModalTimeline = response.data.data
            this.isLoadingModal = false
          })
          .catch((error) => {
            this.isLoadingModal = false
            alertErrorMessage(error)
          })
      } else {
        this.dataModalTimeline = []
      }
    },
    closeModalPresent () {
      this.dataModalTimeline = []
      this.isModalActive = false
    },
    getDataTimeline () {
      if (this.dataProps) {
        this.dataTimeline = this.dataProps
        this.projectDate = this.projectDateProps
        this.dateWorking = this.dateWorkingProps
        this.duration = this.durationProps
        this.slotSkills = this.slotSkillsProps
        this.durationMaterial = this.durationMaterialProps

        setTimeout(() => {
          this.getElement()
        }, 500)
      }
    },
    getElement () {
      const elProjectDate = document.querySelector('.project-date-wrapper')
      const elRight = document.querySelector('.right-wrapper')
      const elLeft = document.querySelector('.left-wrapper')

      const elScroll = document.querySelector('.custom-scroll')
      const elScrollChild = document.querySelector('.child-custom-scroll')

      setTimeout(() => {
        elScroll.style.width = elRight.clientWidth + 'px'
        elScroll.style.left = elLeft.clientWidth + 10 + 'px'

        elScrollChild.style.width = this.widthColumn * this.duration + 'px'
      }, 200)

      elProjectDate.addEventListener('scroll', function () {
        elRight.scrollLeft = 0 + elProjectDate.scrollLeft
      })
      elRight.addEventListener('scroll', function () {
        elProjectDate.scrollLeft = 0 + elRight.scrollLeft
        elScroll.scrollLeft = 0 + elRight.scrollLeft
      })
      elScroll.addEventListener('scroll', function () {
        elRight.scrollLeft = 0 + elScroll.scrollLeft
      })

      if (this.isMobile) {
        const headerHariKerja = document.querySelector('.header-table')
        headerHariKerja.style.width = elRight.clientWidth + 'px'
        headerHariKerja.style.left = '460px'

        elScroll.style.height = '0px'
      }
    },
    showExtend (projectDate) {
      // const dataDummyExtend = ['2024-08-11', '2024-08-12', '2024-08-13']

      // if (dataDummyExtend.includes(projectDate.date_format)) {
      //   return true
      // } else {
      //   return false
      // }

      if (projectDate && projectDate.is_extend === 1) {
        return true
      } else {
        return false
      }
    }
  },
  mounted () {
    this.getDataTimeline()
    this.staticStatusInformation = sesiStatusInformation()
  },
  watch: {
    dataProps: {
      handler: function (newVal) {
        if (newVal) {
          this.getDataTimeline()
        }
      },
      deep: true
    },
    activeTab (val) {
      if (val === 1) { // pada Detail Survey, activeTab 1 menandakan Detail Penawaran
        this.getElement()
      }
    }
  }
}
</script>

<style scoped>
.wrap-timeline {
  width: 100%;
}

.lock-img {
  width: 100%;
  border-radius: 20px;
}

.background-booking {
  background: #3185fc;
}

.background-sesi {
  background: #d9272d;
}

.hide-scroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.center-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-flex {
  display: flex;
}

.container-timeline {
  /* max-width: 1024px; */
  /* max-width: 1310px; */
  max-height: 30em;
  overflow: auto;
  position: relative;
  border-radius: 12px;
  border: 1px solid #e1e1e1;
}

.wrapper-wrapper {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  background: #fafafa;
}

.right-side {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: auto;
  background: white;
  position: relative;
}

.header-wrapper {
  display: flex;
  background: white;
  height: 7em;
  flex-shrink: 0;
  border-right: 3px solid #e1e1e1;
}

.numbering,
.item,
.skill {
  border: 1px solid #e1e1e1;
}

.text-center {
  text-align: center;
}

.numbering {
  width: 15%;
}

.item {
  width: 50%;
}

.skill {
  width: 35%;
}

.bg-blue-separate {
  background: #3185fc1a;
}

.separate {
  border: 1px solid #e1e1e1;
  border-top-width: 0;
  padding: 0 20px;
  overflow: auto;
}

.looping-wrapper {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.item-number,
.item-note,
.item-skill {
  overflow: auto;
}

.item-number {
  width: 15%;
  display: flex;
  justify-content: center;
  border: 1px solid #e1e1e1;
  border-bottom-width: 4px;
  background: #fff;
}

.item-note {
  width: 50%;
  border: 1px solid #e1e1e1;
  border-bottom-width: 4px;
  background: #fafafa;
}

.item-skill {
  width: 35%;
  background: #fff;
}

.skill-column {
  overflow-y: auto;
  border: 1px solid #e1e1e1;
  display: flex;
}

.skill-column:last-child {
  border-bottom-width: 4px;
}

.column-status {
  border: 1px solid #e1e1e1;
  flex-shrink: 0;
  color: white;
}

.left-wrapper {
  position: relative;
  flex-shrink: 0;
  border-right: 3px solid #e1e1e1;
}

.right-wrapper-wrapper {
  overflow: hidden;
  width: 100%;
}

.right-wrapper {
  background: #fff;
  overflow: auto;
  position: relative;
}

.header-table {
  width: calc(100% - 460px);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e1e1e1;
  flex: 1;
  position: absolute;
  z-index: 2;
  background: white;
  right: 0;
  height: 47px;
}

.project-date-wrapper {
  display: flex;
  overflow: auto;
}

.item-project-date {
  border: 1px solid #e1e1e1;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
}

.table-timeline-wrapper {
  table-layout: fixed;
  margin: 0;
  position: relative;
}

.row-timeline:last-child .column-status {
  border-bottom-width: 4px;
}

.list-border-bottom {
  border-bottom: 3px solid #e1e1e1;
}

.custom-scroll {
  width: 0px;
  height: 10px;
  background: transparent;
  position: sticky;
  overflow: auto;
  bottom: 2px;
  right: 5px;
}

.child-custom-scroll {
  height: 0px;
  width: 20px;
}

.session-status-info {
  display: flex;
  padding: 12px;
  border-radius: 8px;
  background: #fafafa;
}

.item-session-info {
  border-radius: 3px;
  height: 25px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper-session-info:not(:last-child) {
  margin-right: 15px;
}

.worker-absent {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 2px 6px;
  border-radius: 100px;
}

.logo-inside-table {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  overflow: hidden;
  margin-right: 5px;
  background: white;
  flex-shrink: 0;
}

.logo-inside-table.small {
  width: 28px;
  height: 28px;
}

.logo-inside-table.modal-worker {
  width: 40px;
  height: 40px;
}

.logo-inside-table img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal-body {
  height: 70vh;
  overflow-y: auto;
  position: relative;
  padding: 30px 20px;
}

.wrapper-item-detail {
  display: flex;
  flex-wrap: wrap;
}

.item-detail-worker {
  width: 48.5%;
  border: 1px solid #e1e1e1;
  padding: 10px 15px;
  border-radius: 20px;
  background: #f5f5f5;
  align-self: start;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.item-detail-worker:nth-of-type(odd) {
  margin-right: 20px;
}

.status-absent {
  border-radius: 100px;
  padding: 2px 7px;
  color: white;
  background: #868686;
}

.status-absent.not-present {
  background: #d92727;
}

.status-absent.present {
  background: #10bd41;
}

.adjust-padding-top {
  padding-top: 15px !important;
}

.not-assign-wrapper {
  display: flex;
  align-items: center;
  height: 42px;
}

.empty-wrapper {
  height: 75%;
}

.divider {
  border-top: 2px dashed #e1e1e1;
  padding-top: 16px;
}

.wrap-duration-working {
  background: linear-gradient(116.12deg, #891313 16.15%, #D9272D 83.24%);
  width: 49.5%;
  border-radius: 100px;
  position: relative;
}

.wrap-duration-working-full {
  background: linear-gradient(116.12deg, #891313 16.15%, #D9272D 83.24%);
  width: 100%;
  border-radius: 100px;
  position: relative;
}

.wrap-duration-material {
  background: #2F2F2F;
  width: 49.5%;
  border-radius: 100px;
  position: relative;
}

.wave {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 20px;
}

.text-extends {
  font-family: 'Averta' !important;
  color: #868686;
}

@media screen and (max-width: 600px) {
  .session-status-info {
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .item-session-info {
    width: 30px;
  }

  .wrapper-session-info {
    margin-right: 0 !important;
    width: 33%;
  }

  .right-side {
    overflow: unset;
  }

  .right-wrapper {
    overflow: unset;
  }

  .item-detail-worker {
    width: 100%;
    margin-right: 0 !important;
    margin-bottom: 15px;
  }

  .logo-inside-table.modal-worker {
    width: 30px;
    height: 30px;
  }

  .modal-body {
    padding: 15px;
  }

  .wrap-new-duration {
    flex-direction: column;
  }

  .wrap-duration-working {
    width: 100%;
    margin-bottom: 12px;
  }

  .wrap-duration-material {
    width: 100%;
  }

  .wave {
    object-fit: cover;
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
    border-radius: 20px;
  }
}
</style>
