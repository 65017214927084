<template>
  <div class="columns mt-3 parrent-list pb-3">
    <div class="column card list-penawaran is-4 p-5 m-2 is-clickable is-flex is-flex-direction-column" @click="goToProject(data.id)" v-for="data, index in offerData" :key="index">
      <div class="columns card-atas pb-2 is-flex-grow-1 mb-0">
          <div class="column">
              <p>#ID{{ data.survey_id }}</p>
              <p class="store averta-bold">{{ data.store.name }}</p>
          </div>
          <div class="column has-text-centered px-0">
              <p class="py-2 status-penawaran mb-3" :class="getClassName(data.status, data.tooltips)">{{ wordingMenu(data.status, data.tooltips) }}</p>
              <div class="is-flex">
                <div class="img-vendor mr-2">
                  <img :src="data.vendor.logo" :alt="data.vendor.name">
                </div>
                <p class="averta-bold">{{ data.vendor.name }}</p>
              </div>
          </div>
      </div>
      <div class="columns card-bawah mt-4">
          <div class="column">
              <p class="mb-2">Tgl Survey Selesai</p>
              <p class="averta-bold">{{ convertDate(data.survey_date) }}</p>
          </div>
          <div class="column">
              <p class="mb-2">Tgl Pembuatan Penawaran</p>
              <p class="averta-bold">{{ convertDate(data.created_at) }}</p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { wordingMenuHelper, getStatusClassName } from '@/helpers'

export default {
  name: 'CardOffer',
  data () {
    return {
      status: 'waiting-confirmation'
    }
  },
  props: ['offerData'],
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  methods: {
    dateLongFormat (value) {
      const date = !value ? '-' : value
      return moment(date).format('LL')
    },
    convertDate (date) {
      if (date === null) {
        return ''
      }
      return moment(date).format('DD MMMM YYYY')
    },
    goToProject (id) {
      this.$router.push(`/purchasing/project/offering/${id}/detail`)
    },
    getClassName (status, subValue) {
      const convertWording = this.wordingMenu(status, subValue)
      return getStatusClassName(convertWording)
    },
    wordingMenu (value, subValue) {
      return wordingMenuHelper(value, subValue)
    }
  },
  created () {}
}
</script>

<style lang="scss" scoped>
.card {
  box-shadow: none;
  // border: 1px solid #E1E1E1;
  // border-radius: 20px;
  // width: 350px;
  // height: 175px;
}
.card-header {
  padding: 1rem;
  border: none;
}
.card-header-title {
  padding: 0rem;
}
.card-content {
  padding: 0.45rem;
}
.block {
  background: linear-gradient(90deg, rgba(255, 221, 221, 0.4) 0%, rgba(255, 239, 219, 0.4) 99.97%);
  border-radius: 12px;
}
.parrent-list {
    overflow-x: auto;
}
.list-penawaran {
    background: white;
    border-radius: 20px;
}
.card-atas {
    border-bottom: 2px dashed #868686;
}
.store {
    color: #D9272D;
}
.status-penawaran {
    border-radius: 24px;
    color: white;
}

.card-bawah {
    background: linear-gradient(90deg, rgba(255, 221, 221, 0.4) 0%, rgba(255, 239, 219, 0.4) 99.97%);
    border-radius: 12px;
}
</style>
