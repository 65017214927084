<template>
  <SidebarClientTemplate
    :show="showSidebar"
    :disabledSubmit="disabledSubmit"
    @closeSidebar="handleCloseSidebar"
    @submit="handleSubmit"
  >
    <template v-slot:bodySidebar>
      <b-loading :is-full-page="true" v-model="isLoadingSubmit" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>
      <div class="mb-6">
        <p class="averta-black label-20 mb-4">{{typeSidebar === 'add' ? 'Tambah' : 'Edit'}} PIC Toko</p>
        <ClientAndBranchInfo :detailClient="getDetailClient" :detailBranch="getDetailBranch" :detailStore="getDetailStore.selectedStore"/>

        <div class="wrap-client">
          <StoreInformation :typeContent="`${typeSidebar === 'edit' ? 'edit-pic' : 'detail-add-pic' }`" :detailPIC="detailPIC" @formPICEdit="handleFormPICEdit($event)" />
        </div>
      </div>

      <!-- Modal Submit -->
      <ModalConfirm
        :showModal="showModalConfirm"
        imageProps="sunset.png"
        titleProps="Semua Data Yang Dimasukkan Sudah Benar?"
        descProps="Pastikan kamu sudah melakukan pengecekkan sebelum diubah"
      >
        <template v-slot:button>
          <div class="is-flex is-align-items-center">
            <ButtonApp
              :isSecondary="true"
              :is-bold="true"
              width="185px"
              height="52px"
              class="mr-3"
              @click="cancelClose"
            >
              Batalkan
            </ButtonApp>
            <ButtonApp :is-bold="true" width="185px" height="52px" @click="confirmModal">
              {{typeSidebar === 'edit' ? 'Simpan Perubahan' : 'Buat PIC Toko'}}
            </ButtonApp>
          </div>
        </template>
      </ModalConfirm>

      <!-- Modal Cancel Registration -->
      <ModalConfirm
        :showModal="showModalCancel"
        imageProps="sunset.png"
        imageClass="mb-0"
        :titleProps="`Batalkan ${typeSidebar === 'add' ? 'Pendaftaran' : 'Perubahan'}?`"
        descProps="Jika data sudah diisi maka akan dihapus oleh sistem"
      >
        <template v-slot:button>
          <div class="is-flex">
            <ButtonApp
              width="185px"
              height="52px"
              @click="handleCloseCancel"
              class="mr-3"
              :isSecondary="true"
            >
              <p class="averta-bold">Cek Lagi</p>
            </ButtonApp>
            <ButtonApp
              width="185px"
              height="52px"
              @click="closeSidebar"
            >
              <p class="averta-bold">Batalkan {{typeSidebar === 'edit' ? 'Perubahan' : ''}}</p>
            </ButtonApp>
          </div>
        </template>
      </ModalConfirm>
    </template>
  </SidebarClientTemplate>
</template>

<script>
import { mapGetters } from 'vuex'
// eslint-disable-next-line no-unused-vars
import { alertErrorMessage, isObjectsSimilar } from '@/helpers'
import { SpinnerIcon } from '@/components/icons'
import ButtonApp from '@/components/button/ButtonApp.vue'
import ModalConfirm from '../ModalConfirm.vue'
import ClientAndBranchInfo from '../store-components/ClientAndBranchInfo.vue'
import SidebarClientTemplate from '@/components/sidebar/SidebarClientTemplate.vue'
import StoreInformation from './StoreInformation.vue'

export default {
  name: 'SidebarEditPICStore',
  components: {
    SidebarClientTemplate,
    SpinnerIcon,
    ButtonApp,
    ModalConfirm,
    StoreInformation,
    ClientAndBranchInfo
  },
  props: {
    show: {
      type: Boolean
    },
    close: {
      type: Function
    },
    refreshData: {
      type: Function
    },
    selectedPIC: {
      type: Object
    },
    typeSidebar: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      getDetailClient: 'client/getDetailClient',
      getDetailBranch: 'client/getDetailBranch',
      getDetailStore: 'client/getPayloadStore'
    })
  },
  data () {
    return {
      showSidebar: false,
      showModalCancel: false,
      showModalConfirm: false,
      isLoadingSubmit: false,
      disabledSubmit: true,
      initDetailPIC: null,
      detailPIC: null,
      tempDetailPIC: null
    }
  },
  methods: {
    cancelClose () {
      this.showModalConfirm = false
    },
    confirmModal () {
      this.isLoadingSubmit = true
      this.showModalConfirm = false

      const payload = {
        url: this.url,
        user_id: this.detailPIC.id
      }

      if (this.typeSidebar === 'edit') {
        payload.data = {
          name: this.tempDetailPIC.pic_name || this.detailPIC.name,
          email: this.tempDetailPIC.pic_email || this.detailPIC.email,
          phone: this.tempDetailPIC.pic_phone || this.detailPIC.phone
        }
      } else if (this.typeSidebar === 'add') {
        const transformedUserObj = this.getDetailStore.formPIC.map(obj => ({
          name: obj.pic_name,
          email: obj.pic_email,
          phone: obj.pic_phone
        }))
        payload.data = {
          branch_id: this.getDetailBranch.id,
          store_id: this.getDetailStore.selectedStore.id,
          users: transformedUserObj
        }
      }

      this.$store
        .dispatch(`client/${this.typeSidebar === 'edit' ? 'updatePICStore' : 'createStore'}`, payload)
        .then(() => {
          this.showModalDelete = false
          this.isLoadingSubmit = false
          this.$buefy.toast.open({
            message: `<p class="averta-regular">PIC Toko Berhasil Di ${this.typeSidebar === 'edit' ? 'Ubah' : 'Buat'}</p>`,
            type: 'is-success'
          })
          this.detailUser = null
          this.refreshData()
        })
        .catch(error => {
          this.isLoadingSubmit = false
          alertErrorMessage(error)
        })
    },
    handleSubmit () {
      this.showModalConfirm = true
    },
    resetState () {
      this.showSidebar = false
      this.showModalCancel = false
      this.showModalConfirm = false
      this.isLoadingSubmit = false
      this.disabledSubmit = true
      this.initDetailPIC = null
      this.detailPIC = null
      this.tempDetailPIC = null
    },
    closeSidebar () {
      this.resetState()
      this.close()
    },
    handleCloseSidebar () {
      const { formPIC } = this.getDetailStore

      if (this.typeSidebar === 'add') {
        const alreadyFilledPIC = formPIC.some(object => Object.values(object).some(value => value))

        if (alreadyFilledPIC) {
          this.showModalCancel = true
        } else {
          this.closeSidebar()
        }
      } else {
        const isSimilar = this.isFormPICSimilar()
        if (isSimilar) {
          this.closeSidebar()
        } else {
          this.showModalCancel = true
        }
      }
    },
    isFormPICSimilar () {
      // eslint-disable-next-line camelcase
      if (this.initDetailPIC) {
        const { name, email, phone } = this.initDetailPIC

        const tempFormPIC = {
          pic_name: name,
          pic_email: email,
          pic_phone: phone
        }

        return isObjectsSimilar(this.getDetailStore.formPIC[0], tempFormPIC)
      }
      return false
    },
    handleFormPICEdit (data) {
      this.tempDetailPIC = data
    },
    handleCheckFormPIC () {
      const isEmptyPIC = this.getDetailStore.formPIC.some(obj => Object.values(obj).some(value => value === ''))

      let isSimilar = false
      if (this.typeSidebar === 'edit') {
        isSimilar = this.isFormPICSimilar()
      }

      if (isEmptyPIC || isSimilar) {
        this.disabledSubmit = true
      } else {
        this.disabledSubmit = false
      }
    },
    handleCloseCancel () {
      this.showModalCancel = false
    }
  },
  watch: {
    show (value) {
      this.showSidebar = value
      if (value) {
        if (this.typeSidebar === 'edit') {
          this.isLoadingSubmit = true
          setTimeout(() => {
            this.isLoadingSubmit = false
            this.detailPIC = this.selectedPIC
            this.initDetailPIC = JSON.parse(JSON.stringify(this.selectedPIC))
          }, 50)
        } else if (this.typeSidebar === 'add') {
          this.detailPIC = {
            name: '',
            email: '',
            phone: ''
          }
        }
        this.handleCheckFormPIC()
      } else {
        this.detailPIC = null
      }
    },
    getDetailStore: {
      handler () {
        this.handleCheckFormPIC()
      },
      deep: true
    }
  }
}
</script>
