<template>
  <SidebarClientTemplate
    :show="showSidebar"
    :disabledSubmit="disabledSubmit"
    @closeSidebar="handleCloseSidebar"
    @submit="handleSubmit"
  >
    <template v-slot:bodySidebar>
      <b-loading
        :is-full-page="true"
        v-model="isLoadingSubmit"
        :can-cancel="false"
      >
        <SpinnerIcon />
      </b-loading>
      <div class="pb-6">
        <p class="averta-black label-20 mb-4">Tambah PIC Cabang</p>

        <ClientAndBranchInfo
          :detailClient="payloadAddPicBranch.clientSelected"
          :detailBranch="payloadAddPicBranch.branchSelected"
        />

        <BranchInfo currentTab="pic" typeSection="add" />
      </div>

      <!-- Modal Submit -->
      <ModalConfirm
        :showModal="showModalConfirm"
        imageProps="sunset.png"
        titleProps="Semua Data Yang Dimasukkan Sudah Benar?"
        descProps="Pastikan kamu sudah melakukan pengecekkan sebelum diubah"
      >
        <template v-slot:button>
          <div class="is-flex is-align-items-center">
            <ButtonApp
              :isSecondary="true"
              :is-bold="true"
              width="185px"
              height="52px"
              class="mr-3"
              @click="cancelClose"
            >
              Batalkan
            </ButtonApp>
            <ButtonApp
              :is-bold="true"
              width="185px"
              height="52px"
              @click="confirmModal"
            >
              Simpan Perubahan
            </ButtonApp>
          </div>
        </template>
      </ModalConfirm>
    </template>
  </SidebarClientTemplate>
</template>

<script>
import { mapGetters } from 'vuex'
import { validationPayloadHelper, alertErrorMessage } from '@/helpers'
import { SpinnerIcon } from '@/components/icons'
import BranchInfo from './BranchInfo.vue'
import ButtonApp from '@/components/button/ButtonApp.vue'
import ModalConfirm from '../ModalConfirm.vue'
import ClientAndBranchInfo from '../store-components/ClientAndBranchInfo.vue'
import SidebarClientTemplate from '@/components/sidebar/SidebarClientTemplate.vue'

export default {
  name: 'SidebarAddPICBranch',
  components: {
    SidebarClientTemplate,
    SpinnerIcon,
    BranchInfo,
    ButtonApp,
    ModalConfirm,
    ClientAndBranchInfo
  },
  props: {
    show: {
      type: Boolean
    },
    close: {
      type: Function
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      payloadAddPicBranch: 'client/getPayloadAddPicBranch'
    })
  },
  data () {
    return {
      showSidebar: false,
      showModalConfirm: false,
      isLoadingSubmit: false,
      disabledSubmit: true,
      name: '',
      companyName: '',
      logo: ''
    }
  },
  methods: {
    cancelClose () {
      this.showModalConfirm = false
    },
    confirmModal () {
      this.isLoadingSubmit = true
      this.showModalConfirm = false

      const payload = {
        url: this.url,
        data: {
          vendor_id: this.payloadAddPicBranch.clientSelected.id,
          branch_id: this.payloadAddPicBranch.branchSelected.id,
          users: this.payloadAddPicBranch.dataPic
        }
      }

      this.$store
        .dispatch('client/createBranch', payload)
        .then(() => {
          this.isLoadingSubmit = false
          this.$buefy.toast.open({
            message:
              '<p class="averta-regular">PIC Cabang Berhasil Di Buat</p>',
            type: 'is-success'
          })
          this.handleCloseSidebar()
          this.$emit('reloadData', { refresh: true })
        })
        .catch(error => {
          alertErrorMessage(error)
          this.isLoadingSubmit = false
        })
    },
    handleSubmit () {
      this.showModalConfirm = true
    },
    handleCloseSidebar () {
      this.$store.commit('client/setPayloadAddPicBranch', {
        type: 'dataPic',
        dataPic: [
          {
            name: '',
            email: '',
            phone: ''
          }
        ]
      })
      this.showSidebar = false
      this.close()
    }
  },
  watch: {
    show (value) {
      this.showSidebar = value
    },
    payloadAddPicBranch: {
      handler (value) {
        this.disabledSubmit = validationPayloadHelper(value, 'pic')
      },
      deep: true
    }
  }
}
</script>
