<template>
    <ClientSkeleton v-if="isLoading" class="px-6 pb-6 pt-5" />
    <div v-else class="c-w-100">
      <b-loading v-if="isLoadingSubmit" :is-full-page="true" v-model="isLoadingSubmit" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>
      <div class="container is-fluid pt-5 pb-6 px-6 min-h-90vh">
        <div class="is-flex is-justify-content-space-between mb-5">
          <div class="is-flex is-align-items-center is-clickable" @click="goBack()">
              <ArrowLeftIcon class="mr-3" />
              <p class="averta-bold is-size-5">Toko {{ listStore.detail.name }}</p>
          </div>
        </div>
        <div class="notification is-light border-radius-20 border-2">
          <p class="averta-bold label-18">Toko {{ listStore.detail.name }}</p>
          <p class="averta-bold color-red mt-2 label-14">
            {{ listStore.detail.phone && listStore.detail.phone !== '?' ? listStore.detail.phone : '-' }}
          </p>

          <div class="is-flex">
            <p class="label-14 mr-2">{{ listStore.detail.address }}</p>
            <div class="is-clickable"
              @click="showModalEditAddress"
            >
              <img :src="require(`@/assets/img/icon-edit-${listStore.detail.address ? 'merah' : 'abu'}.png`)" width="20">
            </div>
          </div>
        </div>
        <div class="is-flex is-justify-content-space-between mb-4">
          <div>
            <p class="averta-bold is-size-5">Daftar PIC</p>
            <p>
              <span class="averta-bold label-14 mr-1">{{ listStore.total_pic }}</span>
              <span class="color-grey">PIC Tersedia</span>
            </p>
          </div>
          <div class="flex-center">
            <b-field class="mb-0">
                <b-input
                    placeholder="Cari..."
                    type="search"
                    icon="magnify"
                    @icon-click="getFilterDetailStore"
                    v-model="search"
                    @keyup.native.enter="getFilterDetailStore"
                    >
                </b-input>
            </b-field>
            <ButtonApp @click="handleAddPICStore" class="ml-5">
              <p class="averta-bold color-white py-2 px-5">+ Tambah PIC</p>
            </ButtonApp>
          </div>
        </div>
        <table v-if="listStore.pics.length > 0" class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
          <tr class="bg-dark-grey-5 has-text-white has-text-centered">
            <td style="width: 5%;" class="py-4 vertical-align-midle averta-bold"><p>NO</p></td>
            <td style="width: 20%;" class="py-4 vertical-align-midle averta-bold"><p>NAMA PIC</p></td>
            <td style="width: 15%;" class="py-4 vertical-align-midle averta-bold"><p>NOMOR HP</p></td>
            <td style="width: 15%;" class="py-4 vertical-align-midle averta-bold"><p>ALAMAT EMAIL</p></td>
            <td style="width: 10%;" class="py-4 vertical-align-midle averta-bold"><p>TANGGAL REGISTER</p></td>
            <td style="width: 10%;" class="py-4 vertical-align-midle averta-bold"><p>ASSIGN BY</p></td>
            <td style="width: 10%;" class="py-4 vertical-align-midle averta-bold"><p>STATUS</p></td>
            <td style="width: 10%;" class="py-4 vertical-align-midle"></td>
          </tr>
          <tr v-for="(pic, index) in listStore.pics" :key="pic.phone" class="tr-wrapper">
            <td class="py-3 vertical-align-midle">
              <p class="has-text-centered">{{ index + 1 }}</p>
            </td>
            <td class="py-3 vertical-align-midle">
              <div class="is-flex is-align-items-center">
                <img v-if="pic.image_url && pic.image_url !== '-'" :src="pic.image_url" class="image-pic img-shadow-1 mr-3">
                <img v-else :src="require('@/assets/img/default-picture.jpg')" class="image-pic img-shadow-1 mr-3">
                <p>{{ pic.name }}</p>
              </div>
            </td>
            <td class="py-3 vertical-align-midle">
              <p class="has-text-danger averta-bold">{{ pic.phone }}</p>
            </td>
            <td class="py-3 vertical-align-midle">
              <p>{{ pic.email }}</p>
            </td>
            <td class="py-3 vertical-align-midle">
              <p class="has-text-centered">{{formatDate(pic.created_at)}}</p>
            </td>
            <td class="py-3 vertical-align-midle">
              <p class="has-text-centered">{{pic.created_by || '-'}}</p>
            </td>
            <td class="py-3 vertical-align-midle">
              <div class="badge-status-table flex-center" :class="`${pic.is_active === 1 ? 'active' : 'nonactive'}`">
                <p class="averta-bold color-white py-1">
                  {{ pic.is_active === 1 ? 'Aktif' : 'Tidak Aktif' }}
                </p>
              </div>
            </td>
            <td class="py-3 vertical-align-midle">
              <div class="flex-center">
                <div class="is-clickable mr-2" @click="handleEditPIC(pic)">
                  <EditIcon color="#000" />
                </div>
                <!-- <div class="is-clickable" @click="handleDeletePIC(pic)">
                  <TrashIcon />
                </div> -->
              </div>
            </td>
          </tr>
        </table>
        <div v-else class="column c-w-100 has-text-centered">
          <ProyekEmptyIcon />
          <p class="averta-bold mt-2">
            Data Tidak Ditemukan
          </p>
        </div>

        <!-- Delete PIC -->
        <ModalConfirm
          :showModal="showModalDelete"
          imageProps="sunset.png"
          imageClass="mb-3"
          :titleProps="`Hapus PIC ${detailUser && detailUser.name}?`"
          descProps="Pastikan kamu sudah lakukan pengecekkan sebelum menghapus data ini."
        >
          <template v-slot:button>
            <div class="is-flex">
              <ButtonApp
                @click="handleCancelDeletePIC"
                class="mr-3"
                :isSecondary="true"
                width="185px"
                height="52px"
              >
                <p class="averta-bold">Kembali</p>
              </ButtonApp>
              <ButtonApp
                @click="handleConfirmDeletePIC"
                width="185px"
                height="52px"
              >
                <p class="averta-bold">Hapus</p>
              </ButtonApp>
            </div>
          </template>
        </ModalConfirm>

        <SidebarEditPICStore
          :show="showSidebar && typeSidebar === 'add'"
          :close="handleCloseSidebar"
          :refreshData="getDetailStore"
          :typeSidebar="typeSidebar"
        />

        <SidebarEditPICStore
          :show="showSidebar && typeSidebar === 'edit'"
          :close="handleCloseSidebar"
          :selectedPIC="selectedPIC"
          :refreshData="getDetailStore"
          :typeSidebar="typeSidebar"
        />

        <ModalEditAddress
          :dataAddress="listStore.detail"
          :showModal="showEditAddress"
          @closeModal="closeModalEditAddress"
          @confirmChangeAddress="handleChangeAddress($event)"
          @showMaps="handleShowMaps"
        />

        <ModalMapsAddress
          :showModal="modalAddAddressByMap"
          @closeModal="closeModalAddAddressByMap"
          @selectAddress="handleSelectAddress($event)"
        />
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { alertErrorMessage, formatDateHelper } from '@/helpers'
import { ArrowLeftIcon, EditIcon, TrashIcon, SpinnerIcon, ProyekEmptyIcon } from '@/components/icons'
import ClientSkeleton from '@/components/skeleton/ClientSkeleton'
import ButtonApp from '@/components/button/ButtonApp.vue'
import ModalConfirm from './components/ModalConfirm.vue'
import SidebarEditPICStore from './components/store-components/SidebarEditPICStore.vue'

export default {
  name: 'ListDetailStore',
  components: {
    ArrowLeftIcon,
    ProyekEmptyIcon,
    ButtonApp,
    // eslint-disable-next-line vue/no-unused-components
    TrashIcon,
    EditIcon,
    SpinnerIcon,
    ClientSkeleton,
    ModalConfirm,
    SidebarEditPICStore,
    ModalEditAddress: () => import('./components/store-components/EditAddressStore.vue'),
    ModalMapsAddress: () => import('./components/ModalMapsAddress.vue')
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      dataMaps: 'client/getDataMaps'
    })
  },
  data () {
    return {
      isLoading: false,
      isLoadingSubmit: false,
      showModalDelete: false,
      listStore: null,
      search: null,
      showSidebar: false,
      selectedPIC: null,
      typeSidebar: 'add', // add or edit
      showEditAddress: false,
      modalAddAddressByMap: false,
      detailUser: null
    }
  },
  methods: {
    formatDate (date) {
      return formatDateHelper({ date })
    },
    goBack () {
      this.$router.go(-1)
    },
    getDetailStore () {
      this.showSidebar = false
      this.showEditAddress = false
      this.isLoading = true
      const payload = {
        url: this.url,
        store_id: this.$route.params.storeId
      }

      this.$store
        .dispatch('client/getDetailStore', payload)
        .then(response => {
          const res = response.data.data
          this.listStore = res
          this.isLoading = false
          this.$store.commit('client/setPayloadStore', { selectedStore: res.detail })
          this.$store.commit('client/setDetailClient', { data: res.vendor })
          this.$store.commit('client/setDetailBranch', { data: res.branch })
          this.$store.commit('client/setPayloadAddPicBranch', {
            type: 'branchSelected',
            branchSelected: res.branch
          })
          this.$store.commit('client/setPayloadAddPicBranch', {
            type: 'clientSelected',
            clientSelected: res.vendor
          })
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    getFilterDetailStore () {
      this.isLoading = true

      const payload = {
        url: this.url,
        store_id: this.$route.params.storeId,
        search: this.search
      }

      this.$store
        .dispatch('client/getDetailStore', payload)
        .then(response => {
          this.listStore = response.data.data
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    handleAddPICStore () {
      this.showSidebar = true
      this.typeSidebar = 'add'
    },
    handleCancelDeletePIC () {
      this.showModalDelete = false
    },
    handleDeletePIC (data) {
      this.detailUser = data
      this.showModalDelete = true
    },
    handleConfirmDeletePIC () {
      this.isLoadingSubmit = true
      const payload = {
        url: this.url,
        store_id: this.$route.params.storeId,
        user_id: this.detailUser.id
      }

      this.$store
        .dispatch('client/deletePICStore', payload)
        .then(() => {
          this.showModalDelete = false
          this.isLoadingSubmit = false
          this.$buefy.toast.open({
            message: `<p class="averta-bold">${this.detailUser.name} Berhasil Di Hapus</p>`,
            type: 'is-success'
          })
          this.detailUser = null
          this.getDetailStore()
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    handleEditPIC (data) {
      this.showSidebar = !this.showSidebar
      this.selectedPIC = data
      this.typeSidebar = 'edit'
    },
    handleCloseSidebar () {
      this.showSidebar = false
    },
    showModalEditAddress () {
      this.showEditAddress = !this.showEditAddress
    },
    closeModalEditAddress () {
      this.showEditAddress = false
    },
    handleChangeAddress () {
      this.isLoadingSubmit = true
      const res = this.listStore.detail

      const tempMap = this.dataMaps && this.dataMaps.data
      const tempCurrentPlace = this.dataMaps && this.dataMaps.currentPlace

      const payload = {
        url: this.url,
        store_id: this.$route.params.storeId,
        data: {
          address: tempMap ? tempMap.address : res.address,
          detail_address: res.detail_address
        }
      }

      if (tempCurrentPlace && tempCurrentPlace.lat) {
        payload.data.latitude = tempCurrentPlace.lat.toString()
        payload.data.longitude = tempCurrentPlace.lng.toString()
      }

      this.$store
        .dispatch('client/updateAddressStore', payload)
        .then(() => {
          this.$buefy.toast.open({
            message: '<p class="averta-bold">Alamat Berhasil Di Ubah</p>',
            type: 'is-success'
          })
          this.getDetailStore()
          this.isLoadingSubmit = false
          this.$store.commit('client/setDataMaps', { data: null, currentPlace: null })
        })
        .catch(error => {
          this.isLoadingSubmit = false
          alertErrorMessage(error)
        })
    },
    handleShowMaps () {
      this.modalAddAddressByMap = !this.modalAddAddressByMap
    },
    closeModalAddAddressByMap () {
      this.modalAddAddressByMap = false
    },
    handleSelectAddress (data) {
      const res = data.data
      this.listStore.detail.latitude = res.latitude || res.lat || data.currentPlace.lat
      this.listStore.detail.longitude = res.longitude || res.lon || data.currentPlace.lng
    }
  },
  created () {
    this.getDetailStore()
  },
  watch: {
    search (val) {
      if (val === '') {
        this.getDetailStore()
      }
    }
  }
}

</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.col-1 {
  background: linear-gradient(90deg, #ED4949 0%, #A40707 100%);
  border-radius: 20px;
  color: white;
}

.wave {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 20px;
}

.wrapper-vendor {
  background: #F7F7F7;
  border-radius: 8px;
}

.border-right {
  border-right: 2px #EAEAEA solid;
}

.btn-detail {
  border-radius: 20px;
  color: #EB4600;
  background: white;
  border: 1px solid #EB4600;
  padding-top: 14px;
  padding-bottom: 14px;
  font-family: 'Averta-Bold';
  font-size: 16px;
}

</style>
